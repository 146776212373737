$whiteColor: #FFFFFF;
$blackColor: #000000;
$linkColor: #61dafb;
$infoColor: #6ca5db;
$blue-dodger: #2196f3;
$buttonColor: #FF7A00;
$activeButtonColor: #ffac60;
$errorColor: #ff4848;
$errorHoverColor: #ff8686;
$red-carnation: #F86464;
$red-pipin: #ffe6e6;
$borderColor: #8C8C8C;
$silverChalice: #A9A9A9;
$headerBorderColor: #d8d8d8;
$fundColor: #FFF507;
$fundCheckoutColor: #B0FDAE;
$green-hint: #E6FFE5;
$sideMenuButton: #c5c5c5;
$activeSideMenuButton: #fdfdfd;
$formColor: #E5E5E5;
$activeFormColor: #8C8C8C;
$tooltipArrowColor: #C4C4C4;
$activeGreyButton: #cacbcd;
$subBreadcrumbColor: #f6f6f6;
$checkColor: #0c7e00;
$tabBorder: #d4d4d5;
$plusColor: #4fae48;
$documentFormColor: #f7f7f7;
$cancelColor: #f8dcd8;
$activeCancelColor: #fde3e0;
$placeholderColor: #8c8c8c;
$iconColor: #858585;
$grey-alto: #CECECE;
$tableRowColor: #f2f2f2;
$tableHeaderColor: #d7d7d7;
$headerBlockInfo: #e5e5e5;
$colorBtnSetting: #79a9d6;
$blue-anakiwa: #c6e1fe;
$titleAdminColor: #a7a6a6;
$backgroundColorError:#fbcdcd;

$role-list-color: #B8B8B8;
$role-list-back: #FBFBFB;
$role-list-box-shadow: #dedede;
$role-list-border-top: #fafafa;
$role-list-color-role: #0d0d0d;

$cornflower-blue: #79a9d6;
$gray: #808080;
$borderColorButton: #d86500;

$gray-dove: #666666;
$gray-silver: #C7C7C7;
$gray-chalice: #B2B2B2;
$gray-gallery: #EBEBEB;
$gray-mercury: #E4E4E4;
$silver: #BFBFBF;
$white-silver: #C4C4C4;
$form-color-opacity: #e5e5e5cf;
$gray-wild-sand: #F6F6F6;
$green-sushi: #8bc34a;
$green-meet: #b0fdad;
$blue: #0000ff;
$blue-danube: #6B8DD4;
$red: #ff0000;
$orange: #ff9900;
$green: #008800;
$yellow: #ccc61f;
$veil: #00000061;
$sharkColor: #dedede;
$morningGlory: #96c8da;
$Woodsmoke: #040405;
$Chablis: #fff6f6;
$SuperNova: #FFCC00;
$DustyGray: #969696;
$DustyGray: #949494;
$SilverBf: #BFBFBF;
$Mercury: #E6E6E6;
$SilverChalice: #ABA5A5;
$Alto: #D3D0D0;
$Chalice: #B0ADAD;
$transparentIe: rgba(255,255,255,0);
$gray-seashell: #F1F1F1;
$transparent-color: #0000;
$pink-cavern: #E0B4B4;
$pink-ebb: #F0EDED;
$blue-malibu: #7BC7F5;
$orange-jaffa: #F3814D;
$green-emerald: #72cf71;
$orange-texas-rose: #FFB954;
$pastelGreen: #80DD94;
$defaultDisabledTextColor: #000000DE;
$disabledInputColor: #f0f1f1;
