@import '../../../styles/colors.scss';

.creating-or-updating-user {
    padding: 10px !important;
    position: relative;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 800px;
    background: $whiteColor;
    justify-content: space-between;

    .input-field__wrapper-datepicker {
        width: 100%;
    }
    .input-field .field.formInput {
        width: 100%;
    }
    .input-field__wrapper-datepicker input {
        text-align: left;
    }
    &__header {
        background: $whiteColor;
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    &__controls {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
    }

    .ui.button.main-btn {
        height: 50px;
        border-radius: 4px;

        &:hover {
            background-color: $buttonColor;
        }
    }

    &__status-info {
        display: flex;
    }

    &__status, &__employee-user {
        min-height: 45px;
        width: 50%;
        text-align: start;
        display: flex;
        background: $formColor;
        padding-left: 10px;
        margin-left: 3px;
        align-items: center;
    }

    &__employee-user {
        width: 100%;
        margin-left: 0px;
        margin-bottom: 5px;
    }

    &__status:first-child {
        margin: 0;
    }

    &__locked-status {
        margin-left: 20px;
        white-space: nowrap;
        height: 50%;
        padding-top: 10px;
        margin-right: 5px;
    }

    &__login-input, &__password-input {
        display: flex;
    }

    &__login-button, &__password-button  {
        position: absolute;
        left: 9%;
        background: $buttonColor;
        border-radius: 5px;
        min-height: 45px;
    }

    &__login-button-icon {
        background: $green-sushi;
        border-radius: 50%;
        padding: 5px;
    }

    i.icon.redo:before {
        color: $whiteColor;
    }
    &__locked-status-true {
        margin-left: 5px;
        color: red;
    }

    &__locked-status-false {
        margin-left: 5px;
        color: green;
    }

    &__checkbox {
        cursor: pointer;
        &.check.bordered.icon {
          font-size: 1.21rem;
          width: 1.45em;
          height: 1.45em;
          padding: .25em 0 !important;
          color: $gray-chalice;
          border: 1px solid $gray-chalice;
          border-radius: 3px;
          box-shadow: none;
          margin: 0;
          margin-top: 8px;
          margin-left: 5px;
        }

        &_active.check.bordered.icon {
          margin-left: 5px;
          margin-top: 8px;
          color: white;
          background-color: green;
        }
      }

    &__content {
        height: 100%;
        display: flex;
        width: 100%;
    }

    &__left-info {
        width: 100%;
    }
    &__right-info {
        width: 40%;
        margin-left: 5px;
        background: $formColor;
        text-align: left;
    }

    &__right-info-text {
        word-wrap: break-word;
        margin-right: 5px;
        word-break: normal;
    }

    &__right-info-block, &__right-info-block-hash {
        margin-left: 20px;
        height: auto;
        margin-top: 15px;
        display: flex;
        word-break: break-all;
        margin-right: 10px;
    }


    &__id {
        display: flex;
    }

    &__id-info {
        display: flex;
        margin-left: 20px;
    }

    &__ban-button {
        height: 40px;
        width: 150px;
        background-color: $buttonColor;
        color: $blackColor;
        font-size: 20px;
        border-radius: 4px;
        font-weight: 700;
    }
    &__sub-info {
        top: 0;
        margin-top: 5px;
    }

    &__footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: auto;

        &__buttons {
        margin-top: 5px;
        height: 40px;
        width: 150px;
        background-color: $buttonColor;
        color: $blackColor;
        font-size: 20px;
        border-radius: 4px;
        text-transform: none;
        text-shadow: none;
        font-weight: 700;
        line-height: 1em;
        font-style: normal;
        text-align: center;
        text-decoration: none;
        }
    }

    .input-field__wrapper-datepicker {
        .input-field__scroll {
            width: 100%;
        }
        input {
            padding-left: 35px !important;
        }
    }

    .react-datepicker-popper {
        display: flex;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }
}
