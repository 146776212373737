@import '../../styles/colors';

@mixin mid-size() {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin small-size() {
  @media only screen and (max-width: 480px) {
    @content;
  }
}

.payment-sum {
  background-color: $whiteColor;
  padding: 10px 10px 140px 10px;

  .field {
    min-width: 20%;

    .input {
      width: 100%;
      height: 53px;
      font-size: 1.1rem;

      input {
        border-radius: 0;
      }
    }
  }

  .row {
    padding: 0 !important;
    margin: 0 !important;
  }

  &__row-sum {
    background-color: $formColor;
    padding: 2%;
    margin-bottom: 5px;
    box-sizing: border-box;
  }

  &__sum, &__title {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
  }

  &__title {
    text-align: left;
    justify-content: left;

    &_total {
      font-weight: bold;
      font-size: 1.3rem;
    }

    &_required {
      font-weight: bold;
    }

    @include mid-size() {
      padding-bottom: 10px;
    }
  }

  &__empty {
    @include mid-size() {
      display: none;
    }
  }

  &__title-desc {
    background-color: $formColor;
    height: 40px;
    margin-right: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    &_last {
      margin-right: 0;
    }
  }

  &__title-desc-row {
    margin-bottom: 5px;

    @include mid-size() {
      display: none;
    }
  }

  &__input-block {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &__btn-block {
    display: flex;
    justify-content: flex-end;
  }

  &__apply-button.ui.button {
    background-color: $buttonColor;
    color: $blackColor;
    margin: 0;
    font-size: 20px;
    margin-top: 30px;

    @include small-size() {
      width: 100%;
    }

    &:hover {
      background-color: $activeButtonColor;
    }
  }
}
