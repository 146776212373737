@import './../../../styles/colors.scss';

.admin-products {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 680px;

  .react-datepicker__input-container {
    width: 100%;
  }
  
  &__footer {
    display: flex;
    justify-content: space-between;
    background: $formColor;
    padding: 5px;
  }

  &__check-box-control {
    display: flex;
    justify-content: space-around;
  }

  &__accept-btn {
    margin-left: 10px !important;
    display: flex;
  }

  .row {
    margin: 0;
  }

  .main-btn.ui.button {
    border-radius: 4px;
  }

  .input-field {
    margin-bottom: 0;
    background: none;
  }

  &__control-buttons {
    display: flex;
    justify-content: center;
  }

  &__control-buttons-text {
    margin-top: 5px;
  }
}
