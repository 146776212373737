@import '../../../styles/colors.scss';

.veil {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: $veil;
    cursor: unset;
    z-index: 100000;
}

.popup-container {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   border: 1px solid $borderColor;
   box-shadow: 0 2px 4px 0 $Woodsmoke, 0 2px 10px 0 $Woodsmoke;
   background: $formColor;

  &__close-btn {
       font-size: 21px;
       position: absolute;
       right: 0;
       top: 5px;
       cursor: pointer;
       outline: none;

       .close.icon {
           color: $red;
       }
   }
}
