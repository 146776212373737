@import '../../../../../../../styles/colors.scss';

.description-assets-popup {
  &__trading-view-buttons {
    position: absolute;
    right: 45px;

    > .ui.button {
      background-color: $pastelGreen;
      color: $blackColor;
      font-size: 18px;
      padding: 5px;
      width: 120px;
      margin-right: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover,
      &:focus {
        background-color: $pastelGreen;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
}
