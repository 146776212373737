@import '../../../styles/colors.scss';

.set-new-password-form {
  width: 430px;
  padding: 35px 35px 15px;

  &__form {
    display: flex;
    flex-direction: column;
     .fields {
    display: block !important;
    margin: 0px !important;
  } 
  }

  &__form-container {
    display: flex;
    flex-direction: column;
    padding: 0 35px 15px;
  }

  
  &__title {
    display: flex;
    justify-content: flex-start;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 20px;
    text-align: start;
    padding-bottom: 10px;

  }

  &__controls {
    padding: 20px 35px 35px;
  }

  &__send-button.ui.button {
    background-color: $buttonColor;
    color: $blackColor;
    margin-right: 10px;
    width: 100%;
    font-size: 20px;

    &:hover {
      background-color: $activeButtonColor;
    }

    &:focus {
      background-color: $buttonColor;
      outline: none;
      box-shadow: 0 0 0 2px $activeButtonColor;
    }
  }

  &__message {
    font-size: 1rem;
    text-align: left;
  }

  &__password-requirements {
    padding: 0 35px;
    text-align: left;

    p {
      font-size: 0.9rem;
      margin: 5px 0;
    }
  }

    &__green {
      color: $checkColor;
    }

    &__red {
      color: $errorColor;
    }
}
