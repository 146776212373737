@import '../../../styles/colors.scss';

.creating-or-updating-report {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 700px;

    &__error {
        color: $errorColor;
        text-align: left;
    }
    &__error-select {
        background: $errorColor !important;
    }
    &__draggable-list {
        display: flex;
        margin-bottom: 5px;
    }

    &__partners-select {
        display: flex;
    }
    &__header {
        text-align: start;
    }

    &__header__title {
        margin-left: 10px;
        padding: 5px;
    }

    &__active-status {
        display: flex;
        background: $formColor;
        min-height: 45px;
        width: 100%;
        padding: 3px 10px;
        align-items: center;
        margin-bottom: 5px;
    }

    &__id-field, &__partners-select {
        display: flex;
        width: 100%;
        min-height: 45px;
        background-color: $formColor;
        padding: 3px 10px;
        align-items: center;
        margin-bottom: 5px;
    }
    &__id-field-header, &__partners-select-header {
        margin: 0;
        width: 30%;
        text-align: left;
    }
    &__id-field-input, &__partners-select-selector {
        width: 70%;
        margin-right: 0;
        border: none;
        min-height: 35px;
        border-radius: 4px;
        position: relative;
    }

    &__id-field-input {
        padding-left: 14px;
    }

    &__status-title {
        width: 30%;
        text-align: left;
    }

    &__status-checkbox {
        width: 70%;
        display: flex;
        justify-content: flex-start;
    }

    &__footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: auto;
    }
    
    &__footer-buttons {
        margin: 5px;
        height: 40px;
        width: 150px;
        background-color: $buttonColor;
        color: $blackColor;
        font-size: 20px;
        border-radius: 4px;
        }
    }

    .input-field .field.formInput {
        width: 100%;
    }
    .ui.selection.dropdown {
        width: 100%;
    }

.icon-write-select {
    position: absolute;
    right: 25px;
    top: 10px;
    color: $borderColor;
}

.icon-write-select-multi-select {
    position: absolute;
    bottom: 120px;
    right: 40px;
}
