.temporary-password-form {
  .ui.button {
    width: 150px;
    font-size: 1.15rem;
    float: right;
    margin-right: 0;
  }
}

.temporary-password-form.ui.active.modal {
  width: 100%;
  max-width: 500px;
}
