@import './../../../styles/colors';

.success-btn {
    &.check.bordered.icon {
      font-size: 1.21rem;
      width: 1.45em;
      height: 1.45em;
      padding: .25em 0 !important;
      color: $gray-chalice;
      border: 1px solid $gray-chalice;
      border-radius: 3px;
      box-shadow: none;
      margin: 0;
    }

    &_active.check.bordered.icon {
      color: white;
      background-color: green;
    }
}