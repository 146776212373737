@import '../../../styles/colors';

.import-from-1c-popup {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background: $formColor;

    &__header-warning {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
    }

    &__header-text {
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
    }

    &__import-errors {
        background: $whiteColor;
        margin-top: 20px;
        height: 400px;
        overflow: auto;
        border: 1px solid $blackColor;
    }

    &__error-row {
        padding: 5px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $blackColor;
    }

    &__warning-sign {
        color: $red;
    }
}
