@import './../../../styles/colors';

.creating-or-updating-public-api-form {
  padding: 10px;

  &__form-content {
    min-height: 515px;
  }

  &__section {
    background-color: $formColor;
    padding: 10px 0 10px 10px;
    margin-bottom: 2px;
  }

  &__button-interface {
    display: flex;
    justify-content: flex-end;
  }

  &__section-content {
    display: flex;
    align-items: center;
  }

  &__title {
    margin: 0;
    padding: 0;
    text-align: left;
    width: 30%;
  }

  &__select {
    width: 70%;

    .select-loader {
      width: 50px;
    }

    .write.icon.icon-write-select {
      right: 75px !important;
    }
  }

  &__input-text {
    width: 70%;

    .write.icon {
      right: 75px !important;
    }

    input {
      height: 38px;
      border: 0;
      border-radius: 3px;
      width: calc(100% - 50px) !important;
      padding-left: 15px;

      &::placeholder {
        color: rgba(191,191,191,.87);;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &__save.ui.button  {
    border-radius: 3px;
    margin-left: 5px;
  }
}