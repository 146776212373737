@import '../../../styles/colors.scss';

.calc-item {
  background-color: $formColor;
  padding: 10px 10px 10px 25px;
  overflow: hidden;
  margin: 0 7px 5px 0;

  &__calc-sum-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0;
  }

  &__calc-sum-title {
    display: flex;
    align-items: center;
    margin-right: 10px;
    text-align: left;
  }

  &__calc-sum-weight {
    border: 1px solid $borderColor;
    padding: 0 10px;
    height: 35px;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}
