@import '../../../styles/colors.scss';

.creating-or-updating-branch {
  padding: 10px;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__controls {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .ui.button.main-btn {
    height: 50px;
    width: 130px;
    border-radius: 4px;
  }

  &__infinity-search-wrapper {
    width: 100%;
    padding: 3px 10px;
    margin-bottom: 5px;
    background-color: $formColor;
    display: flex;
  }
}
