@import './../../../styles/colors';

.public-api-admin-table {
  min-height: 665px;

  &__item-row {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid $borderColor;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__multi-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &__control-button {
    &:focus {
      outline: none;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    width: 308px;
  }
}