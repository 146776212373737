@import './../../../styles/colors';

.ip-editor {
  display: flex;
  flex-direction: column;
  max-width: 220px;
  width: 100%;

  &__row {
    margin-bottom: 5px;
    width: 100%;
  }

  &__row-content {
    display: flex;
    align-items: center;
  }

  &__input {
    height: 30px;
    width: 180px;

    &_error {
      input {
        background: $Chablis !important;
        border-color: $pink-cavern !important;
      }
    }
  }

  &__remove-btn {
    background-color: transparent;
    border: 0;
  }

  &__add-button {
    background-color: $buttonColor;
    border: 0;
    height: 27px;
    font-weight: bold;
    border-radius: 4px;
  }

  &__error {
    display: flex;
    justify-content: center;
  }

  &__error-icon {
    color: $red;
  }

  &__error-text {
    color: $errorColor;
  }
}