@import '../../../styles/colors.scss';

.creating-constructor-isz {
  margin: 10px !important;
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  background: $whiteColor;
  justify-content: space-between;
  max-width: 1200px !important;
  height: 100%;
  padding: 10px;

  .item[asset="1"] {
    color: red !important;
  }

  .item[asset="2"] {
    color: $SuperNova !important;
  }

  .item[asset="3"] {
    color: green !important;
  }

  &__error {
    color: $errorColor;
    text-align: left;
  }

  &__asset-wrap {
    background-color: $formColor;
    margin-bottom: 5px;
  }

  &__assets-different-colors-warning {
    margin: 0;
    padding: 5px 0;
    display: flex;
    justify-content: center;
  }

  .main-btn.ui.button {
    border-radius: 4px;
    height: 60px;
  }

  &__error-select {
    background: $Chablis !important;
  }

  &__barriers {
    display: flex;

    .input-field {
      padding-right: 0;
    }
  }

  &__header {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    color: $blackColor;
    background: $formColor;
    margin-bottom: 10px;
    padding-top: 10px;
  }

  &__header-parametres {
    display: flex;
    justify-content: space-between;
  }

  .ui.form {
    display: flex;
    background: $formColor;
    min-height: 45px;
    width: 100%;
    padding: 3px 10px;
    align-items: center;
    margin-bottom: 5px;
  }

  &__form-field {
    display: flex;
    background: $formColor;
    min-height: 45px;
    width: 100%;
    padding: 3px 0 3px 13px;
    align-items: center;
    margin-bottom: 5px;
  }

  &__form-title {
    width: 30%;
    text-align: left;
    height: 25px;
  }

  &__form-content, &__form-content-radio {
    position: relative;
    width: 70%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    &_small {
      width: 50%;
    }

    &_no-margin-right {
      .fieldWrap {
        margin-right: 0;
      }
    }
  }

  &__form-content-radio {
    flex-direction: row;
  }

  .ui.form .field {
    margin: 0;
  }

  .ui.selection.dropdown {
    width: 100%;
  }

  .create-entity-btn__icon-add {
    display: none;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }

  .create-entity-btn {
    width: 200px;
    height: unset;
  }

  .icon-write-select {
    position: absolute;
    right: 25px;
    top: 10px;
    color: $borderColor;
  }

  .icon-write-select-input {
    position: absolute;
    right: 36px;
    top: 13px;
    color: $borderColor;
  }

  .input-field {
    justify-content: space-between;
    margin-bottom: 0;

    &__scroll {
      width: 50%;
    }
  }

  &__btn-get-sum.ui.button {
    font-size: 16px;
    min-height: 36px !important;
    height: auto !important;
    display: flex;
    align-items: center;
    width: 19%;
    margin-right: 1%;
    padding: 0 5px;
    min-width: 118px;
  }

  &__input {
    position: relative;
    background: $formColor;
    margin-bottom: 5px;
    border: 0 !important;

    &_error {
      .input-field__input {
        background: $Chablis !important;
      }
    }

    &:disabled {
      background-color: $gray-seashell !important;
    }

    p:last-child {
      position: relative;
      left: 30.5%;
    }
  }

  .formContainer__required-label .write.icon {
    color: unset;
  }
}