@import '../../styles/colors';

.form-products {
  margin: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1920px;

  &__excel-icon {
    height: 100%;
  }
  &__pages {
    display: flex;
    height: 100%;
    margin: 0 5px;
    font-size: 20px;
  }

  .collapse {
    margin-bottom: -10px;
  }

  .react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100%;
    height: 55px;

    .field {
      height: 55px;
    }

    @media only screen and (max-width: 768px) {
      width: 100%;
    }

    input {
      border: none;
      width: 100%;
      height: 55px;
      font-size: 1.2rem;
      outline: none;
    }
  }

  .fieldWrap.date {
    width: 100%;
    svg {
      left: 80%;
      top: 15px;

      @media only screen and (max-width: 900px) {
        left: 3%;
      }

      @media only screen and (max-width: 768px) {
        left: 90%;
      }
    }
  }

  .formInput {
    margin: 0;
  }

  .formInput .fieldWrap .ui.input input {
    padding-right: 0px !important;
  }

  &__btn-control {
    width: 100%;
    display: flex;
    margin-bottom: 5px;

    .form-products__date-input {
        width: 100%;
    }

    .ui.selection {
      height: 55px;
      margin-right: 5px;
      width: 50%;
    }
  }

  .ui[class*="left icon"].input {
    > i.icon {
      right: 5px;
      left: unset;
      font-size: 1.3rem;
    }

    > input {
      padding-left: 31px !important;
      font-size: 1.3rem;
    }
  }


  .ui.icon input {
    border-radius: 0;
  }

  .ui.selection.dropdown {
    width: 100%;
    min-width: unset;
    border-radius: 0;

  }

  &__backButton.ui.button,
  &__btn-search.ui.button {
    width: 100%;


    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__content {
    min-height: 643px;
    background-color: $whiteColor;
    margin-bottom: 5px;

    .ui.input {
      width: 100%;
      display: flex;
      align-items: center;
      height: 100%;

      input {
        border-radius: 0;
        height: 100%;
      }
    }

    .ui.selection.dropdown {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  &__search-text {
    margin: 0;
    padding: 0;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    background-color: $formColor;
  }

  .row {
    margin: 0;
    padding: 0;
  }

  &__download-report {
    background: $formColor;
    height: 55px;
  }

  &__controls {
    margin-bottom: 5px;

    .ui.selection.dropdown {
      width: 100%;
      height: 55px;
      display: flex;
      align-items: center;


    }

    .dropdown.icon {
      height: 55px !important;
      display: flex;
      align-items: center;
    }
  }

  &__tools {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
      padding: 0;
    }
  }

  &__title-date-input {
    position: absolute;
    z-index: 100;
    left: 6px;
    font-size: 0.9rem;
    color: gray;
  }

  &__select-date.ui.selection.dropdown {
    font-size: 1.15rem;
    padding: 15px;
  }

  .ui.segment {
    padding: 0;
    margin: 0;
    border: 0;
    min-height: 700px;
    z-index: 2;
  }

  &__search-inputs {
    margin-bottom: 10px;
  }

  &__select-wrapper {
    display: flex;
    justify-content: center;
  }

  &__input-wrap {
    padding-right: 3px;
  }

  &__control-buttons:last-child {
    .form-products__input-wrap {
      padding-right: 0px;
    }
  }

  &__pagination {
    width: 100%;
    height: 35px;
    background-color: $whiteColor;
    display: flex;
    justify-content: flex-end;

    .caret.icon {
      color: $buttonColor;
      font-size: 3rem;
      width: 12px;
      padding-top: 10px;
    }
  }

  &__icon-caret {
    outline: none;
  }

  &__pagination-control {
      display: flex;
      align-items: center;

    input {
      width: 30px;
      height: 30px;
      border: none;
      outline: none;
      text-align: center;

      [type='number'] {
        -moz-appearance:textfield;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }

}

