// import color variabels
@import '../../../styles/colors';
@mixin only-ie-11() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

.formContainer {
  display: flex;
  justify-content: flex-start;
  min-height: calc(100vh - 100px);
  position: relative;
  overflow: auto;

  &_united-frontend {
    min-height: calc(100vh - 113px);

    &:after { // for ie
      content: '';
      min-height: inherit;
      font-size: 0;
    }
  }

  &__check-data {
    text-align: left;
    align-items: center;
    display: flex;
    height: 25px;

    p {
      margin: 0 5px 5px 0;
      padding: 0;
      font-size: 1.1rem;
    }
  }

  &__required-label {
    margin: 0;
    padding: 0 10px 5px 0;
    display: flex;
    justify-content: flex-end;
    font-size: 1.1rem;

    .write.icon {
      color: $borderColor;
    }
  }

  &__content {
    padding: 15px;
    width: 100%;
    max-width: 1280px;

    @media only screen and (max-width: 1024px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &__wrap {
      background: none;
      display: flex;
      flex-direction: row;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }

      @media only screen and (max-width: 480px) {
        width: 100%;
      }

      &__leftBar {
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }

      &__backButton.ui.button {
        background-color: $buttonColor;
        color: $blackColor;
        border-radius: 0;
        margin-right: 10px;
        width: 100%;
        font-size: 20px;

        padding: 0;
        height: 55px;

        &:hover {
          background-color: $activeButtonColor;
        }
      }

      .fund {
        margin: 0;
        justify-content: flex-start;
        border-top: 1px solid $borderColor;
        border-bottom: 1px solid $borderColor;

        @media only screen and (max-width: 768px) {
          display: none;
        }

        &__type {
          border: none;
        }

        &__contentWrap {
          background-color: $formColor;
          border: none;
        }
      }

      &__formWrap {
        display: flex;
        flex-direction: column;
        margin-left: 5px;
        width: 100%;

        .fieldWrap input::placeholder {
          color: $activeFormColor !important;
        }
      }

      &__section {
        border: 2.5px solid $borderColor;
      }
    }
  }

  &__title {
    display: flex;
    justify-content: flex-start;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 30px;
    padding-bottom: 20px;
  }

  &__remoteWrap {
    margin-left: 10px;

    &__remoteAccess.ui.button {
      background: none;
      text-decoration: underline;
      padding: 0;

      &:hover {
        background: none;
      }
    }

    &__orIndent {
      display: flex;
      justify-content: flex-start;
    }
  }

  &__signInButton.ui.button {
    background-color: $buttonColor;
    margin-right: 10px;
    width: 50%;
    font-size: 16px;

    &:hover {
      background-color: $activeButtonColor;
    }
  }

  &__error {
    color: $errorColor;
    text-align: left;
  }

  &__formSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: $formColor;
    padding: 5px 20px 0 20px;
    margin-bottom: 20px;
    border: 1px solid $sideMenuButton;
    border-radius: 3px;

    @media only screen and (max-width: 480px) {
      padding: 15px 35px;
    }


    &__row {
      display: flex;
      flex-wrap: wrap;

      .col,
      .col-md-9,
      .col-sm-12 {
        padding: 0;
      }

      @media only screen and (max-width: 480px) {
        flex-direction: column;
      }
    }

    &__label {
      margin-bottom: 5px;
      align-items: center;
    }
  }
}

.container,
.con-sm-12,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1 {
  padding: 0;
}

.container {
  .row {
    padding: 0;
    margin: 0;
  }
}

.ui.menu.attached.tabular {
  margin-top: 20px;

  @media only screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;

    .tabItem {
      justify-content: end;
    }
  }
}

.ui.button.footerPanel__userManual__button {
  background-color: transparent !important;
  padding: 0;
}

.ui.selection.dropdown .menu {
  @include only-ie-11 {
    word-break: break-all;
  }
}
