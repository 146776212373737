.constructor-basket-currencies {
  width: 100%;
  display: flex;
  align-items: center;

  &__radio {
    margin-right: 15px;
  }

  label {
    margin: 0;
    padding: 0;
  }
}