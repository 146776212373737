@import '../../styles/colors';

.docs-transfer-messages {
  position: relative;

  &__search {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: $whiteColor;
  }

  &__field {
    flex: 2 1 auto;
    margin-right: 8px;
  }

  &__checkbox {
    top: 4px;
  }

  &__checkbox, &__btn {
    flex: 0 1 auto;
    margin-right: 8px;
  }

  .ui.big.active.inline.loader {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
  }

  &__messages-wrapper_loading {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
      width: 100%;
      height: 100%;
      background-color: $whiteColor;
      opacity: 0.7;
    }
  }

  .custom-table-row__data {
    cursor: auto;
  }
}
