@import '../../../styles/colors';

.fund {
  background: $formColor;
  box-sizing: border-box;
  min-height: 385px;
  width: 220px;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid $borderColor;
  

  &_maxHeight {
    height: 100%;
  }

  &__contentWrap {
    padding: 15px;
  }

  &__description {
    text-align: left;
    padding: 5px;
  }

  &__description-nsz {
    font-size: 18px;
    line-height: 21px;
    text-align: left;
    margin-right: -15px;
    margin-left: -15px;
    background-color: $white-silver;
    padding: 5px 15px;
  }

  &__type {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    background-color: $fundColor;
    padding: 5px;

    + div {
      height: 100%;
    }
  }

  &__name {
    font-size: 30px;
    display: table-caption;
    line-height: 30px;
    font-weight: bold;
    padding-bottom: 10px;
    text-align: left;
  }

  &__description {
    text-align: left;
  }

  &__dataWrap-nsz {
    display: flex;
    justify-content: space-between;
    padding: 7px 15px;
    margin-right: -15px;
    margin-left: -15px;
    background-color: $white-silver;

    &__value {
      font-size: 30px;
    }

    &__value-nsz-count {
      font-size: 13px;
      line-height: 16px;
    }

    &__value-nsz {
      font-size: 16px;
      line-height: 23px;
    }

    &__label {
      text-align: left;
    }

    &__label-nsz {
      text-align: left;
      font-size: 17px;
      line-height: 22px;
    }
  }

  &__dataWrap {
    display: flex;
    justify-content: space-between;
    padding: 7px 0px;

    &__value {
      font-size: 30px;
    }

    &__value-nsz-count {
      font-size: 13px;
      line-height: 16px;
      height: 100%;
    }

    &__value-nsz {
      font-size: 16px;
      line-height: 23px;
    }

    &__label {
      text-align: left;
    }

    &__label-nsz {
      text-align: left;
      font-size: 17px;
      line-height: 22px;
    }
  }

  &__dataWrap-fixed-nsz {
    display: flex;
    justify-content: space-between;
    padding: 7px 0px;
    align-items: center;

    &__value {
      font-size: 30px;
    }

    &__value-nsz-count {
      font-size: 13px;
      line-height: 16px;
      height: 100%;
    }

    &__value-nsz {
      font-size: 16px;
      line-height: 23px;
    }

    &__label {
      text-align: left;
    }

    &__label-nsz {
      text-align: left;
      font-size: 17px;
      line-height: 22px;
    }
  }

  &__checkout.ui.button {
    background-color: $fundCheckoutColor;
    margin: 0;
    border-radius: 0;
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-start;
    align-items: center;
    color: $blackColor;
    font-size: 20px;
    padding: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: unset;

    &:hover {
      background-color: $activeButtonColor;
    }
  }
}
