@import './../../../../styles/colors.scss';

.custom-text-input {
  border: none;
  border-radius: 4px;
  padding: 10px 15px;

  &:focus {
    outline: none;
  }

  &__error {
    &_left {
      text-align: left;
    }

    &_right {
      text-align: right;
    }
  }
}

.custom-text-input:disabled,
.custom-text-input[disabled] {
  opacity: .45;
}

.formInput {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 5px !important;
    margin-right: 7px;

    &.validationBox {
      display: flex;

      .fieldWrap .field {
        width: 80%;
      }
    }

    &.error {
      padding-top: 0;
    }

    @media only screen and (max-width: 480px) {
      width: 100% !important;
      max-width: initial !important;
    }

    .ui.selection.dropdown {
      width: 100%;
      min-width: 95px;
    }

    @media only screen and (max-width: 480px) {
      flex-direction: column;
    }

    .fieldWrap {
      display: flex;
      align-items: center;
      margin-right: 7px;
      position: relative;
      outline: none;

      .ui.input input {
        width: inherit !important;
        padding-right: 30px;
      }

      .write.icon, .icon-write-select {
        position: absolute;
        right: 5px;
        top: 11px;
        color: $borderColor;
        pointer-events:none;
        z-index: 9;

        &.icon-write-select {
          right: 30px;
        }
      }

      .eye.icon, .icon-eye-select {
        position: absolute;
        right: 5px;
        top: 11px;
        color: $borderColor;
        pointer-events: none;
        z-index: 9;
  
        &.icon-eye-select {
          right: 30px;
        }
      }

      .field {
        width: 100%;
        margin: 0 !important;

        &.disabled {
          opacity: 0.60;

          .disabled.ui.input {
            opacity: 1;
          }
        }
      }

      @media only screen and (max-width: 480px) {
        flex-direction: column;
      }
    }

    .fieldNumber {
      background-color: $white-silver;
      position: absolute;
      left: 0;
      top: 0;
      padding: 1px 5px;
    }

    .label {
      text-align: left;
    }
    
}