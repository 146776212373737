@import '../../../styles/colors';

.input-range {
  margin: 13px 0 10px 0;
  width: 100%;

  .input-range__label {
    display: none;
  }

  .input-range__track {
    background-color: $silver;
    height: 7px;
    border-radius: 2px;
  }

  .input-range__slider {
    background-color: $activeFormColor;
    border: 1px solid $iconColor;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 3px;
    box-shadow: 2px 2px 3px 0 rgb(0 0 0 / 50%);

    &:focus {
      outline: none;
    }
  }
}
