@import '../../../styles/colors';

.sideBar {
  width: 70px;

  &__sub-menu {
    text-align: right;
    padding-right: 30px;
    width: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 29px;
    outline: none;

    .sideBar__menuItem.ui.button {
      text-align: right;

      &:last-child {
        margin: 0;
        margin-right: 4px;
      }
    }
  }

  &__menuItem.ui.button {
    text-align: right;
    padding-right: 0;
    background: none;
    border: none;
    color: $sideMenuButton;
    font-size: 16px;
    white-space: nowrap;

    &:hover {
      background: none;
      border: none;
      color: $activeSideMenuButton;
    }

    &:focus {
      background-color: transparent;
      color: $sideMenuButton;
    }

    &:last-child {
      margin-top: 10px;
    }
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  z-index: 10001 !important;
  position: fixed;
  height: 100%;
  width: 250px;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.8em;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  z-index: 10000 !important;
  background: rgba(0, 0, 0, 0.3);
}
