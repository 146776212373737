@import '../../../styles/colors.scss';

.server-message-error {
    display: flex;
    align-items: center;
    justify-content: center;

    .warning.sign.icon {
        color: $red;
        font-size: 16px;
    }

    &__message {
        color: $red;
        font-weight: bold;
    }
}