@import '../../styles/colors';

.contract-info-form {
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1313px;
  position: relative;

  .main-btn.ui.button {
    height: 100%;
    width: 100%;
    min-height: 55px;
  }

  &__header-block {
    background-color: $documentFormColor;
    height: 100%;
    margin-right: 2px;

    table {
      width: 100%;
      height: 100%;
      text-align: left;
      min-height: 55px;

      tr {
        height: 50%;
      }

      td {
        padding: 0;
        padding-left: 5px;
        vertical-align: baseline;

        &:first-child {
          color: gray;
          width: 45%;
        }
      }
    }
  }

  &__content {
    background-color: $whiteColor;
    width: 100%;
    min-height: 527px;
  }

  .row {
    padding: 0;
    margin: 0;
  }

  .col-sm-12 {
    padding: 0;
    margin: 0;
  }

  &__controls {
    width: 100%;
    margin-bottom: 5px;
  }
}

