.united-frontend-block-popup {
  max-width: 600px;
  width: 100%;
  padding: 15px;

  &__attention {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  &__message {
    font-size: 16px;
  }
}