@import '../../../styles/colors.scss';

.risk-list {
  background-color: $formColor;
  padding: 10px 10px 10px 25px;
  overflow: hidden;
  margin: 0 7px 5px 0;

  &__title {
    margin: 0;
  }

  &__control-btn {
    border: none;
  }

  .caret.icon {
    font-size: 2em;
    background-color: transparent;
  }

  &__control-btn {
    background: transparent;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__control {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__risk {
    width: 100%;
    border: 1px solid $borderColor;
    border-bottom: 0;
    text-align: center;

    &:last-child {
      border-bottom: 1px solid $borderColor;
    }
  }

  &__risk-row {
    display: flex;
    justify-content: center;
    width: 100%;
    border: 1px solid $borderColor;
    border-bottom: 0;
    padding: 5px 0;

    &:last-child {
      border-bottom: 1px solid $borderColor;
    }
  }

  &__insurance-sum-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0;
  }

  &__insurance-sum-title {
    display: flex;
    align-items: center;
    margin-right: 10px;
    text-align: left;
  }

  &__insurance-sum-weight {
    border: 1px solid $borderColor;
    padding: 0 10px;
    height: 35px;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  &__risk-title, &__risk-weight {
    width: 70%;
    margin: 0;
  }

  &__package-desc, &__package-risk {
    width: 50%;
  }

  &__risk-check, &__risk-weight {
    width: 10%;
  }

  &__risk-weight {
    background: $whiteColor;
    border: none;
    text-align: center;
    border-radius: 4px;

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: .45;
    }
  }

  &__package-risk {
    border-right: 1px solid $borderColor;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__risk-check {
    display: flex;
    align-items: center;
    font-size: 18px;
    justify-content: center;

    .custom-checkbox__checkbox.check.bordered.icon {
      margin-top: 0px;
    }
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
  }

  &__hint {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: 1px solid $borderColor;
    border-bottom: 0;
    padding: 0 5px;

    &:last-child {
      border-bottom: 1px solid $borderColor;
    }
  }

  &__risk-wrapper {
    margin-bottom: 10px;
  }

  &__hint-text {
    width: 100%;
  }
}
