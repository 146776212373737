@import '../../../styles/colors';

.agent-data {
  &__applyButton.ui.button {
    background-color: $buttonColor;
    color: $blackColor;
    margin: 0;
    font-size: 20px;

    @media only screen and (max-width: 480px) {
      width: 100%;
    }

    &:hover {
      background-color: $activeButtonColor;
    }
  }

  &__insured-is-same-error {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  &__form-title {
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    font-size: 24px;
    color: $borderColor;
  }

  &__defaultButton.ui.button {
    background-color: $borderColor;
    align-self: end;
    margin: 10px;
    max-width: 160px;

    &:hover {
      background-color: $activeGreyButton;
    }
  }

  &__check-data {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    p {
      margin: 0;
      padding: 0;
      margin-right: 5px;
    }
  }

  &__formContainer {
    .row:first-child > .col {
      padding: 0;
    }
  }

  &__searchBar {
    width: 100%;

    .results {
      right: 7px !important;
      width: auto !important;
    }
  }

  &__searchBar.ui.search .ui, &__content {
    width: calc(100% - 7px);
  }

  &__addressCheckbox.ui.checkbox {
    margin: 10px;
    display: flex;
  }

  &__addressCheckboxWrap {
    display: flex;
    margin-bottom: 5px !important;

    p {
      margin: 0 7px 0;
      display: flex;
      align-items: center;
    }
  }

  &__formContainer {
    background-color: $whiteColor;

    .ui.form {
      display: flex;
      flex-direction: column;
    }

    &__inputs {
      padding: 10px;

      input::-webkit-contacts-auto-fill-button,
      input::-webkit-credentials-auto-fill-button {
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        right: 0;
      }

      @media only screen and (min-width: 1024px) {
        padding: 15px;
        padding-top: 5px;
      }

      @media only screen and (max-width: 720px) {
        padding-top: 25px;
      }
    }

    &__interface {
      display: flex;
      justify-content: flex-end;
      align-self: end;
      padding-top: 30px;
    }

    &__total {
      background-color: $formColor;
      display: flex;
      flex-direction: row;
      padding: 10px;

      &__label {
        width: 50%;
        text-align: left;
      }

      &__value {
        font-size: 25px;
        width: 50%;
      }
    }
  }

  &__error {
    margin: 10px;
    font-weight: bold;
    text-align: left;
    color: $errorColor;
  }
}

.modal .actions {
  display: flex;
  justify-content: center;
}

.modalView.ui.button {
  font-size: inherit;
}
