@import './../../../styles/colors.scss';

.users-admin-table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 663px;
    
    &__popup {
        left: 3px !important;
        min-width: 0 !important;
        max-width: 1313px !important;
        width: 100% !important;
        padding: 5px !important;
    }

    &__control-button {
        outline: none;
    }
    
    &__controls {
        display: flex;
        height: 100%;
        width: 100%;

      div {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:first-child {
            border-right: 1px solid $borderColor;
        }
    }

    }
    &__info-icon.circle.icon {
        height: 40px;
        font-size: 2rem;
        color: $infoColor;
        position: relative;
        margin-top: 20px;
        margin-left: 5px;
      }
    
    &__edit-icon {
        width: 40px;
    }

    &__info-popUp {
        height: 100%;
        width: 100%;
        max-width: 1313px;
    }

    &__user-control-buttons {
        border-top: 1px solid black;
        position: relative;
        display: flex;
    }

    &__checkbox {
        cursor: pointer;

        &.check.bordered.icon {
          font-size: 1.21rem;
          width: 25px;
          height: 25px;
          padding: 4px 0 !important;
          color: $transparent-color;
          border: 1px solid $gray-chalice;
          border-radius: 3px;
          box-shadow: none;
          margin-top: 8px;
          margin-left: 50%;
          margin: 0;
        }
    
        &_active.check.bordered.icon {
          margin-left: 50%;
          margin-top: 8px;
          margin: 0;
          color: $whiteColor;
          background-color: $green;
        }
      }

    &_popup {
        width: 900px;
        max-width: 100%;
        padding-top: 30px;
        min-height: unset;

      .flex-table {
        max-height: 300px;
        overflow: auto;
      }
    }
}
.close.icon {
    color: $red;
    font-size: 20px;
}

.check.circle {
    color: $green;
    font-size: 20px;
    display: flex;
    align-items: center;
    margin: 0;
    justify-content: center;
}

.control-button {
    border-radius: 5px;
    background: $buttonColor;
    display: flex;
    height: 35px;
    width: 150px;
}

button:active, button:focus {
    outline: none;
}

button::-moz-focus-inner {
    border: 0;
}

.custom-table-row__item {
    text-align: center;
}