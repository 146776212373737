@import '../../../styles/colors.scss';

.password-recovery-code-form {
  width: 430px;
  padding: 35px;

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__form-container {
    display: flex;
    flex-direction: column;
    padding: 0 35px;
  }
  
  &__title {
    display: flex;
    justify-content: flex-start;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 20px;
    text-align: start;
  }

  &__message {
    font-size: 1rem;
    text-align: left;
    margin-top: 10px;
  }

  &__error {
    max-width: 430px;
    font-size: 1rem;
    color: $errorColor;
    padding: 0 35px;
    text-align: left;
  }

  &__buttons {
    max-width: 430px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 35px 35px;
    margin-top: 20px;
  }

  &__btn-get-code-again.ui.button,
  &__btn-send.ui.button {
    background-color: $buttonColor;
    color: $blackColor;
    width: 200px;
    height: 40px;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: 16px;
    padding: 0;
    box-sizing: border-box;
    white-space: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-align: center;

    &:hover {
      background-color: $activeButtonColor;
    }

    &:focus {
      outline: none;
      background-color: $buttonColor;
    }
  }

  &__btn-get-code-again {
    background-color: $buttonColor;
  }

  &__btn-send {
    background-color: $formColor;
  }

  &__btn-get-code-again.ui.button {
    margin-right: 10px;
  }
}
