@import "../../../../../styles/colors";

$arrow-radius: 30px;

@mixin only-ie-11() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

.info-table-constructor-isz {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__grid {
    display: grid;
    grid-auto-rows: minmax(40px, auto);
  }

  &__row {
    display: contents;

    & > * {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      padding-left: 5px;
      padding-right: 5px;

      border-left: 1px solid $borderColor;
      border-bottom: 1px solid $borderColor;
    }
  }

  &__row_choosed > * {
    background-color: #80DD94 !important;
  }

  &__row_unactivated > * {
    color: $activeFormColor !important;
  }

  &__table-header > * {
    position: sticky;
    top: 0;
    z-index: 10000;

    font-weight: bold;
    background-color: $white-silver !important;

    & > *:not(:last-child) {
      margin-right: 7px;
    }
  }

  // Child table

  .collapse {
    grid-column: 1 / -1;
    box-shadow: inset 0 0 10px rgba(0,0,0,0.5);
    border-bottom: 1px solid $borderColor;
  }

  &__child-baskets-wrapper {
    position: relative;
    padding: 13px 20px 10px 0px;
  }

  &__strategy {
    display: flex;
    flex-direction: column;
  }

  &__multi-strategy-dropdown.icon {
    font-size: 23px;
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    color: $green;
  }

  &__multi-strategy-count {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  &__additional-mark {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: $white-silver !important;
    font-weight: bold;
    word-break: break-all;
  }

  &__child-item {
    margin-bottom: 10px;
  }

  &__first-connecting-arrow {
    position: absolute;
    border: 4px solid $green;
    border-radius: 0 0 0 $arrow-radius;
    top: 0;
    border-right-width: 0;
    border-top: 0;

    &:after {
      content: '\25BA';
      position: absolute;
      z-index: 2;
      right: -12px;
      bottom: -9.5px;
      font-size: 35px;
      color: green;
    }
  }

  &__child-connecting-arrow {
    position: absolute;
    border: 4px solid $green;
    border-radius: $arrow-radius 0 0 $arrow-radius;
    top: 0;
    border-right-width: 0;
    display: none;

    &:after {
      content: '\25BA';
      position: absolute;
      z-index: 2;
      right: -12px;
      bottom: -9.5px;
      font-size: 35px;
      color:  $green;
    }

    &:before {
      content: '';
      position: absolute;
      width: 10px;
      z-index: 1;
      right: -10px;
      top: -4px;
      height: 4px;
      background-color: $green;
    }
  }

  // Etc

  &__placeholder-not-found {
    font-size: 40px;
    color: $placeholderColor;
    height: 100%;
  }

  &__asset-button {
    position: relative;
    padding-right: 25px !important;

    &:focus {
      outline: none;
    }
  }

  &__asset-icon.info.circle {
    position: absolute;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $infoColor;
    right: 0;
    top: 5px;
  }

  &__garant-schedule {
    display: flex;
    flex-direction: column;
  }

  .ui.button.update {
    padding: 5px;
    font-weight: 300;
    background-color: $buttonColor;
  }

  i.icon.info {
    color: $infoColor;
  }

  &__scroll {
    background-color: $whiteColor;
  }

  &__view {
    margin-bottom: 0px !important;
  }

  &__thumb-horizontal {
    display: none;
    color: red;
  }

  @media only screen and (max-width: 1368px) {
    font-size: 13px;

    .sort-interface__search-value {
      width: 15px;
    }

    .sort-interface__alphabet {
      margin-right: 2px;
      &-icon.icon {
        width: 14px;
        font-size: 18px;
      }
    }
  }
}
