@import './../../../styles/colors.scss';

.controls-my-sales-table {
    display: flex;
    width: 100%;
    height: 100%;

    &__info-icon.circle.icon {
        height: 30px;
        font-size: 2rem;
        color: $infoColor;
        position: absolute;
        top: calc(50% - 15px);
        left: calc(50% - 18px);
      }

    button {
        padding: 0;
        margin: 0;
        border: 0;
        position: relative;
        background-color: $transparentIe !important;
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-child {
            border-right: 1px solid $borderColor;
        }
        width: 50%;
    };

    &__print-icon {
        height: 40px;
        min-height: 30px;
        width: 40px;
        min-width: 30px;
    }
}

.popUp-table {
    border-bottom: 1px solid $borderColor;
    height: 51%;
    display: flex;
    justify-content: center;
    flex-direction: column;

}
.popUp {
    height: 100%;
    width: 100%;

    .cloud-popup {
        height: 50%;

        .cloud-popup__popup {
            padding: 0;
        }

        .field-block {
            padding: 0;
        }
    }
}

.contract-status[name=waiting] {
    color: $errorColor;
}

.contract-status[name=error] {
    color: $errorColor;
}

.contract-status[name=paid] {
    color: $green;
}

.contract-status[name=not_paid] {
    color: $red;
}

.contract-status[name=partly] {
    color: $errorColor;
}

.contract-status {
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 50%;
}

.popUp-control-my-sales {
    padding: 0px !important;
}
