@import './../../../styles/colors.scss';

.payout-info, .payout-info-table  {
    min-width: 500px;
    &__header, &__table-row {
        display: flex;
        background: rgba(196, 196, 196, 0.43);
    }

    &__table-row {
        background: none;
    }

    .field-block__text {
        display: none;
    }

    &__header-text {
        font-weight: bold;
    }

    &__header-text,
    &__table-text, 
    &__table-text-control {
        text-align: center;
        width: 100%;
        max-width: 300px;
    }

    &__table-text-control {
        display: flex;
    }

    &__header-wrapper, &__table-wrapper {
        width: 100%;
    }

    &__header-payout-periods {
        display: flex;
        justify-content: flex-start;
        padding: 5px 5px 0 5px;
    }

    .field-block {
        display: flex;
        flex-direction: column;
    }
    
    i.icon, i.icons {
        margin-top: 10px;
        font-size: 2em;
    }

    .check.circle {
        font-size: 2em;
        margin-top: 5px;
    }

    &__delete-button {
        color: $red;
    }

    &__add-button {
        color: $green;
    }

    &__table-text {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.payout-info-table {
    border-left: 1px solid $borderColor;

    &__header-text,
    &__table-text, 
    &__table-text-control {
        border: 1px solid $borderColor;
        border-top: 0;
        border-left: 0;
    }

    .field-block {
        margin-bottom: unset;
        padding: 0 !important;
    }

    &__header-wrapper {
        background: $DustyGray;
    }

    &__header-text {
        background: $SilverBf;
    }

    &__table-text {
        background: $Mercury;
    }
}
