@import './../../../styles/colors';

.custom-input {
  width: 100%;

  &__input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__input {
    position: relative;
    width: 100%;
  }

  .ui.input {
    width: 100%;
    padding-top: 0;
  }

  &__error {
    color: $errorColor;
    display: flex;
  }

  &__required-icon {
    z-index: 2;
    position: absolute;
    top: calc(50% - 9px);
    color: $gray;
    right: 10px;

    &_loading {
      color: $errorColor;
    }
  }
}