@import '../../styles/colors';

.contragent-data {
  background-color: $whiteColor;

  &__btn.ui.button.main-btn {
    border-radius: 4px;
    font-size: 20px;
    padding: 0 30px;
  }

  &__control-interface {
    display: flex;
    justify-content: space-between;
    padding: 15px;
  }
}
