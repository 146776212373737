@import '../../../styles/colors';

.status-scan-checking {
    .warning.sign.icon {
        color: $red;
        font-size: 1.7rem;
        padding-top: 7px;
      }
    
      .checkmark.icon {
        color: $green;
        font-size: 2rem;
        padding-top: 7px;
      }
}

