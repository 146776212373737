@import '../../../styles/colors.scss';

.select-button {
  display: flex;
  flex-direction: column;
  width: 300px;

  &__button, &__content-button {
    background-color: $whiteColor;
    border: 0.5px solid $formColor;
    display: flex;
    align-items: center;
    color: $blackColor;
    min-width: 250px;
    height: 53px;
    padding: 0 5px;
  }

  &__text-button {
    margin: 0;
    padding: 0;
  }

  &__text-wrapper {
    display: flex;
    align-items: center;
    padding: 0 7px;

    .icon {
      font-size: 25px;
      display: flex;
      align-items: center;
      color: $iconColor;
      justify-content: center;
      width: 30px;
      height: 30px !important;
      border-radius: 5px;
    }

    .icon.caret {
      font-size: 20px;
    }
  }

  &__content-button {
    width: 100%;
    height: unset;
    min-height: 30px;
    justify-content: center;
    border-top: none;
  }
}
