.scan-list {
  display: flex;
  flex-wrap: wrap;
  padding: 3px 10px;
  width: 100%;

  &__scan {
    display: flex;
    align-items: center;
    margin-right: 10px;

    p {
      margin: 0;
      margin-right: 3px;
      padding: 0;
    }
  }
}