@import './../../../styles/colors.scss';

.scheduler-form {
    display: flex;
    flex-direction: column;
    background: $whiteColor;
    width: 100%;
    border-bottom: 1px solid $blackColor;

    &__back-button {
        width: 25%;
    }

    &__header {
        display: flex;
    }

    &__scheduler {
        display: flex;
        flex-direction: column;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
        justify-content: center;
        margin-left: 10px;
    }

    &__content {
        border-top: 2px solid $SilverChalice;
        display: flex;
        height: 100%;
    }

    &__menu {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        width: 25%;
        border: 2px solid $SilverChalice;
        border-bottom: none;
        min-height: 800px;
        border-top: none;
    }

    &__menu-item, &__menu-item-active {
        display: flex;
        padding: 20px;
        border-bottom: 2px solid $SilverChalice;
        justify-content: space-between;
        background: $Alto;
        cursor: pointer;
        outline: none;
        height: 40px;
        align-items: center;
    }

    &__menu-item-active {
        background: $Chalice;
    }

    &__item-number {
        background: $whiteColor;
        border-radius: 50px;
        min-width: 50px;
        max-height: 19px;
    }
}
