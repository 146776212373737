
.hint {
  width: 100%;
  padding: 30px 10px 10px 10px;
  text-align: left;

  @media only screen and (min-width: 720px) {
    width: 35%;
  }

  &__helpersWrap {
    border: 2.56143px solid #E5E5E5;
    padding: 15px;
  }

  &__title {
    margin-bottom: 10px;
  }
}
