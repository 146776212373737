@import '../../../styles/colors';

.tabItem {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px 5px 0 0;
  padding: 10px;

  @media only screen and (min-width: 720px) {
    margin-right: 20px;
  }

  &.active {
    border: 1px solid $tabBorder;
    background: $whiteColor;
    margin-bottom: -3px;
  }

  .ui.button {
    text-decoration: none;
    &:focus {
      background: none;
    }
  }

  .actionButton {
    width: 25px;
    height: 24px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .plusButton {
    background-color: $plusColor;
    color: $whiteColor;
    border-radius: 15px;
    font-size: 17px;
  }

  .removeButton {
    color: $errorColor;
    background: none;
    font-size: 22px;
    margin-right: 8px !important;
  }

  .errors {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 15px;
    height: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: $whiteColor;
    border-radius: 50%;
    background-color: $errorColor;
  }
}
