@import '../../../styles/colors';

.searchButton.ui.button {
  background-color: $formColor;
  color: $blackColor;
  border-radius: 0;
  margin-right: 3px;
  width: 84%;
  font-size: 20px;
  height: 55px;
  padding: 2% 4%;

  &:hover {
    background-color: gray;
  }

  &:disabled {
    opacity: 0.9 !important;
    color: $gray;
  }
}

.searchButton {
  &__content {
    display: flex;
    justify-content: space-between;
  };
}
