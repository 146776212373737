@import './../../../styles/colors.scss';

.confirmation-popup {
  max-width: 400px;
  width: 100%;
  background-color: $whiteColor;
  padding: 10px;

  &__btn-cancel {
    background-color: $red-carnation;
  }

  &__btn-confirm {
    background-color: $buttonColor;
  }

  &__btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 55px;
  }

  &__controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__btn-cancel, &__btn-confirm {
    width: 100px;
    height: 25px;
    font-weight: 700;
    border: none;

    &:focus {
      outline: none;
    }

    &:active {
      width: 105px;
      height: 30px;
      font-size: 1.1rem;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: bold;
  }
}   