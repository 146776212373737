@import '../../../styles/colors.scss';

.events-form-icon {
    border: 0;
    background-color: $transparentIe !important;
    position: relative;
    margin-right: 15px;

    img {
        width: 45px;
    }

    &:focus {
        outline: none;
    }
    
    &__count {
        background-color: $blue-dodger;
        color: $whiteColor;
        font-weight: bold;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        position: absolute;
        top: 10px;
        right: 0;
    }
}