@import './../../../styles/colors.scss';


.custom-table-row {
  &__item {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $borderColor;
    border-left: 0;
    border-top: 0;
    background-color: $tableRowColor;
    word-break: break-word;

    &_second {
      background-color: $gray-mercury;
    }

    &_header {
      background-color: $tableHeaderColor;
    }
  }

  &__item-block {
    height: 50%;
  }

  p {
    margin: 0;
    overflow: hidden;
  }

  &__headers {
    font-weight: bold;
    display: flex;
    flex-direction: column;
  }

  &__table-row {
    display: flex;
    width: 100%;
  }

  .row {
    padding: 0;
    margin: 0;
  }

  &__data {
    outline: none;
    &_highlight {
      * {
        background-color: #80DD94;
      }
    }
  }
}

.flex-table {
  .custom-table-row:not(:first-child) {
    .custom-table-row__item_header {
      display: none;

      @media only screen and (max-width: 767px) {
        display: flex;
      }
    }
    @media only screen and (max-width: 767px) {
      margin-bottom: 50px;
    }
  }
}
