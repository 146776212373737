@import '../../../styles/colors';

.timeout-window {
  max-width: 600px;

  &_not-visable {
    display: none !important;
  }

  &__timer {
    font-size: 5rem;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ui.header {
    margin-bottom: 0;
  }

  &__controls {
    display: flex;
    justify-content: center;

    .ui.button {
      width: 100px;

      &:first-child {
        background-color: $buttonColor;
        color: $whiteColor
      }
    }
  }

  &__finish-session.ui.button {
    width: 250px;
  }
}
