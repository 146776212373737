@import '../../../styles/colors';

.ui.button.bonus-button {
  border-radius: 4px;
  background-color: $green-meet;
  height: 36px;
  padding: 0 50px;
  color: $blackColor;

  &:hover, &:focus {
    background-color: $plusColor;
    font-weight: bold;
  }
}