@import '../../../styles/colors';

.validationIcon {
  background-color: $checkColor;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  padding: 5px;
  margin-left: 5px;

  i {
    font-size: 20px !important;
    color: $whiteColor;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
  }
}

