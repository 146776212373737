@import '../../../styles/colors';

%dotes {
  width: 8px;
  height: 8px;
  border-radius: 6px;
  background-color: $blue-dodger;
}

.docs-transfer-message {

  &__message-wrapper {
    display: flex;
    padding-bottom: 6px;
    width: 100%;
    height: 100%;
    align-items: center;
    position: relative;
    background-color: $whiteColor;
  }

  &__message-text-full {
    position: absolute;
    display: inline-block;
    background-color: inherit;
    text-align: justify;
    padding: 1px 25px 0 15px;
    border: .8px solid $borderColor;
    overflow: auto;
    top: -18px;
    left: -15px;
    width: 105%;
    z-index: 100;
  }

  &__message-close {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    right: -2px;
    z-index: 101;
  }

  &__message-text {
    display: inline-block;
    flex: 1 1 auto;
    text-align: justify;
    padding: 0 15px;
    overflow: hidden;
    max-height: 45px;
  }

  &__save-button {
    flex: 0 1 auto;
  }

  &__message-dots-wrapper {
    position: absolute;
    bottom: 4px;
    left: 14px;
    width: 40px;
    height: 8px;
  }

  &__message-dots {
    position: relative;
    bottom: 0;
    left: 14px;
    @extend %dotes;

    &:before {
      content: "";
      display: block;
      position: absolute;
      right: 14px;
      @extend %dotes;
    }

    &:after {
      display: block;
      content: "";
      position: absolute;
      left: 14px;
      @extend %dotes;
    }
  }
}
