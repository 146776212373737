@import '../../../styles/colors';

.check-block-editor {
  display: flex;
  flex-direction: column;

  &__control {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $gray-wild-sand;
    margin: 0 2px 2px 0;
    border: 1px solid $silverChalice;
    padding: 9px;
    cursor: pointer;
    outline: none;

    &_active {
      outline: none;
      background-color: $formColor;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  &__status {
    width: 100%;
    position: relative;
  }

  &__select-title {
    margin-right: 7px !important;
  }

  &__status-state {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .field.formInput {
    width: 100%;
  }

  .ui.selection.dropdown {
    width: 100%;
    padding-top: 12px;
    padding-left: 12px;
  }

  .ui.input {
    width: 100%;
  }
}