@import '../../../styles/colors';

$border: 1px solid $gray-silver;
$heightInput: 50px;

.check-document-list {
  margin: 15px;
  width: 1280px;

  &__control {
    display: flex;
    max-width: 1280px;
    width: 100%;

    .ui.button {
        width: 220px;
        margin: 0;
    }

    .breadcrumbs {
      width: 83%;
      background-color: transparent;
    }
  }

  &__content {
    width: 100%;
    min-height: 635px;
    background-color: $whiteColor;
    margin-bottom: 5px;
  }

}

.check-form-search-block {

  &__first-row, &__second-row  {
    width: 20%;
    position: relative;
  }


    @media only screen and (max-width: 1100px) {
      &__first-row  {
        width: 50%;
      }

      &__second-row {
        width: 33.3%;
      }
    }

  &__third-row, &__four-row {
    width: 25%;
    position: relative;
  }

  @media only screen and (max-width: 1100px) {

    &__third-row, &__four-row {
      width: 50%;
    }
  }

  .formInput {
    margin-bottom: 0px !important;
  }

  .row {
    margin: 0;
    padding: 0;
    min-height: 50px;
  }

  .ui.input {
    width: 100%;
    height: 50px;
    box-shadow: none;
    border: $border;
    border-right: 0;
    border-top: 0;

    input {
      border: 0;
    }
  }

  .fieldWrap, .formInput {
      width: 100%;
      height: 50px;

    input {
      border: $border;
      border-right: 0;
      border-top: 0;
      padding-left: 20% !important;
      padding-top: 3% !important;
      width: 100%;

    &:focus {
        outline: none;
      }
    }

    .react-datepicker-wrapper {
      width: 100%;

      .react-datepicker__input-container {
        width: 100%;
      }
    }
  }
  
  .range-date-picker {
    .fieldWrap.date svg {
      position: absolute;
      top: calc(50% - 12px);
      left: 80%;
      z-index: 1;
    }
  }

  .range-date-picker__wrapper {
    width: 50%;
    margin: 0;
  }

  .ui.selection.dropdown {
    padding: 0;
    margin: 0;
    width: 100%;
    min-width: unset;
    height: 50px;
    border: $border;
    border-radius: 0;
    border-right: 0;
    border-top: 0;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  .fieldWrap.date .react-datepicker__input-container input {
    padding-left: 30%;
  }

  &__control {
    display: flex;
    height: 50px;
  }

  .ui.button.searchButton {
    height: 50px;
    border: $border;
    border-right: 0;
    border-top: 0;
    margin: 0;
  }

  .extend-setting-btn__img {
    height: 50px;
    width: 50px;
    border: $border;
  }

  .checkbox-setting + .checkbox-setting_custom {
    top: 30px;
    right: 20px;
  }

  .dropdown.icon {
    height: 100% !important;
    display: flex;
    align-items: center;
  }
  .formInput .fieldWrap .ui.input input {
    border-bottom: $border;
    border-radius: unset;
  }

}
