@import '../../../styles/colors';

.preliminary {
  &__form {
    background-color: $whiteColor;

    .ui.form {
      display: flex;
      flex-direction: column;
    }

    &__inputs {
      padding: 10px;
      width: 60%;

      @media only screen and (min-width: 1024px) {
        padding: 30px 15px 30px 30px;
      }

      @media only screen and (max-width: 720px) {
        padding-top: 25px;
        width: 100%;
      }

      .field.formInput {
        background-color: $formColor;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 10px 10px 25px;
        margin-bottom: 15px !important;

        @media only screen and (min-width: 480px) {
          .error {
            align-self: flex-end;
          }
        }

        .fieldWrap {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .react-datepicker-wrapper {
            width: 60% !important;
          }

          @media only screen and (max-width: 480px) {
            flex-direction: column;
          }
        }

        .fieldNumber {
          background-color: #C4C4C4;
          position: absolute;
          left: 0;
          top: 0;
          padding: 1px 5px;
        }

        .label {
          display: flex;
          align-items: center;
          text-align: left;
          width: 40%;
        }

        @media only screen and (min-width: 480px) {
          .selection.dropdown, input {
            width: 60% !important;
          }
        }
      }

      svg {
        display: none;
      }

      .react-datepicker__input-container {
        .formInput {
          padding: 0px !important;
          margin-bottom: 0 !important;

          .fieldWrap {
            .field {
              .ui input {
                padding-left: 10px;
              }
            }
          }
        }
      }
    }

    &__interface {
      display: flex;
      justify-content: flex-end;
      padding: 15px;
    }

    &__nextButton.ui.button {
      background-color: $buttonColor;
      color: $blackColor;
      margin: 0;
      font-size: 20px;

      @media only screen and (max-width: 480px) {
        width: 100%;
      }

      &:hover {
        background-color: $activeButtonColor;
      }
    }

    &__total {
      background-color: $formColor;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 10px 10px 25px;
      margin: 0 0 1em;
      overflow: hidden;

      &__label {
        width: 50%;
        text-align: left;

        &_error {
          color: $errorColor;
        }
      }

      &__value {
        font-size: 22px;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &_error {
          color: $errorColor;
        }
      }
    }
  }

  &__info {
    position: absolute;
    right: 42%;
  }

  &__icon {
    color: $infoColor;
  }

  &__final-amount-error {
    color: $errorColor;
    font-size: 14px;
    text-align: left;
  }

  &__edit-basket {
    margin-right: auto;
    padding-left: 15px;
  }

  &__product-presentation {
    margin-right: 10px;
  }
}

.formCont {
  display: flex;

  @media only screen and (max-width: 720px) {
    flex-direction: column;
  }
}
