@import "../../../../../styles/colors";

.know-min-insurance-amount-button {
  height: 100%;
  width: 100%;

  &__button.ui.button {
    background-color: $buttonColor;
    color: $blackColor;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 310px;
    height: 100%;
    width: 100%;
  }

  &__button-text {
    padding: 0;
    margin: 0;
    font-size: 18px;
  }

  &__amount {
    height: 100%;
    width: 100%;
    background-color: $formColor;
    border-radius: 4px;
  }

  &__amount-text {
    padding: 0;
    margin: 0;

    font-size: 22px;
    line-height: 24px;
    font-weight: bold;
  }

  &__currency {
    padding: 0;
    margin: 0;
    font-size: 18px;
    line-height: 20px;
  }

  &__amount-text-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__calculator-icon.icon.calculator {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__min-insurance-block {
    margin: 0px 20% 0px -51%;
    padding: 5px 0px;
    width: 300%;
    z-index: 100;
  }

  &__table {
    width: 100%;
  }

  &__cancel-button {
    color: $red;
    margin-left: -1.5% !important;
    cursor: pointer;
  }

  .custom-table-row {
    &__item {
      border: 0.5px solid $borderColor;
      cursor: default;
    }
  }
}
