@import '../../../styles/colors';

.download-report {
    &__window-reports {
        width: 344px;
        background-color: $formColor;
        border: 1px solid $blackColor;
        display: flex;
        flex-direction: column;
        margin: 24px;
        text-align: left;
        border-bottom: none;
      }

    &__window-selects {
        width: 344px;
        background-color: $formColor;
        border: 1px solid $blackColor;
        display: flex;
        flex-direction: column;
        margin: 24px;
        text-align: left;
        zoom: 98%;
    }

    &__header {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        justify-content: center;
        padding: 5px;
    }

    &__titles {
        display: flex;
        background: $white-silver;
        justify-content: space-between;
    }

    &__title-name, &__row-left {
        width: 70%;
        border: 0.25px solid $blackColor;
        border-left: none;
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        justify-content: center;
    }

    &__title-download, &__row-right {
        width: 30%;
        border-top: 0.25px solid $blackColor;
        border-bottom: 0.25px solid $blackColor;
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        justify-content: center;
    }

    &__report-row {
        display: flex;
    }

    &__row-left, &__row-right {
        border-top: none;
    }

    &__row-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    &__row-right {
        display: flex;
        justify-content: center;
    }

    &__selects {
        display: flex;
        flex-direction: column;
        padding: 0px 10px;
    }

    .fieldWrap.date {
        width: 104.5%;
        svg {
            left: 80%;
            top: 15px;
        }
    }

    .ui.input {
        width: 100%;
        display: flex;
        align-items: center;
        height: 55px;

        input {
            height: 100%;
        }
    }

    .ui.selection.dropdown {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    &__select-date {
        display: flex;
    }

    &__select {
        width: 40%;
        height: 50px;
        margin-bottom: 10px;
        align-items: center;
        flex-wrap: wrap;
        border-radius: 0 !important;

        &.ui.selection.dropdown {
            min-height: 50px;
            display: flex;
        }

        .dropdown.icon {
            height: 100% !important;
            display: flex;
            align-items: center;
        }
    }

    &__title-date-input-start {
        position: absolute;
        z-index: 100;
        left: 40px;
        font-size: 0.9rem;
        color: gray;
    }

    &__title-date-input-end {
        position: absolute;
        z-index: 100;
        left: 200px;
        font-size: 0.9rem;
        color: gray;
    }
}
