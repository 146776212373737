.clientReports {
  &__checkbox-wrapper {
    display: flex;
    width: 100%;
    height: 37px;
    align-items: center;
    justify-content: center;
  }

  &__checkbox {
    position: absolute;
    left: 110%;
  }
}
