@import '../../../styles/colors';

.upload-scans-popup {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    &__window {
        background-color: $headerBlockInfo;
        min-width: 650px;
        padding: 5px 0 10px;
        box-shadow: 0 2px 4px 0 rgba(34,36,38,.12), 0 2px 10px 0 rgba(34,36,38,.15);
        position: absolute;
    }

    &:before {
        display: none;
    }

    &__title {
        font-size: 20px;
        font-weight: bold;
    }

    &__content {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    &__table {
        font-size: 16px;
        margin-bottom: 10px;
        text-align: left;

        td:first-child {
            padding-right: 10px;
        }
    }

    &__warning {
        font-size: 16px;
        font-weight: bold;
    }

    &__upload-scan {
        width: 400px;
    }
    
    &__controls {
        margin-top: 10px;
        display: flex;
        margin-left: 42px;
        align-items: center;

        .main-btn.ui.button {
            width: 100%;
            height: 40px;
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            padding: 5px;
            margin-right: 10px;
            border-radius: 4px;
        }
    }
}