.add-partners-form {
    &__controls {
        display: flex;
        width: 100%;

        .row {
            margin: 0;
            width: 100%;
        }

        .field.formInput {
            width: 100%;
            height: 100%;
            margin: 0 !important;

            .fieldWrap {
                width: 100%;
                height: 100%;
            }
            
            .ui.selection.dropdown {
                border-radius: 0;
                border-right: 0;
                height: 100%;
                display: flex;
                align-items: center;

                .dropdown.icon {
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    .ui.segment.dimmable {
        margin: 0;
        padding: 0;
    }
}