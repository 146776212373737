@import '../../../styles/colors';

.scheduler-edit-job, .scheduler-import-block {
  padding: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  width: 533px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__header {
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
  }

  .input-field {
    margin-left: 0 !important;

    &__scroll {
      width: 180px;
    }
  }

  &__save-button.ui.button {
    padding: 20px;
    max-width: 200px;
    border-radius: 4px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__save-button-content {
    display: flex;
    align-items: center;
    justify-content: center;

    .play.icon, .pause.icon {
      margin-left: 5px;
      height: 15px;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__date-picker {
    display: flex;
    padding: 0 !important;

    input {
      width: 300px;
      margin-left: 20px;
    }
  }

  &__date-picker-title {
    display: flex;
    align-items: center;
  }

  .react-datepicker__input-container {
    margin-left: 13px;
    input {
      outline: none;
      transform: rotateZ(0);
      padding: 10px;
      box-shadow: none;
      border: none;
      border-radius: 4px;
    }
  }

  .input-field {
    padding: 0 !important;

    .input-field__title {
      width: 38%;
    }
  }
}

.scheduler-import-block {
  padding-top: 10px;
  padding-bottom: 10px;
  width: auto;
  height: auto;

  &__header {
    padding: 10px;
    font-size: 14px;
    font-weight: unset;
  }

  .react-datepicker__input-container {
    margin-left: 0px;
  }

  .input-field {
    padding: 3px 0px !important;
    justify-content: center;
    margin-left: 40px;

    .input-field__title {
      width: 120px !important;
    }
  }

  &__date-picker {
    padding: 10px 0px !important;
    justify-content: center;
    width: 100%;
  }

  &__date-picker-title {
    text-align: left !important;
    margin-left: 90px;
  }

  .react-datepicker-wrapper {
    width: auto !important;
    margin-left: 20px;

    input {
      width: 180px !important;
      margin: 0 !important;
      border-radius: 4px !important;
      height: 40px !important;
    }
  }

  .ui.input {
    max-width: auto !important;
  }

  .input-field .field.formInput {
    width: 180px !important;
  }

  &__import-header {
    display: flex;
    align-items: center;
  }

  &__header {
    padding: 0;
    width: 50%;
    text-align: right;
    margin-right: 10px;
  }

  .ui.selection.dropdown {
    min-width: auto !important;
    width: 30%;
  }

  &__status-task {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__end-status-good {
    color: $green;
    margin-right: 10px;
  }

  &__end-status {
    color: $red;
    margin-right: 10px;
  }

  &__save-button {
    border-radius: 4px !important;
    padding: 20px;
    max-width: 200px;
  }

}
