@import '../../../styles/colors';

@mixin mid-size() {
  @media only screen and (max-width: 850px) {
    @content;
  }
}

@mixin small-size() {
  @media only screen and (max-width: 850px) {
    @content;
  }
}

.templates-panel {
  max-width: 1280px;
  width: 100%;
  margin: 15px;
  display: flex;
  flex-direction: column;
  background-color: $whiteColor;
  padding: 2px 2px 0;
  height: 100%;

  &__title {
    width: 100%;
    height: 31px;
    padding-left: 2px;

    display: flex;
    align-items: center;

    box-sizing: border-box;
    background-color: $gray-silver;
  }

  &__content {
    height: 100%;
    width: 100%;
    display: flex;
  }

  &__title-text {
    height: 26px;
    width: 20%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $buttonColor;
    font-size: 1.3rem;
    font-weight: bold;
  }

  &__info-block {
    width: 20%;
    margin-bottom: 2px;
    background-color: $formColor;
    margin-left: 2px;
    padding: 2px;

    p {
      margin: 0;
      padding: 0;
      padding-left: 5px;

      text-align: left;
    }

    @include mid-size {
      display: none;
    }
  }

  &__download {
    width: 80%;
    padding: 2px 0 2px 2px;

    .download-block__button-excel {
      display: none;
    }
    @include mid-size {
      width: 100%;
    }
  }

  &__title-download {
    width: 100%;
    height: 35px;
    padding-left: 5px;

    background-color: $silverChalice;
    font-size: 1.14286rem;
    text-align: left;

    display: flex;
    align-items: center;
  }

  &__input-link-download {
    border: none;
  }

  &__field-upload {
    background-color: $whiteColor;
    height: 53px;
    padding: 0 10px;
    margin: 0 15px;

    display: flex;
    align-items: center;
  }

  &__label {
    cursor: pointer;
    margin: 0;
    padding: 0;

    display: flex;
    font-size: 1.15rem;
    align-items: center;

    input {
      display: none;
    }
  }

  &__upload-controllers {
    margin-left: 10px;
    display: flex;
    align-items: center;

    &-button {
      margin-left: 5px;
      background-color: $buttonColor;
      color: $blackColor;
      border-radius: 5px;
      padding: 10px;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
    }
  }
}
