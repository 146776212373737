@import './../../../styles/colors';

.custom-select-input {
  width: 100%;

  &__select {
    position: relative;
    width: 100%;
  }

  &__error {
    color: $errorColor;
    display: flex;
  }

  &__required-icon {
    z-index: 2;
    position: absolute;
    top: calc(50% - 9px);
    color: $gray;
    right: 25px;

    &_loading {
      color: $errorColor;
    }
  }

  &__select-wrapper {
    width: 100%;
  }
}