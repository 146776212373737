.basket-popup {
  padding: 50px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    gap: 20px;
  }

  .main-btn.ui.button {
    border-radius: .28571429rem !important;
  }
}
