@import '../../../styles/colors';

.history-scans-popup {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &__window {
        width: 600px;
        max-height: 600px;
        overflow: auto;
        background-color: $formColor;
        position: absolute;
        box-shadow: 0 2px 4px 0 rgba(34,36,38,.12), 0 2px 10px 0 rgba(34,36,38,.15);
    }

    &:before {
        display: none;
    }

    &__table {
        width: 100%;
        text-align: center;

        td {
            border: 1px solid $borderColor;
        }
        
        th {
            border: 1px solid $borderColor;
            height: 30px;
            background-color: $grey-alto;
        }
    }

    &__save-button {
        background-color: transparent;
        border: none;
        width: 100%;

        &:focus {
            outline: none;
        }

        img {
            width: 40px;
        }
    }

    &__title {
        font-size: 16px;
        padding-left: 5px;
        margin: 0;
        margin-bottom: 5px;
    }

    &__check-status {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}