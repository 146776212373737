@import '../../../styles/colors';

.docs-transfer-menu {
  display: flex;
  width: 1280px;
  height: 100%;
  margin: 5px;

  &__navigation {
    width: 220px;
    height: 100%;
    flex: 0 1 auto;
  }

  &__menu {
    text-align: left;
    background: $formColor;
    box-sizing: border-box;
    min-height: 385px;
    height: 100%;
    width: 220px;
    display: flex;
    flex-direction: column;
    border: 1px solid $borderColor;
  }

  &__menu-item {
    width: 100%;
    background: $whiteColor;
    padding-left: 20px;
    height: 50px;
    border: 1px solid $borderColor;
    font-weight: 700;
    display: flex;
    font-size: 16px;
    align-items: center;

    &_active {
      background-color: $silverChalice;
    }
  }

  &__unread-messages {
    display: inline-block;
    background-color: $red;
    color: $whiteColor;
    padding: 1px 6px 2px;
    margin-left: 72px;
    border-radius: 2px;

    &:before {
      content: "+";
    }
  }

  &__form {
    margin-left: 5px;
    background: $whiteColor;
  }

  &__content {
    flex: 2 1 auto;
  }
}
