.last-check-info {
  min-width: 330px;

  &__table {
    min-width: 330px;
    td:first-child {
      width: 130px;

    }
  }

  td {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
  }
}