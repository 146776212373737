@import '../../../../../styles/colors';

@mixin only-ie-11() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

.guarantee-level {
  background-color: $formColor;
  align-items: center;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 100px;
  border-radius: 4px 4px 0 0;

  @media screen and (max-width: 1140px) {
    @include only-ie-11 {
      height: 270px;
    }
  }

  &__title {
    font-weight: bold;
    margin: 0 0 22px 0;
    padding-top: 5px;
    font-size: 16px;
  }

  &__currency-title {
    font-weight: bold;
    padding-top: 5px;
    font-size: 16px;
    margin: 0;
  }

  &__guarantee-value-message {
    margin: 0 0 3px 0;
    padding: 0;
    height: 18px;
  }

  &__guarantee-input-wrapper {
    display: flex;
    position: relative;
    align-items: center;
  }

  &__guarantee-percent {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &__currency, &__guarantee, &__option, &__product-type {
    display: flex;
    flex-direction: column;
    min-height: 100px;
  }

  &__favorite {
    display: flex;
    justify-content: space-between;
    width: 70%;
    padding-top: 10px;
  }

  &__input-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__guarantee {
    margin-bottom: 0;
    width: 209px;
  }

  &__currency {
    width: 158px;
  }

  &__currency-space-symbol {
    margin: 0;
    padding: 0;
    font-size: 24px;
  }

  &__radio-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 35px;
  }

  &__radio {
    margin-right: 10px;
    height: 17px;
  }

  &__guarantee-value {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__guarantee-input {
    width: 100px;
    margin-right: 3px;

    input {
      padding-right: 10px;
      width: 100px;
    }

    &_error {
      input {
        background-color: $Chablis !important;
        border: 1px solid $pink-cavern !important;;
      }
    }
  }

  &__error {
    color: $red;
    margin: 0;
  }

  &__option, &__product-type {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__select-option {
    height: min-content;
    width: 250px !important;
    z-index: 10001 !important;
    margin-bottom: 5px;
  }

  &__info-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 13px;
    height: 38px;
    width: 30px;
    z-index: 100000 !important;
  }
}
