@import './../../../styles/colors.scss';

.add-subdivisions-form {
    display: flex;
    flex-direction: column;

    &__controls {
        display: flex;
        width: 100%;
        height: 38px;

        .row {
            margin: 0;
            width: 100%;
        }

        .ui.input {
            height: 100%;
        }

        .field.formInput {
            width: 100%;
            height: 100%;
            margin: 0 !important;

            .fieldWrap {
                width: 100%;
                height: 100%;
            }
            
            .ui.selection.dropdown {
                border-radius: 0;
                border-right: 0;
                height: 100%;
                display: flex;
                align-items: center;

                .dropdown.icon {
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    .ui.input {
        width: 100%;
        
        input {
            border-right: none;
            border-radius: 0;
        }
    }

    .ui.segment {
        margin: 0;
        padding: 0;
    }
}