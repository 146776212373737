@import '../../../styles/colors';

.header-panel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__bonus-btn {
    margin-right: 15px !important;
  }

  &__united-frontend {
    width: 100%;
    background-color: $green-emerald;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 2px;
    font-weight: bold;
    letter-spacing: 1px;

    &_read {
      background-color: $orange-texas-rose;
    }
  }

  &__united-frontend-content {
    margin: 0;
    padding: 0;

    &_read {
      margin-left: 5px;
    }
  }

  &__main {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;
  }

  &__docs-transfer-unread-messages {
    background-color: $red;
    color: $whiteColor;
    padding: 2px 6px;
    margin-left: 4px;
    margin-right: -10px;
    border-radius: 2px;
    line-height: 14px;

    &:before {
      content: "+";
    }
  }
}

.headSections {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 24px;
  display: flex;

  .sectionButton.ui.button {
    background: none;
    position: relative;

    @media only screen and (max-width: 480px) {
      padding: 5px;
    }

    &.active {
      background-color: $formColor;
    }
  }
 }

.logoWrap {
  display: flex;
  align-items: center;
  outline: none;

  .logoImg {
    background-image: url('../../../assets/images/logo_blue.png');
    width: 165px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 15px;
    cursor: pointer;
    outline: none;
  }
}

.headInfoWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rightSideContent {
  display: flex;
  flex-direction: row;
  align-items: center;

  .menuButton.ui.button {
    font-size: 35px;
    background: none;
    padding: 0;
    margin-left: 25px;
    color: #003168;

    &.selectBranchIcon {
      margin: 0 20px 0 0px;
    }

    &:hover {
      color: #0267d8;
    }

    i {
      margin: 0 !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .menuButton.ui.button, .rightSideContent {
    display: none;
  }
}

