.popup-guarantee-warning {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  max-width: 700px;
  width: 100%;
  padding: 20px;

  &__content {
    font-size: 20px;
    margin-bottom: 20px;
  }

  &__title {
    font-weight: bold;
    font-size: 20px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 80%;
  }

  &__button.ui.button {
    height: 55px;
    max-width: 150px;
    width: 100%;
    font-size: 18px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}