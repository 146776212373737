@import '../../../styles/colors';

.field-block {
  display: flex;
  background-color: $formColor;
  justify-content: space-between;
  align-items: center;

  padding: 1.2% 2%;
  margin-bottom: 5px;
  box-sizing: border-box;

  &__text {
    margin: 0;
    padding: 0;

    width: 30%;
    text-align: left;

    &_bold {
      font-weight: bold;
    }

    &_big {
      font-size: 1.35rem;
    }

    @media only screen and (max-width: 800px) {
      font-size: 1.1rem;
    }
  }
}
