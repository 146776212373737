// import color variabels
@import '../../styles/colors.scss';

.auth {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__title {
    display: flex;
    justify-content: flex-start;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 30px;
    padding-bottom: 20px;
  }

  &__remoteWrap {
    margin-left: 10px;

    &__orIndent {
      display: flex;
      justify-content: flex-start;
    }
  }

  .formInput .fieldWrap {
    margin-bottom: 7px;
  }

  &__remoteAccess.ui.button {
    background: none;
    text-decoration: underline;
    padding: 0;

    &:hover {
      background: none;
    }
  }

  &__signInButton.ui.button {
    background-color: $buttonColor;
    color: $blackColor;
    margin-right: 10px;
    width: 50%;
    font-size: 20px;

    &:hover {
      background-color: $activeButtonColor;
    }

    &:focus {
      background-color: $buttonColor;
    }
  }

  &__form {
    background: $whiteColor;
    border: 1px solid $borderColor;
    box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.5);
    width: 430px;
    padding: 35px;

    &__interface {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
    }
  }

  &__error {
    color: $errorColor;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
  }

  &__restore-password {
    text-align: left;
    background: none;
    border: none;
    // color: $errorColor;
    text-decoration: underline;
    font-size: 14px;
    font-weight: bold;

    &:hover {
      background: none;
      border: none;
      color: $errorHoverColor;
    }

    &:focus {
      background-color: transparent;
      color: $errorColor;
    }

    &:last-child {
      margin-top: 10px;
    }
  }

  &__controls {
    justify-content: center;
  }
}