@import './../../../styles/colors.scss';

.checking-state {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &__success-icon {
        width: 20px;
    }
    
    &_error {
        background-color: $red-pipin;

        p {
            margin-right: 3px;
        }

        .warning.sign.icon {
            color: $red;
            font-size: 1.4rem;
        }
    }

    &_checked {
        p {
            margin-right: 3px;
        }

        background-color: $green-hint;
    }

    &_new {

    }

    &_half-checked {
        background-color: $blue-anakiwa;

        .question.circle.icon {
            font-size: 1.4rem;
            color: $blue;
        }

        p {
            margin-right: -5px;
        }
    }
}