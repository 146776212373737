@import '../../../styles/colors';

.breadcrumbs {
  display: flex;
  flex-direction: row;
  background-color: $whiteColor;
  word-break: break-all;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  &__section {
    background-color: $formColor;
    font-size: 20px;
    padding: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    height: 55px;
    cursor: pointer;
    flex: 1;

    &:focus {
      outline: none;
    }

    @media only screen and (max-width: 768px) {
      padding: 5px;
      font-size: 14px;
      height: 40px;
      min-height: 40px;
    }

    &.active {
      background-color: $activeFormColor;
      cursor: default;
    }

    &.subBreadcrumbs {
      background-color: $subBreadcrumbColor;
      height: 40px;
      font-size: 16px;
      font-weight: normal;
      margin: 2px;

      &:focus {
        outline: none;
      }

      &.active {
        background-color: $formColor;
        font-weight: bold;
      }
    }
  }

  .icon {
    color: $buttonColor;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
