@import './../../../styles/colors.scss';

.events-form {
    margin: 15px;
    display: flex;
    flex-direction: column;
    min-height: 850px;
    max-width: 1400px;
    width: 100%;
    

    &__content {
        background-color: $whiteColor;
        display: flex;
        margin-bottom: 7px;
        min-height: 750px;
        
        @media only screen and (max-width: 1024px) {
            flex-direction: column;
        }
    }

    &__controls {
        width: 220px;
        height: 600px;
        background-color: $formColor;
        border: 1px solid $borderColor;
        border-right: none;
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: 1024px) {
            width: 100%;
            height: 55px;
            flex-direction: row;
        }
    }

    &__back-btn {
        width: 100%;

        @media only screen and (max-width: 1024px) {
            width: 30%;
        }
    }

    &__current-page {
        height: 55px;
        background-color: $whiteColor;
        border-top: 1px solid $borderColor;
        border-bottom: 1px solid $borderColor;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        outline: none;

        @media only screen and (max-width: 1024px) {
            width: 50%;
            border-right: 1px solid $borderColor;
        }
    }

    &__title-page {
        padding: 0 10px 0 7px;
        margin: 0;
    }
    
    &__pages {
        @media only screen and (max-width: 1024px) {
            width: 100%;
            display: flex;
        }
    }

    .warning.sign {
        color: $red;
        font-size: 1.8rem;
        padding-top: 3px;
    }

    &__error-count {
        margin: 0;
        padding: 0;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $blue-dodger;
        font-weight: bold;
        color: $whiteColor;
    }

    &__table {
        width: calc(100% - 220px);
        border-left: 1px solid $borderColor;

        .custom-table-row__data {
            height: 111px;
        }

        @media only screen and (max-width: 1024px) {
            width: 100%;
        }
    }

    &__table-controls {
        .ui.input > input {
            border-left: 0;
            border-radius: 0;
        }

        .searchButton.ui.button {
            height: 38px;
            font-size: 16px;
        }
    }

    .row {
        margin: 0;
        padding: 0;
    }

    &__table-title {
        background-color: $formColor;
        border: 1px solid $borderColor;
        border-left: 0;
        margin: 0;
        padding: 5px 0;
    }
}