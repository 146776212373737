@import './../../../styles/colors.scss';

.warning-message-popup {
  max-width: 700px;
  width: 100%;
  background-color: $whiteColor;
  font-size: 20px;
  padding: 20px;

  &__title {
    font-weight: bold;
    font-size: 20px;
  }

  &__message {
    font-size: 18px;
    white-space: pre-line
  }

  &__link {
    font-size: 1rem;
  }
}