@import './../../../styles/colors';

.ui.bottom.left.popup {
  z-index: 10000;
}

.help-message {
  max-width: 400px !important;

  &__icon {
    background-color: transparent !important;

    .info.circle.icon {
      color: $infoColor;
      font-size: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
