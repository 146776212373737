@import '../../../styles/colors';

.sort-interface {
  display: flex;
  align-items: center;
  justify-content: center;

  &__alphabet {
    border: 0;
    background-color: $transparentIe;
    margin: 0 5px 0 0;
    padding: 0;
  }

  &__search-value {
    width: 16px;
  }

  &__alphabet-icon.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    width: 10px;
    margin: 3px 0 0 0;
    padding: 0;
  }

  &__filter-btn {
    border: 0;
    background-color: $transparentIe;
    padding: 0;
  }
}