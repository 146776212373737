@import "./../../../styles/colors";

.updating-united-session-frontend {
  max-width: 600px;
  width: 100%;

  &__content {
    padding: 15px;
  }

  &__attention {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  &__button.ui.button {
    background-color: $buttonColor;
    max-width: 190px;
    width: 100%;
  }

  &__button-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__btn-content {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 16px;
    color: $blackColor;
    white-space: pre;

    &_small {
      font-size: 14px;
    }
  }

  &__description {
    font-size: 16px;
    margin-bottom: 30px;
  }
}