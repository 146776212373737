@import '../../styles/colors.scss';

.buttons-and-paginations {
  display: flex;
    &__control-buttons {
        display: flex;
        width: 100%;
    }
    &__control-button {
        margin: 1px;
        padding-top: 4px;
        border-radius: 5px;
        background: $buttonColor;
        display: flex;
        height: 35px;
        width: 150px;
    }
}
.close {
    text-shadow: none;
}