.scheduler-admin-table {
    display: flex;
    width: 75%;

    .flex-table {
        width: 100%;
    }

    &__edit-button {
        display: flex;
        align-items: center;
        outline: none;
        flex-wrap: wrap;
        justify-content: center;
    }

    i.icon{
    font-size: 30px;
    color: red;
    margin-top: 10px;
    cursor: pointer;
    }

    &__pause-button {
        margin-top: 10px;
        margin-left: 5px;
        outline: none;
    }

    &__popup-error {
        cursor: pointer;
    }

    .ui.selection.dropdown {
        width: 95%;
        min-width: unset !important;
    }

    &__edit-button-icon {
        outline: none;
        cursor: pointer;
    }

    &__popup-item {
        width: unset !important;
    }
}
