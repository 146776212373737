@import '../../../styles/colors';

.templates-popup {
  width: 700px;
  padding: 20px 120px;
  background-color: $formColor;
  border: 1px solid $silverChalice;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__header-text {
    font-size: 1.2857rem;
    font-weight: 600;
  }

  &__controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__btn-cancel, &__btn-confirm {
    width: 120px;
    height: 45px;
    font-weight: 700;
    border: none;

    &:focus {
      outline: none;
    }

    &:active {
      width: 125px;
      height: 50px;
      font-size: 1.1rem;
    }
  }

  &__btn-cancel {
    background-color: $red-carnation;
  }

  &__btn-confirm {
    background-color: $buttonColor;
  }

  &__btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 55px;
  }

  &__import-errors {
    background: $whiteColor;
    margin-top: 20px;
    height: 400px;
    overflow: auto;
    border: 1px solid $blackColor;
    min-width: 500px;
  }

  &__error-row {
    padding: 5px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $blackColor;
  }

  &__warning-sign {
    color: $red;
  }
}
