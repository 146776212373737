@import '../../../styles/colors';

.row-product {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $documentFormColor;

  padding-left: 10px;
  margin-bottom: 4px;
  border: 1px solid $borderColor;

  &_big {
    height: 156px;
  }

  p {
    margin: 0;
    padding: 0;
    width: 70%;
    text-align: left;

    &:first-child {
      color: $borderColor;
      width: 60%;

      @media only screen and (max-width: 1000px) {
        width: 45%;
      }
    }
  }

  &__value {
    color: $blackColor !important;
    width: 100% !important;
  }

  &__row-value {
    width: 100%;
  }

  &__description {
    overflow: auto;
    border: 1px solid $borderColor;
    background-color: $documentFormColor;
    padding: 5px;

    p {
      padding: 0;
      margin: 0;
    }

    .fund {
      &__name {
        font-size: 1.2rem;
        display: block;
      }
      &__contentWrap {
        padding: 0;
        border: none;
      }
      &__dataWrap__value {
        font-size: 1rem
      }
      &__dataWrap {
        padding: 0;
        padding-bottom: 3px;
      }
    }
  }

  &.ransoms, &.garants {
    flex-direction: column;
    padding: 0;
  }
}
