@import '../../../styles/colors';

.scan-upload-input {
    width: 100%;

    &__scan-upload-label {
        width: 100%;
    }

    input {
        display: none;
    }

    &__upload-input {
        background-color: $white-silver;
        width: 100%;
        height: 40px;
        border: 1px solid $borderColor;
        display: flex;
        align-items: center;
        padding: 0 30px;
        position: relative;

        &:acitve {
            border: 1px solid $buttonColor;
        }
    }

    &__view {
        width: 35px;
    }

    &__input-text {
        margin: 0;
        padding: 0;
        color: $red;
    }

    &__remove-button {
        border: none;
        background-color: transparent;
        position: absolute;
        right: 5px;
        top: 9px;

        &:focus {
            outline: none;
        }

        .remove.icon {
            color: $red;
            font-size: 1.7rem;
        }
    }
}