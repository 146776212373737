@import '../../../styles/colors.scss';

.password-recovery-form {
  width: 430px;
  padding: 35px 35px 15px;

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__form-container {
    display: flex;
    flex-direction: column;
    padding: 0 35px 15px;
  }
  
  &__title {
    display: flex;
    justify-content: flex-start;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 20px;
    text-align: start;
    padding-bottom: 10px;
  }

  &__controls {
    padding: 20px 35px 35px;
  }

  &__send-button.ui.button {
    background-color: $buttonColor;
    color: $blackColor;
    margin-right: 10px;
    width: 100%;
    font-size: 20px;

    &:hover {
      background-color: $activeButtonColor;
    }

    &:focus {
      background-color: $buttonColor;
    }
  }

  &__message {
    font-size: 1rem;
    text-align: left;
  }

  &__error {
    max-width: 430px;
    font-size: 1rem;
    color: $errorColor;
    padding: 0 35px;
    text-align: left;
  }
}

