@import './../../../../../../styles/colors';

.basket-main-strategy-label {
  display: flex;
  align-items: center;

  &__label {
    margin: 0;
    background-color: $plusColor;
    font-weight: bold;
    font-size: 18px;
    padding: 5px 10px;
    border-radius: 5px;
  }
}