@import '../../../styles/colors';

.range-date-picker {
  display: flex;
  width: 100%;

  &__first-picker-wrapper {
    padding-right: 3px !important;
  }

  &__wrapper {
    position: relative;
    width: 110%;

    input {
      height: 50px;
      padding-top: 12px !important;
    }
  }

  &__title-date-input {
    position: absolute;
    z-index: 2;
    left: 5px;
    color: $gray;
    font-size: 0.9rem;
  }
}
