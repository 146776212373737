
.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__userInfo {
    text-align: left;
  }
}
