@import '../../../styles/colors.scss';


.beneficiary {
    background-color: $formColor;
    padding: 5px 28px 0 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid $sideMenuButton;
    margin-bottom: 10px;
    border-radius: 3px;

    &__validation-error {
        color: $red;
        font-weight: bold;
        text-align: left;
    }

    &__info-block-wrapper {
        position: relative;
        margin-bottom: 30px;
    }

    &__title {
        margin: 0;
        margin-bottom: 5px;
        padding: 0;
        text-align: left;
    }

    &__error {
        display: flex;
        justify-content: center;
        align-items: center;

        .warning.icon {
            color: $red;
            font-size: 16px;
        }
    }

    &__error-message {
        margin: 0;
        padding: 0;
        color: $red;
    }
}

.beneficiaries-form {
    background-color: $whiteColor;
    padding: 7px 15px 15px;

    .row {
        padding: 0;
        margin: 0 !important;
    }

    .field.formInput {
        margin-right: 5px !important;
    }

    .fieldWrap {
        margin: 0;
        width: 100%;

        input {
            width: 100%;
            height: 38px;
            border: 1px solid rgba(34,36,38,.15);
            border-radius: 3px;
            width: 100%;
            padding-left: 5px;

            &:focus {
                outline: none;
            }
        }

        .ui.input {
            width: 100%;
            height: 38px;
        }
    }

    &__config-block {
        display: flex;
        align-items: center;
        border: 1px solid $sideMenuButton;
        background-color: $formColor;
        padding: 5px 20px;
        margin-bottom: 10px;
        border-radius: 3px;
    }

    &__beneficiary-by-raw {
        margin-left: 10px;
    }

    &__beneficiary-count {
        margin: 0;
        padding: 0;
        margin-right: 10px;
    }

    &__submit-interface {
        display: flex;
        justify-content: space-between;
        padding-top: 37px;

        .ui.button {
            height: 52px;
            border-radius: 4px;
            font-size: 20px;
            padding: 0 30px;
        }
    }
}

.switcher-beneficiary-block {
    display: flex;
    position: relative;
    top: 1px;

    &_not-active {
        border-bottom: 1px solid $sideMenuButton;
    }

    &__item {
        cursor: pointer;
        display: flex;
        margin-right: 20px;
        height: 30px;
        align-items: center;
        position: relative;
        padding: 10px 22px 10px 5px;

        &:hover {
            font-weight: bold;
        }

        &_active {
            border: 1px solid $sideMenuButton;
            background-color: $formColor;
            border-radius: 5px 5px 0 0;
            border-bottom: 0;
        }
    }

    &__count-errors {
        margin: 0;
        padding: 0;
        background-color: $red;
        color: $whiteColor;
        position: absolute;
        border-radius: 10px;
        right: 0;
        width: 15px;
        height: 15px;
        display: flex;
        bottom: 13px;
        font-size: 12px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__circle {
        .icon {
           color: $green;
           font-size: 25px;
           display: flex;
           align-items: center;
        }
    }

    &__close {
        .icon {
           color: $red;
           font-size: 25px;
           display: flex;
           align-items: center;
        }
    }
}

.additional-block {
    background-color: $formColor;
    padding: 5px 28px 0 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid $sideMenuButton;
    border-radius: 3px;

    .col {
        padding: 0;
    }

    &__address {
        .ui.search .prompt {
            border-radius: 0;
        }
    }

    &__address-title {
        text-align: left;
        font-weight: bold;
        margin: 0;
        margin-bottom: 5px;
        padding: 0;
    }

    &__living-address {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }

    &__checkbox-text {
        margin: 0 7px;
        padding: 0;
    }
}
