@import "../../../../../styles/colors";

.minInsuranceAmountTable {
  &__amount {
    height: 100%;
    width: 100%;
    background-color: $formColor;
    border-radius: 4px;
  }

  &__amount-text {
    padding: 0;
    margin: 0;

    font-size: 22px;
    line-height: 24px;
    font-weight: bold;
  }

  &__currency {
    padding: 0;
    margin: 0;
    font-size: 18px;
    line-height: 20px;
  }

  &__amount-text-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
