@import '../../../styles/colors';

.switch-check-form {
  display: flex;
  padding: 0 2px 0 0;
  margin-bottom: 2px;
  width: 100%;

  &__text {
     margin: 0;
     padding: 0;
  }

  &__current {
    background-color: $formColor;
    padding-left: 18px;
    width: 45%;
    font-weight: bold;
  }

  &__tab {
    width: 55%;
    background-color: $gray-wild-sand;
    position: relative;
    padding-left: 30px; 
    display: flex;
    align-items: center;
    border: 1px solid $silverChalice;
    height: 45px;
    cursor: pointer;
    outline: none;

    &_first {
      border-right: none;
      outline: none;
    }
    
    &_active {
      background-color: $formColor;
      outline: none;
    }
  }

  .caret {
    font-size: 2.5rem;
    color: $buttonColor;
    position: absolute;
    top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;

    &.left {
      left: 2px;

      &:active {
        font-size: 2.9rem;
        top: 2px;
        right: -1px;
      }
    }

    &.right {
      right: 2px;

      &:active {
        font-size: 2.9rem;
        top: 2px;
        right: -1px;
      }
    }
  }

  &__title {
    background-color: $white-silver;
    border: 1px solid $silverChalice;
    padding: 2px 2px 2px 10px;
    text-align: left;
    margin: 0 2px 2px 0;
  }
}