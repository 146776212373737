@import '../../../styles/colors';

.scheduler-stop-job {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 42px;
    text-align: center;
    width: 500px;

    &__header {
        margin: 10px;
        font-size: 18px;
        font-weight: bold;
    }

    &__footer {
        margin: 20px;
        display: flex;
        justify-content: space-around;
    }

    &__confirm-button {
        background: $green !important;
        width: 100px;
        height: 40px !important;
        border-radius: 4px !important;
    }

    &__cancel-button {
        border-radius: 4px !important;
        background: $red !important;
        width: 100px;
        height: 40px !important;
    }
}
