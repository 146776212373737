@import '../../../styles/colors';

@mixin mid-size() {
  @media only screen and (max-width: 850px) {
    @content;
  }
}

@mixin small-size() {
  @media only screen and (max-width: 550px) {
    @content;
  }
}

.scheduled-task {
  background-color: $formColor;
  padding: 1% 2% 3%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }

  @include small-size {
    padding: 0;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__setting-row {
    display: flex;
    align-items: center;
    width: 80%;

    @include mid-size {
      width: 100%;
    }

    @include small-size {
      flex-direction: column;
      justify-content: center;
      margin-bottom: 20px;
    }
  }

  &__time-setting {
    display: flex;
    width: 60%;
  }


  .ui.input {
    margin: 0;
    width: 100px;

    input {
      text-align: center;
    }
  }

  &__title, &__status-next-title, &__status-next-value {
    word-wrap: break-word;
    text-align: left;
    margin: 0;
    padding: 0;
  }

  &__status-next-wrapper {
    width: 60%;
  }

  &__title {
    padding-left: 3%;
    width: 40%;

    @include small-size {
      width: 100%;
      text-align: center;
    }

    @include mid-size {
      font-size: 1.1rem;
    }
  }

  &__status-next-value {
    font-size: 0.9rem;
    font-weight: bold;
  }

  .ui.checkbox {
    padding: 0;
    height: 10px;
  }

  &__checkbox {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include small-size {
      padding-right: 20px;
    }
  }

  &__input {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include small-size {
      padding-right: 20px;
    }
  }

  .ui.input > input {
    width: 38px;
  }
}
