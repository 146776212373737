@import './../../../styles/colors.scss';
.custom-checkbox {
    &__checkbox {
        cursor: pointer;
        flex-shrink: 0;
        &.check.bordered.icon {
          font-size: 1.21rem;
          width: 1.45em;
          height: 1.45em;
          padding: .25em 0 !important;
          color: $transparent-color;
          background-color: $whiteColor;
          border: 1px solid $gray-chalice;
          border-radius: 3px;
          box-shadow: none;
          margin: 0;
        }

        &_active.check.bordered.icon {
          color: $whiteColor;
          background-color: $green;
        }

        &_disabled.check.bordered.icon {
          opacity: 0.5;
        }
      }
}
