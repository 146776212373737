@import './../../../styles/colors.scss';

.catalogs-admin-table {
    max-height: 700px;
    overflow: auto;

    &__headers {
        display: flex;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
    }

    &__header,  &__row,  &__row-control, &__row-id, &__header-row-id  {
        min-width: 200px;
        width: 100%;
        outline: none;
        border-right: 1px solid $DustyGray;
        background: $formColor;
        border-bottom: 1px solid $DustyGray;
        height: 30px;
    }

    &__header, &__header-row-id {
        height: 30px;
        align-items: center;
        display: flex;
        justify-content: center;
        border-top: 1px solid $DustyGray;
    }

    &__header {
        word-break: break-all;
    }

    &__row, &__row-control {
        word-wrap: break-all;
        overflow: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
    }

    &__row-control {
        cursor: pointer;
    }

    &__input-row {
        background: initial;
        word-wrap: break-all;
        border: none;
        outline: none;
        height: 100%;
        flex: 1;
        padding-left: 15px;
    }

    &__edit-header, &__edit {
        min-width: 200px;
        width: 100%;
        border-right: 1px solid $DustyGray;
        border-bottom: 1px solid $DustyGray;
        cursor: pointer;
        outline: none;
        background: $formColor;
        height: 30px;

        img {
            height: 28px;
        }
    }

    &__edit-header {
        border-top: 1px solid $DustyGray;
        min-width: 50px;
        width: unset;
    }

    &__edit, &__row-control  {
        min-width: 50px;
        width: unset;
    }

    &__row-id, &__header-row-id {
        min-width: 50px;
        width: unset;
        max-width: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__table-row {
        width: 100%;
        display: flex;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;

        &_edit {
            * {
                background: $whiteColor;
            }
        }

        &_deleted {
            * {
                background: $red-carnation;
            }
        }

        &_saveError {
            * {
                background: $red-carnation;
            }
        }
    }

    .check-circle-or-close-icon .close.icon {
        font-size: 28px;
    }
}
