.dp-policy-constructor-execution-success-popup {
  max-width: 450px;
  width: 100%;
  padding: 25px;

  &__message {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  &__user-info {
    display: flex;
  }

  &__user-info-title {
    font-weight: bold;
    margin-right: 5px;
  }

  &__user {
    font-weight: bold;
    margin-bottom: 5px;
  }

  table {
    background-color: white;
    border-collapse: collapse;
    width: 100%;
  }

  td:first-child {
    font-weight: bold;
  }

  th, td {
    border: 1px solid black;
  }
}
