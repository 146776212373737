@import './../../../styles/colors';

.partition-coefficient {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__coefs {
    display: flex;
    flex-direction: column;
  }

  &__title {
    text-align: left;
    margin-bottom: 12px;
  }

  &__coef-wrapper {
    background-color: $formColor;
    padding: 10px 10px 10px 25px;
      margin: 0 0 1em;
      overflow: hidden;
  }

  &__coef-input {
    display: flex;
    align-items: center;
  }

  &__percent {
    margin: 0 5px
  }

  &__input {
    width: 40px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    text-align: center;
    border: 0;
    border-radius: 4px;
    height: 36px;

    &:disabled {
      opacity: 0.5;
    }

    &:focus {
      outline: none;
    }

    &_error {
      background-color: $Chablis !important;
      border: 1px solid $pink-cavern !important;
    }
  }

  &__error {
    text-align: left;
    color: $errorColor;
  }

  &__info {
    position: absolute;
  }

  &__icon {
    color: $infoColor;
    padding-left: 5px;
  }
}
