@import './../../../styles/colors.scss';


.check-circle-or-close-icon {
    .close.icon {
        color: $red;
        font-size: 35px;
    }

    .check.circle {
        color: $green;;
        font-size: 35px;
        display: flex;
        align-items: center;
        margin: 0;
        justify-content: center;
    }
}