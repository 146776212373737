@import '../../../styles/colors';

.check-document-master {
    min-height: 800px;
    background-color: $whiteColor;
    padding: 15px;


  &__date-picker-wrapper {
    display: flex;
  }

  &__scroll {
    max-width: 500px;
    width: 100%;
  }

  .fieldWrap {
    display: flex;
    align-items: center;

    svg {
      right: 5px;
      left: unset;
    }
  }

  .selection.dropdown {
    margin-right: 5px;
    display: flex !important;
    align-items: center;
  }

  .select-loader {
    width: 24px;
  }

  &__message-status {
    display: flex;
    height: 20px;
  }

  &__loader-message {
    display: flex;
    align-items: center;
  }

  &__loader-text {
    margin: 0;
    padding: 0;
    margin-left: 5px;
    color: $gray;
  }

  &__field-block {
    display: flex;
    background-color: $formColor;
    justify-content: space-between;
    align-items: center;

    padding: 1.2% 2% 1.2% 4%;
    margin-bottom: 5px;
    box-sizing: border-box;

    &_column {
      padding: 1.2% calc(2% + 26px) 1.2% 4%;
      flex-direction: column;
      align-items: flex-start;

      p {
        margin-bottom: 5px;
      }
    }

    &_no-margin {
      margin-bottom: 0;
    }

    &_indent {
      padding: 1.2% calc(2% + 26px) 1.2% 4%;
    }
  }

  .range-date-picker {

    &__wrapper {
      display: flex;
    };

    .fieldWrap.date svg {
      position: absolute;
      top: calc(50% - 12px);
      left: 80%;
      z-index: 1;
    }
  }

  &__field-text {
    margin: 0;
    padding: 0;
    margin-right: 5px;
  }

  &__checkbox-wrapper {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      padding: 0;
      margin-right: 5px;
    }
  }

  &__checkboxes-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 60%;
    max-width: 500px;
  }

  &__checkbox-title {
    font-style: italic;
  }

  &__btn {
    display: flex;
    justify-content: flex-end;

    .ui.button {
      width: 150px;
      margin: 20px 0;
    }
  }

  .field.formInput {
    width: 100%;
  }

  .ui.selection.dropdown {
    width: 100%;
    height: 50px;
  }

  .fieldWrap input {
    height: 50px;
  }

  .dropdown.icon {
    height: 100% !important;
    display: flex;
    align-items: center;
  }
}
