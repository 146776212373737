
@import '../../styles/colors';

.dashboard {
  display: flex;
  flex-direction: column;
  padding: 15px;
  overflow: auto;
  overflow-x: auto;

  &__form {
    display: flex;
    flex-wrap: wrap;
  }

  &__empty-block {
    width: 247px;
    height: 412px;
  }

  &__table {
    margin-bottom: 25px;
    tr {
      position: relative;
      display: flex;
      margin-bottom: 25px;
      width: 100%;
      padding: 10px;
      
      td {
        display: flex;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }
   
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.5);
  }
   
  &::-webkit-scrollbar-thumb {
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;

    background-color: $green-emerald;
    &:hover {
      background-color: lighten($color: $green-emerald, $amount: 10);
    }
  }

  scrollbar-width: auto;
  scrollbar-color: $green-emerald $green-emerald;
}
