@import '../../styles/colors';


.clientReports {
  display: flex;
  width: 1280px;
  width: 100%;
  height: 100%;
  margin: 5px;


  .custom-table-row {
    &__item {
      border: 0.5px solid $borderColor;
      cursor: default;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  .input-field {
    padding: 0px;
    &__scroll {
      width: 100% !important;
    }
  }

  &__fund {
    text-align: left;
    background: $formColor;
    box-sizing: border-box;
    min-height: 385px;
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid $borderColor;
  }

  &__fund-text {
    margin: 5px;
  }

  &__form {
    margin-left: 5px;
    background: $whiteColor;
    width: 1055px;
  }

  &__field-block {
    min-width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background-color: $formColor;
    border-radius: 5px;
    margin: 3px 20% 3px 5px;
    padding: 0.5%;
  }

  &__field-block-number {
    min-width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background-color: $formColor;
    border-radius: 5px;
    margin: 3px 20% 6px 5px;
    padding: 0.5%;

    .ui.disabled.input>input {
      background-color: $disabledInputColor;
    }
  }

  &__filed-block-label {
    width: 30%;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    flex-wrap: nowrap;
    white-space: pre;
  }

  .formInput {
    .fieldWrap {
      margin-right: 0px !important;
    }
  }

  &__field {
    height: 38px;
    width: 100% !important;

    .ui.disabled.input {
      opacity: 1;
    }
  }

  &__contract-number {
    width: 100%;
    margin-right: 20%;

    .fieldWrap {
      margin-right: 0px;
    }

    .input-field {
      padding: 0px;
      margin-bottom: 0px;
      height: 38px;

      &__scroll {
        width: 100%;
      }

      &__input {
        height: 38px;
      }

      .ui.input {
        width: 100%;
      }
    }
  }

  &__search-fio {
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: right;
    width: 80.7%;
  }

  &__search {
    position: absolute;
    left: 81%;
  }

  &__btn {
    height: 40px !important;
    width: 100px;
    border-radius: 5px !important;
  }

  &__info {
    position: absolute;
    left: 81%;
    cursor: pointer;
  }

  &__icon {
    color: $infoColor;
  }

  &__table {
    margin: 0px 20% 0px 5px;
    padding: 5px 0px;
    width: 106%;
  }

  &__table-none {
    padding: 0px !important;
  }

  &__download {
    border-radius: 5px;
    margin: 30px 0px 10px 0px;
    width: 80%;
    display: flex;
    justify-content: flex-end;
  }

  &__download-button {
    border-radius: 5px !important;
  }
}
