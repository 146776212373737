@import './../../../styles/colors.scss';

.creating-partner-form {
    padding: 10px;

    .ui.compact.segment {
        margin: 0;
        padding: 6px 10px;
    }

    &__controls {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
    }

    .ui.button.main-btn {
        height: 50px;
        width: 130px;
        border-radius: 4px;
    }

    .input-field .field.formInput {
        width: 100% !important;

        input {
            padding-left: 30px !important;
        }
    }

    &__ip-addresses-block {
        display: flex;
        width: 100%;
        min-height: 45px;
        background-color: $formColor;
        padding: 3px 10px;
        align-items: center;
        margin-bottom: 5px;
    }

    &__ip-addresses-text {
        width: 20%;
        text-align: left;
        margin: 0;
    }

    &__ip-addresses-input {
        border: none;
        border-radius: 5px;
        height: 35px;
        padding: 0px 20px;
        margin-left: 30px;
        min-width: 270px;

        &:focus {
            outline: none;
        }
    }

    &__ip-addresses-button {
        height: 30px;
        padding: 5px 7px;
        margin-left: 30px;
        background-color: $buttonColor;
        border: none;
        border-radius: 5px;
        color: $blackColor;

        &:disabled {
            opacity: 0.5;
        }
    }

    &__ip-list-block {
        display: flex;
        align-items: center;
        padding: 3px 10px;
        min-height: 45px;
        background-color: $formColor;
    }

    &__ip-list-text {
        width: 23%;
        margin: 0;
        text-align: left;
    }

    &__ip-addresses-list {
        display: flex;
        margin-left: 30px;
        flex-wrap: wrap;
        width: 77%;
    }

    &__ip-address {
        display: flex;
        align-items: center;
        margin-left: 7px;
    }

    &__scroll {
      width: 80%;
    }
}
