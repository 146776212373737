@import './../../../../styles/colors';

.basket-creating-form {
  max-width: 1400px;
  width: 100%;
  padding: 10px;

  .ui.segment {
    background: unset;
    padding: 0;
    margin: 0;
  }

  .ui.dimmer {
    padding: 0;
    margin: 0;
  }
}