@import './../../../styles/colors.scss';

.add-reports-form {
    display: flex;
    flex-direction: column;

    &__filter {
        min-height: 40px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 10px;
    }

    &__controls {
        display: flex;
        width: 100%;
        flex-direction: column;
        background-color: $formColor;
        min-height: 40px;
        height: 100%;
    }
    
    .row {
        margin: 0;
        padding: 0;
    }

    .formInput {
        margin: 0 !important;
        min-height: 40px;
        height: 100%;
    }

    .formInput .fieldWrap {
        margin: 0;
        min-height: 40px;
        height: 100%;
    }

    .create-entity-btn {
        border: none;
        background: $buttonColor;
    }

    .ui.selection.dropdown {
        border-radius: 0;
    }

    .ui.segment.dimmable {
        margin: 0;
        padding: 0;
    }
}
