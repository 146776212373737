
.fileModal {
  width: 1000px !important;

  &__viewButton.ui.button {
    border: none;
    background: none;
    padding: 5px;
    margin: 0;

    .eye.icon {
      margin: 0 !important;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      background: none;
    }
  }

  &__content {
    .pg-viewer-wrapper {
      width: 100%;

      .pg-viewer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .photo-viewer-container {
          width: auto !important;
          height: auto !important;
          overflow: visible;

          .photo {
            width: 100% !important;
            height: auto !important;
            min-width: 400px;
            object-fit: contain;
          }
        }

        .pdf-viewer-container {
          width: 100%;
          height: auto;

          .pdf-canvas {
            width: 100%;
            height: auto;

            canvas {
              width: 100%;
              height: auto;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
