@import '../../../styles/colors.scss';

.resend-test-link-popup {
  width: 50%;

  padding: 35px;

  &__form {
    display: flex;
    flex-direction: column;

    gap: 20px;
  }

  &__form-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

  }
  
  &__message {
    display: flex;
    justify-content: flex-start;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 20px;
    text-align: start;

    padding-bottom: 20px;
  }

  &__sendButton.ui.button {
    background-color: $buttonColor;
    color: $blackColor;
    margin-right: 10px;
    width: 100px;
    font-size: 20px;

    &:hover {
      background-color: $activeButtonColor;
    }

    &:focus {
      background-color: $buttonColor;
    }
  }
  &__btn-cancel.ui.button {
    margin-right: 10px;
    width: 150px;
    font-size: 20px;
  }
}
