@import '../../../styles/colors';

.execution {
  &__formWrap {
    display: flex;

    @media only screen and (max-width: 1280px) {
      flex-direction: column;
    }
  }

  &__fake-input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
    opacity: 0;
  }

  &__wrapper-file-input {
    position: relative;

    &_error {
      .ui.active {
        background-color: $red !important;
      }
    }
  }

  &__formContainer {
    background-color: $documentFormColor;
    display: flex;
    flex-direction: column;
    padding: 15px;

    .ui.form {
      display: flex;
      flex-direction: column;
    }

    .select-button {
      .icon.file {
        margin-right: 13px;
      }
    }

    &__inputs {
      padding: 10px;

      @media only screen and (min-width: 1024px) {
        padding: 15px;
      }

      @media only screen and (max-width: 720px) {
        padding-top: 25px;
      }
    }

    &__interface {
      display: flex;
      justify-content: flex-end;
      align-self: end;
      padding-top: 30px;
    }

    &__total {
      background-color: $formColor;
      display: flex;
      flex-direction: row;
      padding: 10px;

      &__label {
        width: 50%;
        text-align: left;
      }

      &__value {
        font-size: 25px;
        width: 50%;
      }
    }
  }

  &__fileCount {
    margin-bottom: 10px;
    font-weight: bold;
    display: flex;
    padding-left: 5px;
  }

  &__leftBar {
    width: 40%;

    @media only screen and (max-width: 1280px) {
      width: 100%;
    }
  }

  &__rightBar {
    width: 60%;

    @media only screen and (max-width: 1280px) {
      width: 100%;
    }

    .hint {
      width: 100%;
      padding: 10px;

      &__helpersWrap {
        border: none;
        padding: 0 0 30px 0;
      }
    }

    .ui.segment {
      padding: 0;
      margin: 0;
      box-shadow: none;
      border: none;
      border-radius: 0;

      &.dimmed {
        border: 1px solid $borderColor;
      }
    }

    .fileArea  {
      background-color: $whiteColor;
      border: 1px solid $borderColor;
      position: relative;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;

      &__fileItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $borderColor;
        padding: 8px;


        .fileLeftBlock {
          display: flex;
          width: 55%;
        }

        &:first-child {
          border-top: 1px solid $borderColor;
        }
      }

      .docTypes.ui.selection {
        width: 240px;
      }

      .fileRightBlock {
        display: flex;
      }

      .fileNameTextExecution {
        text-align: left;
        word-break: break-word;
        margin: 0;
      }

      .removeButton.ui.button {
        border: none;
        background: none;
        color: $errorColor;
        display: flex;
        font-size: 20px;
        align-items: center;
        justify-content: center;
        padding: 5px;
        margin: 0;

        .remove.icon {
          margin: 0;
        }

        &:hover {
          background: none;
        }

        &:focus {
          outline: none;
        }
      }

      &__fileList {
        padding-top: 10px;
      }

      &__areaButton {
        height: 200px;
        width: 100%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 20px;

        &:focus {
          outline: none;
        }

        &__areaText {
          color: $placeholderColor;
          font-size: 16px;
          width: 100%;
        }
      }

      #dndArea {
        height: 100%;


      }
    }
  }

  &__printButton.ui.button {
    background-color: $whiteColor;
    border: 0.5px solid $formColor;
    display: flex;
    align-items: center;
    color: $blackColor;
    min-width: 250px;
    margin-bottom: 5px;

    .icon {
      font-size: 25px;
      display: flex;
      align-items: center;
      color: $iconColor;
      justify-content: center;
      width: 30px;
      height: 30px !important;
      border-radius: 5px;
    }
  }

  &__bottomContent {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;

    .icon {
      margin-right: 0 !important;
    }
  }

  &__saveButton.ui.button {
    background-color: $buttonColor;
    color: $blackColor;
    margin: 0;
    font-size: 20px;

    @media only screen and (max-width: 480px) {
      width: 100%;
    }

    &:hover {
      background-color: $activeButtonColor;
    }


    @media only screen and (max-width: 480px) {
      padding: 5px;
      margin-left: 15px;
    }
  }

  &__cancelButton.ui.button {
    background-color: $cancelColor;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    border: 0.5px solid $formColor;
    color: $blackColor;

    @media only screen and (max-width: 480px) {
      padding: 5px;
      margin: 0;
    }

    .icon {
      color: $errorColor;
      font-size: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2px;
    }

    &:hover {
      background-color: $activeCancelColor;
    }
  }

  &__cancelIcon {
    margin-left: 15px;
  }

  &__submitIcon {
    background-color: $checkColor;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    padding: 5px;
    margin-left: 15px;

    i {
      font-size: 20px !important;
      color: $whiteColor;
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin: 0 !important;
    }
  }

  &__error {
    color: $errorColor;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
  }

  &__select-type {
    display: flex;
    width: 300px;
    margin: 10px 0px;
  }

  &__checkbox-block {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background-color: $whiteColor;
    width: 300px;
    height: 53px;
    padding: 0px 3px;
  }

  &__checkbox-text {
    margin-left: 7px;
    color: $blackColor;
  }

  &__send-sms-button.ui.button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    background-color: $buttonColor;
    color: $blackColor;
    width: 300px;
    height: 53px;

    @media only screen and (max-width: 480px) {
      width: 100%;
    }

    &:hover {
      background-color: $activeButtonColor;
    }
  }

  &__send-sms-text {
    font-size: 14px;
  }

  &__send-sms-text-repeat {
    text-align: left;
  }

  &__popupContainer {
    width: 500px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__confirmPopup.ui.button {
    background-color: $buttonColor;
    color: $blackColor;
    margin-top: 15px;
    font-size: 16px;

    &:hover {
      background-color: $activeButtonColor;
    }
  }
}

