@import "../../../styles/colors.scss";
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');

.not-found-page {
    max-width: 1100px;
    width: 100%;
    height: 755px;
    display: flex;
    flex-direction: column;
    background: $whiteColor;
    margin: 10px;
    justify-content: space-between;

    &__main {
        display: flex;
    }

    &__left-side {
        display: flex;
        flex-direction: column;
        margin-left: 70px;
    }

    &__code {
        font-family: 'Roboto Mono', monospace !important;
        font-style: normal;
        font-weight: 300;
        font-size: 288px;
        line-height: 364px;
        display: flex;
        align-items: center;
        color: $blackColor;
        margin: 0;
    }

    &__right-side {
        margin-top: 150px;
    }

    &__image {
        display: block;
        max-width: 100%;
        height: auto;
    }

    &__text {
        max-width: 435px;
        font-family: 'Roboto Condensed' !important;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: $blackColor;
        text-align: left;
    }

    &__footer {
        display: flex;
        justify-content: center;
        margin: 50px;
    }

    &__back-button {
        background: $buttonColor;
        font-family: 'Roboto Condensed' !important;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 20px;
    }
}
