@import "../../../styles/colors.scss";

.info-table-for-user {
    display: flex;
    flex-direction: column;
    max-width: 1313px;
    margin-top: 5px;

    &__header, &__content {
        width: 100%;
        display: flex;
    }
    
    &__content {
           &_error { 
               .ui.selection.dropdown,
               .info-table-for-user__check,
               .info-table-for-user__managment {
                   background-color: $backgroundColorError;
               }
           }         
    }
    &__warning-message {
        color: red;
        text-align: left;
        width: 100%;

        i.icon.warning.sign {
            font-size: unset;
        }
    }
    &__child {
        max-width: 120px;
        width: 100%;
        background-color: $tableHeaderColor;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $placeholderColor;
        border-left: 0;
        border-top: 0;
        word-break: break-word;
        border-right: 1px solid $blackColor;
    }

    &__partner {
        max-width: 190px;
        width: 100%;
        background-color: $tableHeaderColor;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $placeholderColor;
        border-left: 0;
        border-top: 0;
        word-break: break-word;
        border-right: 1px solid $blackColor;
    }

    &__partner:first-child {
        border-left: 1px solid $blackColor;
    }

    &__subdivision {
        max-width: 500px;
        width: 100%;
        background-color: $tableHeaderColor;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $placeholderColor;
        border-left: 0;
        border-top: 0;
        word-break: break-word;
        border-right: 1px solid $blackColor;
    }

    &__role {
        max-width: 200px;
        width: 100%;
        background-color: $tableHeaderColor;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $placeholderColor;
        border-left: 0;
        border-top: 0;
        word-break: break-word;
        border-right: 1px solid $blackColor;
    }

    &__managment {
        max-width: 120px;
        width: 100%;
        background-color: $whiteColor;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $placeholderColor;
        border-left: 0;
        border-top: 0;
        word-break: break-word;
        border-right: 1px solid $blackColor;
    }

    &__managment-trash {
        width: 50%;
        border-right: 1px solid $blackColor;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    &__managment-add {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    &__check {
        max-width: 120px;
        width: 100%;
        background-color: $whiteColor;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $placeholderColor;
        border-left: 0;
        border-top: 0;
        word-break: break-word;
        border-right: 1px solid $blackColor;
    }

    .ui.dropdown:not(.button)>.default.text {
        color: $blackColor;
    }

    .ui.selection.dropdown {
        min-width: 0;
        width: 100%;
        height: 100%;
    }

    &__checkbox {
        cursor: pointer;
        &.check.bordered.icon {
          font-size: 1.21rem;
          width: 1.45em;
          height: 1.45em;
          padding: .25em 0 !important;
          color: $gray-chalice;
          border: 1px solid $gray-chalice;
          border-radius: 3px;
          box-shadow: none;
          margin-top: 8px;
          margin-left: 50%;
          margin: 0;
        }
    
        &_active.check.bordered.icon {
          
          margin-left: 50%;
          margin-top: 8px;
          margin: 0;
          color: $whiteColor;
          background-color: $green;
        }
      }
    
      &__trash {
          color: $red;
          cursor: pointer;
      }

      &__add-circle {
          color: $green;
          cursor: pointer;
      }
      i.icon { 
        margin-top: 10px;  
      }

      i.icon, i.icons {
        font-size: 2em;
    }
}
.info-table-for-user .ui.selection.dropdown {
    display: flex;
    align-items: center
}

.ui.selection.dropdown>.dropdown.icon {
    height: 100% !important;
    display: flex;
    align-items: center;
}