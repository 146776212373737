@import '../../../styles/colors';

$border: 1px solid $borderColor;

.underwriting-contract-col {
  display: flex;
  flex-direction: column;

  &__header {
    min-height: 25px;
    width: 100%;
    border-bottom: $border;
  }

  &__text {
    word-break: break-all;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    margin: 0;
    padding: 0;
  }
}

.underwriting-contract-table {
  display: flex;
  background-color: $headerBlockInfo;

  .underwriting-contract-col {
    border-right: $border;
    border-bottom: $border;
  }

  &:not(:first-child) {
    .underwriting-contract-col {
      &__header {
        display: none;
      }

      &__text {
        border: 0;
      }
    }
  }

  &__content {
    display: flex;
    width: 100%;
  }

  &__col-1 {
    width: 15%;
  }

  &__col-2 {
    width: 20%;
  }

  &__col-3 {
    width: 65%;
  }

  &__info-icon {
    display: flex;
    height: 100%;
    align-items: center;
    position: relative;
    bottom: 12px;
    outline: none;
  }

  .info.icon {
    color: $green;
    margin-left: 3px;
  }
}
