@import './../../../../../styles/colors';

.contest-result-item {
  width: 100%;
  background-color: $whiteColor;
  padding: 5px;
  margin-bottom: 10px;

  .row {
    padding: 0 !important;
    margin: 0 !important;
    border-bottom: 1px solid $blue-malibu;

    .col {
      text-align: center;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 18px;
    padding: 3px;
  }

  &__title-table {
    font-weight: bold;
  }

  &__col-title-table {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__row-item {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}