@import '../../../styles/colors';

.tooltip {
  &__title {
    font-size: 16px;
    text-align: left;
    margin-bottom: 10px;
  }

  &__text {
    margin-bottom: 10px;
    text-align: left;
  }
}
.field.formInput {
  .__react_component_tooltip.show {
    border: 1px solid $formColor;
    padding: 15px;

    @media only screen and (min-width: 1024px) {
      width: 15%;
      max-width: 280px;
    }

    @media only screen and (max-width: 720px) {
      display: none;
    }

    &.place-top:after  {
      border-top-width: 12px;
      border-left-width: 15px;
      border-right-width: 15px;
      bottom: -12px;
    }

    &.place-right:after  {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid $tooltipArrowColor;
      left: -10px;
    }

    &.place-bottom:after  {
      top: -12px;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 12px solid #C4C4C4;
    }

    &:after {
      border-top-color: $tooltipArrowColor;
    }
  }
}


