@import './../../../styles/colors.scss';

.partners-admin-table {
    min-height: 705px;

    &__edit-icon {
        width: 40px;
        cursor: pointer;
    }

    &__control-button {
        outline: none;
    }
} 
