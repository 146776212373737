@import './../../../styles/colors.scss';

.locking-form {
  min-height: 800px;

  display: flex;
  flex-direction: column;
  background-color: $whiteColor;
  padding: 2px;

  @media only screen and (min-width: 930px) and (max-width: 1024px) {
    min-width: 550px;
  }

  &__tab {
    width: 25%;
    background-color: $formColor;
    font-size: 16px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__tasks {
    padding: 2px 0;
  }

  &__task {
    padding: 0 15px;
    margin: 5px;
    display: flex;
    background-color: $formColor;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    border: 1px solid $borderColor;
    position: relative;
  }

  &__title {
    margin: 0;
    padding: 0;
  }

  &__loader {
    position: absolute;
    right: 60px;
  }

  &__interface {
    position: relative;
  }
}
