@import './../../../../../../styles/colors';

.strategy-filed-row {
  display: flex;
  align-items: center;
  background-color: $formColor;
  padding: 5px 8px;
  border-radius: 4px;
  margin: 5px 0;

  &__label {
    margin: 0;
    padding: 0;
    width: 30%;
    font-weight: bold;
    text-align: left;
    font-size: 14px;
  }
}