@import "./../../../../../styles/colors";

@mixin midSize() {
  @media only screen and (max-width: 1375px) {
    @content;
  }
}


.user-card-info-contest {
  background-color: $formColor;
  box-shadow: 0 0 10px $borderColor;
  margin-bottom: 15px;

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $blue-danube;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
  }

  &__row {
    text-align: left;
    padding: 0 20px;
    font-size: 16px;
    min-height: 45px;
    display: flex;
    align-items: center;

    &:after { // for ie
      content: '';
      min-height: inherit;
      font-size: 0;
    }
  }

  &__row-name, &__row-text {
    padding: 0;
    margin: 0;
    white-space: pre-wrap;
  }

  &__row-name {
    margin-right: 5px;

    &_mod_subdivision {
      width: 35%;
    }
  }

  &__row-text {
    &_mod_subdivision {
      width: 65%;
    }
  }

  &__row:nth-child(2n) {
    background-color: $gray-wild-sand
  }
}