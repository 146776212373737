@import '../../../styles/colors.scss';

.accept-agreement-partner-channel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__form {
    background: $whiteColor;
    border: 1px solid $borderColor;
    box-shadow: 0 10px 6px -6px $gray;
    width: 430px;
    padding: 35px;

    &__interface {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
    }
  }

  &__row {
    display: flex;
    margin-bottom: 20px;
  }

  &__text {
    display: flex;
    width: 100%;
    text-align: left;
    margin-left: 20px;
  }

  &__link {
    cursor: pointer;
    color: $blue;
    text-decoration: underline;
    margin-left: 5px;

    &:hover {
      color: $blue;
      text-decoration: underline;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__accept-btn.ui.button {
    width: 100%;
    max-width: 200px;
    border-radius: 10px;
    background-color: $green-meet;

    &:hover {
      background-color: $plusColor;
    }
  }
}
