@import './../../../styles/colors';

.barrier-fields {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__barriers {
    display: flex;
    align-items: center;
  }

  &__barrier-wrapper {
    display: flex;
    align-items: center;
  }

  &__percent {
    margin-left: 3px;
  }

  &__input {
    width: 40px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    text-align: center;
    border: 0;
    border-radius: 4px;
    height: 36px;

    &:disabled {
      opacity: 0.5;
    }

    &:focus {
      outline: none;
    }

    &_error {
      background-color: $Chablis !important;
      border: 1px solid $pink-cavern !important;
    }
  }

  &__separator {
    margin: 0 3px;
    font-size: 16px;
  }

  &__error {
    text-align: left;
    color: $errorColor;
  }

  &__desc {
    text-align: left;
    color: $checkColor;
  }
}