
.public-api-keys-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 200px;

  &__warning {
    display: flex;
    align-items: center;
    width: 80%;
    height: 50px;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 18px;
  }

  &__table {
    width: 375px;

    td {
      text-align: left;
      padding-bottom: 5px;

      &:first-child {
        font-weight: bold;
        padding-right: 15px;
      }
    }
  }

  &__warning-icon {
    font-size: 2em !important;
    color: #ff861d;
  }
}