@import './../../../../../../styles/colors';

.basket-strategy-title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: $formColor;
  border-radius: 4px;
  height: 40px;

  &__buttons {
    position: absolute;
    right: 5px;
    top: 4px;
    display: flex;
  }

  &__title {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
  }

  &__plus.icon {
    color: $green;
  }
}