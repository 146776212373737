@import '../../../styles/colors';

@mixin small-size() {
  @media only screen and (max-width: 550px) {
    @content;
  }
}

.download-block {
  width: 100%;
  background-color: $formColor;
  margin: 3px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1% 2%;

  &__icon-wrapper {
    width: 100%;
    max-width: 48px;
  }

  &:last-of-type {
    margin: 0;
  }

  @include small-size {
    flex-direction: column;
  }

  &__button-excel {
    border: none;
    background-color: transparent;
    padding: 0;

    &:focus {
      outline: none;
    }
  }

  &__loading-upload {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin: 0;
      padding: 0;
      margin-right: 5px;
    }
  }

  &__title-podft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 40%;
  }

  &__title {
    text-align: left;
    margin-bottom: 8px;
    padding: 0;
    font-weight: 800;

    @include small-size {
      text-align: center;
      width: 100%;
    }
  }

  &__podft-version {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &>p {
      margin: 0px;
    }
  }

  &__control-wrapper {
    width: 60%;
    display: flex;
    align-items: center;

    @include small-size {
      width: 100%;
    }
  }

  &__label {
    margin: 0;
    padding: 0;

    display: flex;
    font-size: 1.15rem;

    input {
      display: none;
    }
  }

  &__field-upload,
  &__input-text {
    background-color: $whiteColor;
    height: 53px;
    padding: 0 10px;
    margin: 0 15px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include small-size {
        font-size: 1rem;
      }
    }
  }

  &__input-text {
    border: none;
    outline: none;
    width: 90%;
  }

  &__field-block {
    width: calc(100% - 85px);
  }

  &__error-block {
    display: flex;
    align-items: center;
    justify-content: center;

    .warning.sign.icon {
      color: $errorColor;
    }
  }

  &__error {
    margin: 0;
    padding: 0;

    color: $errorColor;
    font-weight: bold;
  }

  .plusButton {
    background-color: $plusColor;
    color: $whiteColor;
    border-radius: 25px;
    font-size: 20px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .removeButton {
    color: $errorColor;
    background: none;
    font-size: 22px;
    margin-right: 8px !important;
  }

  &__download-link {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 48px;
    justify-content: space-between;

    @include small-size {
      width: 100%
    }
  }

  &__remove-wrapper {
    height: 18px;

    &:focus {
      outline: none;
    }
  }

  &__link-wrapper {
    width: 60%;
  }
}