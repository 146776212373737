@import '../../../styles/colors';

.select-branch-popup {
  max-width: 700px;
  height: 300px;
  width: 100%;
  border: 0;

  &__window {
    height: 100%;
    background-color: $whiteColor;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
  }

  &__select {
    max-width: 450px;
    width: 100%;
  }

  &__header-title {
    font-size: 18px;
    font-weight: bold;
  }

  .field.formInput, .fieldWrap, .ui.selection.dropdown {
    width: 100%;
  }

  .field.formInput {
    margin-bottom: 20px !important;
  }

  .fieldWrap .label {
    text-align: left;
    margin-bottom: 5px;
  }

  &__buttons {
    max-width: 450px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__btn-select, &__btn-cancel {
    width: 225px;
    height: 35px;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: 16px;

    &:focus {
      outline: none;
      background-color: $activeGreyButton;
    }
  }

  &__btn-select {
    background-color: $buttonColor;
    width: 45%;
  }

  &__btn-cancel {
    background-color: $formColor;
    width: 45%;
  }
}