@import './../../../styles/colors.scss';

.create-entity-btn, .create-entity-btn-disabled {
    background-color: $buttonColor;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border: 1px solid $gray-chalice;

    &:focus {
        outline: none;
    }

    &:active {
        background-color: $gray-chalice;
    }

    .add{
        font-size: 20px;
        color: $whiteColor;
        margin-right: 5px;
    }

    &__icon-add, &__icon-add-disabled {
        background: $green;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
    }

    &__icon-add-disabled {
        background: $gray;
    }

    p {
        margin: 0;
        padding: 0;
        font-size: 16px;
    }

    i.icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1px 5px 0 5px;
    }
}

.create-entity-btn-disabled {
    opacity: .45!important;
    height: auto;
    color: $blackColor;
}