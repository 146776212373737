.contest-invite-banner-popup {
  min-width: 400px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__banner {
    max-width: 850px;
    cursor: pointer;
  }

  .ui.dimmer {
    background-color: unset !important;
  }
}
