@import '../../../styles/colors';

@mixin mid-size() {
  @media only screen and (max-width: 850px) {
    @content;
  }
}

@mixin small-size() {
  @media only screen and (max-width: 850px) {
    @content;
  }
}

.admin-panel {
  max-width: 1280px;
  width: 100%;
  min-height: 800px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  background-color: $whiteColor;
  padding: 2px 2px 0;
  height: 100%;

  &__label-checkbox {
    margin-right: 10px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
  }

  &__rewrite {
    background-color: $formColor;
    display: flex;
    padding-left: 4.8%;
    padding-top: 10px;
    align-items: center;
  }

  &__scheduler {
    background: $formColor;
  }

  &__title {
    width: 100%;
    height: 31px;
    padding-left: 2px;

    display: flex;
    align-items: center;

    box-sizing: border-box;
    background-color: $gray-silver;
  }

  &__content {
    height: 100%;
    width: 100%;
    display: flex;
  }

  &__title-text {
    height: 26px;
    width: 20%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $buttonColor;
    font-size: 1.3rem;
    font-weight: bold;
  }

  &__info-block {
    width: 20%;
    margin-bottom: 2px;
    background-color: $formColor;
    margin-left: 2px;
    padding: 2px;

    p {
      margin: 0;
      padding: 0;
      padding-left: 5px;

      text-align: left;
    }

    @include mid-size {
      display: none;
    }
  }

  &__download {
    width: 80%;
    padding: 2px 0 2px 2px;

    @include mid-size {
      width: 100%;
    }
  }

  &__title-download {
    width: 100%;
    height: 35px;
    padding-left: 5px;

    background-color: $silverChalice;
    font-size: 1.14286rem;
    text-align: left;

    display: flex;
    align-items: center;
  }

  &__input-link-download {
    border: none;
  }
}

.updating-passports {
  background-color: $formColor;

  &__field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1% 2%;
    width: 100%;
  }

  &__input {
    background-color: $whiteColor;
    border: none;
    width: 100%;
    height: 53px;
    padding: 0 10px;
    margin: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:focus {
      outline: none;
    }
  } 

  &__input-wrapper {
    width: 60%;
    padding: 0 30px 0 65px;
    display: flex;
    flex-direction: column;
    position: relative;

    &_without-icon {
      padding: 0px 65px 0 49px;
    }
  }

  &__loading-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;

    p {
      margin: 0 5px 0;
      padding: 0;
    }
  }

  &__label {
    position: absolute;
    padding-left: 20px;
    font-size: 0.9rem;
    color: $gray;
  }

  &__title {
    width: 40%;
    padding-left: 3%;
    margin: 0;
    text-align: left;
  }

  &__network-mode {
    display: flex;
    width: 40%;
    padding-left: 3%;
  }

  &__network-text {
    margin-right: 20px;
  }
}
