@import '../../../styles/colors.scss';

.input-field {
  display: flex;
  width: 100%;
  min-height: 45px;
  background-color: $formColor;
  padding: 3px 10px;
  align-items: center;
  margin-bottom: 5px;

  &_checkbox, &_date, &_select {
    justify-content: start;
  }

  .ui.input {
    width: 100%;
  }

  .formInput{
    margin-bottom: 0 !important;

    input:disabled {
      color: $blackColor;
    }
  }

  &_error {
    input {
      background: #fff6f6;
      border-color: #e0b4b4;
    }

    .input-field__title {
      color: $red;
    }

  }

  &__title {
    margin: 0;
    width: 20%;
    text-align: left;
  }

  .field.formInput {
    width: 70%;
    margin-right: 0;
  }

  .fieldWrap, &__input {
    width: 100%;
  }

  &__input {
    border: none;
    height: 35px;
    border-radius: 4px;
    padding: 0 10px;
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);

    &:focus {
      outline: none;
    }
  }

  &__text {
    width: 70%;
    text-align: left;
    word-break: break-word;
    padding-left: 5px;
  }

  &__wrapper-select {
    width: 80%;
    display: flex;

    .input-field__scroll {
      width: 100%;
    }
  }

  .ui.selection.dropdown {
    width: 100%;
    min-width: 250px;
    padding-left: 10px;

    .search {
      padding-left: 10px !important;
    }
  }

  &__wrapper-datepicker {
    width: 80% !important;

    input {
      width: 100%;
      border: none;
      height: 38px;
      border-radius: 4px;
      text-align: center;
      padding-left: 0 !important;

      &:focus {
        outline: none;
      }
    }
  }

  &__scroll {
    width: 80%;
  }
}