@import './../../../../../styles/colors';

.description-assets-popup {
  max-width: 700px;
  width: calc(100% - 30px);
  position: relative;
  padding: 25px;
  min-height: 205px;

  &__content {
    display: flex;
    justify-content: space-between;
    overflow: auto;
    width: 100%;
    max-height: 500px;
    height: 100%;
  }

  &__desc {
    text-align: left;
    margin-bottom: 10px !important;
    padding-right: 130px;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  &__segment {
    &.ui.segment {
      padding: 0;
      border: 0;
      box-shadow: none;
      background-color: $transparentIe;
      margin: 0 20px 0 0;
    }

    &_loading {
      width: 100px;
      height: 100px;
    }
  }

  &__dimmer.inverted.dimmer.active.visible {
    background-color: unset;
    border: 0;
  }
}

.trading-view-chart-popup, .trading-view-minichart-popup {
  background-color: $whiteColor;
  max-width: 1200px;
  width: calc(100% - 30px);
}

.trading-view-reporting-popup {
  background-color: $whiteColor;
  max-width: 800px;
  width: calc(100% - 30px);
}

.trading-view-technical-popup {
  background-color: $whiteColor;
  max-width: 900px;
  width: calc(100% - 30px);
}

.tradingview-widget-container {
  padding-right: 28px;
  width: 100%;

  #tradingview_chart_root_id {
    height: 700px;
  }

  #tradingview_root_id {
    height: 700px;
  }

  #tradingview_technical_analysis_root_id {
    height: 450px;
  }

  #tradingview_minichart_root_id {
    height: 500px;

    iframe {
      width: 100%;
      height: 100% !important;
    }
  }
}
