@import '../../../styles/colors';

.version-dp {
  display: flex;
  flex-direction: column;
  width: 350px;
  text-align: left;

  &__title, &__version {
    margin: 0;
    padding: 0;
  }

  &__title {
    margin-right: 4px;
  }

  &__button {
    width: 200px;
    border: 0;
    background-color: transparent;
    color: $blue;
    text-decoration: underline;

    &:focus {
      outline: none;
    }
  }

  &__controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__trigger {
    margin-right: 10px;
  }
  
  &__right {
    display: flex;

    @media only screen and (max-width: 480px) {
      justify-content: center;
      margin: 15px 0;
    }

    &__button.ui.button {
      border: none;
      background: none;
      padding: 0;
      color: $blackColor;
      font-weight: normal;
      text-decoration: underline;

      &:hover {
        background: none;
      }
    }
  }
}