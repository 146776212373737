
.userSearch {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 53px;
  margin-bottom: 20px;

  &__avatar {
    width: 60px;
    height: 52px;
    border-radius: 5px 0 0 5px;

    @media only screen and (max-width: 480px) {
      display: none;
    }
  }

  &__content {
    display: flex;  
    position: absolute;
  }

  &__searchBar {
    form .ui.huge.action {
      input {
        height: 52px;
        width: 300px;
        border-radius: 0;

        @media only screen and (max-width: 480px) {
          min-width: 100px;
          width: calc(100% - 50px);
          border-radius: 5px 0 0 5px;
        }
      }
    }
  }
}

.ui.icon.button {
  width: 50px;
  display: flex;
  justify-content: center;

  .icon {
    font-size: 18px;
  }
}
