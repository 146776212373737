
.auth {
  &__title {
    font-size: 25px;
  }

  &__form__interface {
    justify-content: center;
  }
}
