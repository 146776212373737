@import '../../../styles/colors';

.main-btn {
  &__text {
    margin: 0;
    padding: 0;

    &_loading {
      opacity: 0;
    }
  }

  &__loader.active.loader.inline {
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 16px);
  }

  &.ui.button {
    background-color: $buttonColor;
    color: $blackColor;
    border-radius: 4px;
    padding: 10px;
    font-size: 20px;
    margin: 0;
    height: 55px;
    position: relative;

    &:hover {
      background-color: $activeButtonColor;
    }
  }
}
