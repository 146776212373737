@import '../../../styles/colors';

.ransom-table__wrapper {
  max-height: calc(56px * 6 + 1px);
  overflow-y: auto;

  .ransom-table {
    border-collapse: collapse;

    tr {
      td,
      th {
        border: 1px solid $borderColor;
        height: 56px;
      }

      td:first-child,
      th:first-child {
        border-left: 0;
      }

      td:last-child,
      th:last-child {
        border-right: 0;
      }

      &:last-child {
        td,
        th {
          border-bottom: 0;
        }
      }
    }
  }
}
