@import '../../../../../styles/colors';

.filter-constructor-isz-popup {
  min-height: 150px;
  min-width: 500px;
  padding: 20px;
  font-size: 16px;

  .ui.search {
    width: 100%;
  }

  .ui.icon.input {
    width: 100%;

    input {
      width: 100%;
      border-radius: 4px;
    }
  }

  .results.transition.visible {
    width: 100% !important;
    max-height: 400px !important;
    overflow: auto;
  }

  &__button.ui.button {
    background-color: $buttonColor;
    color: $blackColor;
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
  }

  &__search-wrapper {
    display: flex;
  }
}