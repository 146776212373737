
.documentStatus {
  &__label {
    text-align: left;
  }

  &__currentStatus {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    &__value {
      text-align: left;
      font-weight: bold;
    }
  }

  &__additionalInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 15px;

    &__value {
      text-align: left;
    }
  }
}
