@import "../../../styles/colors.scss";

.drag-list {
    &__start {
        background: $formColor !important;
    }
    &__result {
        width: 100% !important;
        margin-left: 5px;
        display: flex;
        flex-wrap: wrap;
        background: $formColor !important;
    }

    &__error {
        color: $errorColor;
    }
    &__empty {
        width: 100% !important;
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $formColor;
    }
    &__item {
        width: 100%;
        max-width: 200px;
        height: 100%;
        margin-left: 5px !important;
        max-height: 70px;
        padding: 11px !important;
        word-wrap: break-word;
    }
    &__placeholder {
        font-size: 50px;
        color: $placeholderColor;
    }

    &__item-input {
        border: none;
        outline: none;
        width: 100%;
        overflow: hidden;
    }
    &__item-input-tag {
        text-align: left;
    }
}
