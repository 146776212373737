@import './../../../styles/colors.scss';

.new-link-notice-to-test-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background-color: $whiteColor;

  &__message {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px;
  }

  &__title {
    display: flex;
    justify-content: flex-start;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 30px;
    padding-bottom: 20px;
  }
}