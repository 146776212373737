@import '../../styles/colors';

.docs-transfer-create {
  width: 90%;
  background-color: $whiteColor;
  padding: 55px 20px 20px;

  &__partners,
  &__recipients {
    background-color: $formColor;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    align-items: center;
    font-weight: 700;
    margin-bottom: 18px;
  }

  &__partners-text, &__recipients-text {
    text-align: left;
  }

  &__partners-select, &__recipients-select {
    min-width: 440px;
  }

  &__message {
    background-color: $formColor;
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: 18px;
  }

  &__message-text {
    display: block;
    font-weight: 700;
    text-align: left;
    margin-bottom: 15px;

    input {
      height: 400px;
    }
  }

  &__message-field {

    textarea {
      resize: none !important;
    }
  }

  &__files {
    background-color: $formColor;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: 700;
    margin-bottom: 18px;
  }

  &__files-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 18px;
  }

  &__files-text {
    margin-right: 15px;
  }

  &__files-input {
    display: none;
  }

  .plus-button {
    position: relative;
    top: 5px;
    background-color: $plusColor;
    color: $whiteColor;
    font-size: 20px;
    border-radius: 25px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__files-counter {
    margin-left: 50px;
  }

  &__files-list {
    background-color: $whiteColor;
    display: flex;
    flex-wrap: wrap;
  }

  &__files-item {
    width: 50%;
    border: 1px solid $borderColor;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__filename {
    justify-self: start;
    margin: 0 0 0 17px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
  }

  .ui.button.fileModal__viewButton {
    position: absolute;
    left: 0;
  }

  .close.icon {
    justify-self: flex-end;
    margin-right: 0;
    margin-left: auto;
  }

  &__wrapper {
    width: 75%;
  }

  .main-btn.ui.button {
    display: block;
    margin-right: 0;
    margin-left: auto;
    align-self: end;
    border-radius: 4px;
  }
}


