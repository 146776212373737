@import '../../../../styles/colors';

$header-table: 38px;

.constructor-isz-prepared-form {
  padding: 10px;
  max-width: 1980px;
  min-width: 1024px;
  width: 100%;

  &__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 650px;
    background: $whiteColor;
    border-radius: 4px;
    padding-top: 0 !important;
    position: relative;
    height: calc(100vh - 119px);
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
  }

  &__table {
    height: 100%;
  }

  &__content-table {
    height: calc(100% - #{$header-table});
  }

  .visible.transition {
    z-index: 1000 !important;
  }

  .ui.segment {
    padding: 0;
    width: 100%;
    height: calc(100% - 170px);
    margin: 0;
    border: 0;
    box-shadow: none;
  }

  &__header-table, &__header-table-scroll {
    background: $placeholderColor;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    height: $header-table;
    line-height: 28px;
    color: $blackColor;
    padding: 5px 0;
  }

  .main-btn.ui.button {
    width: auto;
    border-radius: 4px !important;
    min-height: 55px;
    max-height: 55px;
    margin: 0 10px;
    height: auto;
    font-size: 18px;
  }

  &__min-pay-block {
    max-width: 310px;
    margin: 0 10px;
    min-height: 55px;

    .know-min-insurance-amount-button__button-text {
      line-height: 18px;
      font-size: 18px;
    }

    .know-min-insurance-amount-button__min-insurance-block {
      margin: 0px 20% 0px -26% !important;
      position: absolute;
      width: 50%;
      bottom: 0%;
      left: 87%;
    }
  }

  .create-entity-btn__icon-add {
    display: none;
  }

  &__footer {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &__excel-btn {
    border-radius: 4px;
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: $transparentIe;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.42);
    border-width: 1px 2px 2px 1px;

    &:active {
      box-shadow: none;
      transform: translateX(1px) translateY(2px) translateZ(2px);
      border-width: 2px 1px 1px 2px;
    }

    &:disabled {
      img {
        opacity: 0.5;
      }
    }
  }

  &__scroll-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    height: 16px;
    position: absolute;
    right: 10px;
    top: 10px;

    .loader.inline.active {
      margin-right: 5px;
    }
  }

  &__table-title {
    background: $placeholderColor;
    position: relative;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: $blackColor;
    padding: 5px 0;
  }

  &__footer-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .create-entity-btn {
    width: 200px;
    height: unset;
  }
}
