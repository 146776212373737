@import '../../../styles/colors';

@mixin mid-size() {
  @media only screen and (max-width: 1100px) {
    @content;
  }
}

.check-document-editor {
  min-height: 800px;
  max-width: 1450px;
  min-width: 670px;
  width: 100%;
  padding: 15px;
  position: relative;

  &__content-header {
    display: flex;
    justify-content: space-between;

    @include mid-size() {
      flex-direction: column;
    }
  }

  &__header-row {
    display: flex;
    justify-content: space-between;
    width: 50%;
    background-color: $formColor;
    margin-bottom: 2px;
    padding-right: 10px;

    @include mid-size() {
      width: 100%;
      justify-content: flex-start;
    }
  }

  &__content-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 160px;

    p {
      margin: 0;
      padding: 0;
    }
  }

  &__back-btn {
    width: 100%;
  }

  &__controls-btn {
    display: flex;
    position: relative;
    width: 100%;
    height: 100px;
  }

  &__content {
    width: 100%;
    height: 100%;
    background-color: $whiteColor;
  }

  &__prev-btn {
    border-radius: 4px !important;
    position: absolute;
    top: 25px;
    left: 15px;
  }

  &__next-btn {
    border-radius: 4px !important;
    position: absolute !important;
    top: 25px;
    right: 15px;
  }

  &__close-btn {
    border-radius: 4px !important;
    position: absolute;
    top: 25px;
    left: calc(50% - 100px);
  }

  &__content-header-title, &__status-check {
    height: 55px;
    padding: 0 7px;
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.15rem;
    white-space: nowrap;

    &_active {
      background-color: $borderColor;
      width: 100%;
      max-width: 200px;

      @include mid-size() {
        width: 50%;
      }
    }
  }

  &__count-contract-title {
    display: flex;
    height: 55px;
    align-items: center;
    font-size: 16px;

    p {
      margin: 0;
      padding: 0;
      margin-right: 6px;
    }
  }

  &__bold-title {
    font-weight: bold;
  }


  &__btn {
    width: 100%;
    max-width: 200px;

    @include mid-size() {
      width: 50%;
    }
  }

  &__controls {
    display: flex;

    @include mid-size() {
      width: 60%;
    }
  }

  &__status {
    display: flex;
    padding-right: 7px;

    .check-document-editor__content-header-title {
      padding-right: 2px;
    }

    @include mid-size() {
      width: 33%;
      justify-content: center;
    }
  }

  &__status-check {
    padding: 0;

    &_red {
      color: $red;
    }

    &_blue {
      color: $blue;
    }

    &_black {
      color: $blackColor;
    }

    &_green {
      color: $green;
    }
  }

  &__title-wrapper {
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%;
    align-items: center;

    .check-document-editor__content-header-title {
      padding: 0;
      height: unset;
    }
  }
}

.check-data-block {
  width: 100%;
  display: flex;
  margin-bottom: 2px;

  @include mid-size() {
    flex-direction: column;
  }

  &__contract, &__client, &__passport {
    background-color: $formColor;
    margin-bottom: 2px;
    padding: 0 10px 7px 10px;
  }


  &__contract-data-wrapper {
    width: 65%;
    margin-right: 2.5px;

    @include mid-size() {
      width: 100%;
    }
  }

  &__client {
    margin-bottom: 0;
    height: 100%;
  }

  &__passport {
    background-color: $formColor;
    margin-bottom: 0;
    width: 35%;

    @include mid-size() {
      width: 100%;
    }
  }

  &__block-title {
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    padding-left: 10px;

    text-align: left;
    font-weight: bold;
  }

  &__field {
    display: flex;
    align-items: center;
    margin-right: 10px;


    &_column-field {
      width: 100% !important;
    }

    &_big {
      width: 58%;
    }
  }

  &__insued-by {
    width: 100%;
  }



  &__field-value {
    background-color: $whiteColor;
    min-height: 41px;
    margin: 0;
    padding: 0 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    &_width {
      min-width: 100px;
    }
  }

  &__field-title {
    margin: 0;
    padding: 0;
    margin-right: 10px;
    text-align: left;
    width: min-content;

    &_fixed-width {
      min-width: 38px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &_column {
      display: flex;
      flex-direction: column;
      justify-content: unset;
      align-items: flex-start;
      margin-right: 10px;
      width: 275px;

      .check-data-block__field {
        width: 100%;
        margin: 0 10px 10px 0;
      }
    }
  }

  &__block-info {
    margin-right: 10px;

    p {
      margin: 0;
      padding: 0;
      text-align: left;
    }
  }

  &__icon-info {
    padding: 3px;
    border: 1px solid $silverChalice;
    height: 45px;
    outline: none;

    .info.icon {
      margin: 0;
      font-size: 2.1rem;
      margin-top: 9px;
      color: $green-sushi;
    }
  }

  &__block-content {
    display: flex;
  }

  &__client-fields-row {
    margin-bottom: 10px;
    display: flex;
  }

  &__client-fields-row-registration {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }

  &__client-fields-row-registration-wrapper {
    width: 100%;
  }

  &__client-block {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.check-editor-block {
  display: flex;
  margin-bottom: 40px;

  .ui.active.visible.dimmer {
    z-index: 0;
  }

  @include mid-size() {
    flex-direction: column-reverse;
  }

  &__scan-window {
    width: 65%;
    border: 1px solid $silverChalice;
    margin-right: 2px;
    position: relative;

    .pdf-loading {
      display: none;
    }

    .pg-viewer-wrapper {
      height: 800px;
    }

    @include mid-size() {
      width: 100%;
    }

    .pdfobject-container {
      height: 100%;
    }
  }

  .ui.segment.dimmable {
    padding: 0;
    margin: 0;
    height: 800px;
  }

  &__control-wrapper {
    width: 35%;

    @include mid-size() {
      width: 100%;
    }
  }
}
