@import '../../../styles/colors';

.footerPanel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px;
  background-color: $whiteColor;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }

  &__tech-support {
    color: $blackColor;
    font-size: 14px;
    text-decoration: underline;
    margin-right: 10px;
    font-weight: 600;

    &:hover {
      color: $blackColor;
      text-decoration: underline;
    }
  }

  &__infoBlock {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 480px) {
      flex-direction: column;
    }
  }

  &__right {
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 480px) {
      justify-content: center;
      margin: 15px 0;
    }

    &__button.ui.button {
      border: none;
      background: none;
      padding: 0;
      color: $blackColor;
      font-weight: normal;
      text-decoration: underline;
      margin-right: 10px;

      &:hover {
        background: none;
      }
    }
  }
}

.subLogo {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 24px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
 }

.headInfoWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

