// import color variabels
@import 'styles/colors';

@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/RobotoCondensed-Regular.ttf');
}

body > #root > div {
  min-height: 100vh;
  min-width: 360px;
  position: relative;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: $whiteColor;
  color: $blackColor;
  font-size: 14px;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.25px solid $headerBorderColor;
}

.App-link {
  color: $linkColor;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.top-right {
  width: 550px !important;
  font-size: 32px;
  line-height: 2.1rem;
}
