@import '../../../styles/colors';

.status-buttons {
  display: flex;
  align-items: center;

  label {
    margin: 0;
  }

  input {
    display: none;
  }


  &__checkbox {

    &.check.bordered.icon {
      font-size: 1.21rem;
      width: 1.45em;
      height: 1.45em;
      padding: .25em 0 !important;
      color: $gray-chalice;
      border: 1px solid $gray-chalice;
      border-radius: 3px;
      box-shadow: none;
      margin: 0;
    }

    &_active.check.bordered.icon {
      color: white;
      background-color: green;
    }
  }

  .close.icon {
    color: $red;
  }

  .close.disabled.icon {
    opacity: 0.2 !important;
  }


}