.branches-products-table {
  width: 700px;
  max-width: 100%;
  padding-top: 30px;

  .flex-table {
    max-height: 300px;
    overflow: auto;
  }

  .custom-checkbox__checkbox {
    cursor: auto;
  }
}