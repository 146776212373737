@import '../../../styles/colors';
@mixin only-ie-11() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

.underwriting-product-col {
  height: 100%;

  &__header, &__text {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $borderColor;
    border-top: none;
    border-right: none;
    outline: none;
  }

  &__header {
    background-color: $tableHeaderColor;
    height: 45px;
    font-weight: bold;
  }

  &__text {
    word-break: break-word;
    border-top: none;
    background-color: $tableRowColor;
    min-height: 45px;
    height: 100%;
    outline: none;

    @include only-ie-11 {
      word-break: break-all;
    }
  }
}

.underwriting-product:first-child {
  .underwriting-product-col__text {
    height: calc(100% - 45px);
    outline: none;
  }
}

.underwriting-product-row {
  display: flex;

  &__arrow {
    width: 50px;
    outline: none;
  }

  .row {
    margin: 0;
    width: 100%;
  }

  &__icon-arrow {
    margin: 0;
    padding: 0 0 5px 5px;
    font-size: 2.7rem;
    line-height: 2rem;
    cursor: pointer;

    &_down {
      transform: rotate(90deg);
    }
  }
}