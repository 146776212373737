@import './../../../../../../styles/colors';

.basket-creating-form-strategy-control {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__button.ui.button {
    background-color: $buttonColor;
    color: $blackColor;
    font-size: 18px;
    display: flex;
    align-items: center;
  }

  &__calculate-min-amount-button {
    width: 100%;
  }

  &__calculate-min-amount {
    width: 310px;
    min-height: 55px;
  }

  &__min-amount {
    width: 100%;
    height: 100%;
    background-color: $formColor;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-weight: bold;
  }

  &__text {
    margin: 0;
    padding: 0;
    font-size: 18px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    background-color: $whiteColor;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
  }

  &__add-strategy-icon.icon {
    font-size: 22px !important;
    color: $green;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__calculator-icon.icon {
    font-size: 18px !important;
  }
}