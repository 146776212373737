@import './../../../styles/colors.scss';

.flex-table-scans-status {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    width: 100%;

    @media only screen and (max-width: 1024px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__content {
        width: calc(100% - 30px);
    }

    &__button {
        border: 0;
        background-color: transparent;
        width: 30px;
        padding: 0;

        &:focus {
            outline: none;
        }
    }

    .file.alternate {
        color: $blue-dodger;
        font-size: 2rem;
    }

}