@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/segoe_ui.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/open_sans.ttf');
}

@font-face {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/fa-solid-900.ttf');
}

body {
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: url('assets/images/main_logo_big.jpg') no-repeat center center fixed !important;
  background-size: cover !important;
}

* {
  font-family: 'Segoe UI', 'Open Sans', sans-serif !important;
}

i {
  font-family: 'fontAwesome', sans-serif !important;
}

.ui.modal {
  position: absolute;
  top: auto;
  left: auto;
  height: auto;
  overflow: visible;
  .content {
    justify-content: center;
  }
}
