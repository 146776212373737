@import "../../../styles/colors.scss";

.multi-select {
  display: flex;
  background: $formColor;
  min-height: 45px;
  width: 100%;
  padding: 3px 10px;
  align-items: center;
  margin-bottom: 5px;
  position: relative;

  &_with-search {
    padding-top: 15px;
  }

  &__icon-write-select-multi {
    position: absolute;
    top: 10px;
    right: 25px;
    color: $borderColor;
  }

  &__test {
    display: flex;
    background: $formColor;
    min-height: 45px;
    width: 100%;
    align-items: center;
    position: relative;
  }

  &__select {
    width: 100%;
    margin-right: 0;
    min-height: 36px;
    display: flex;
    background: $whiteColor;
    border-radius: .28571429rem;

    &_disabled {
      background-color: $gray-seashell;
    }

    &_error {
      background: $Chablis;
    }
  }

  &__select-wrap {
    width: 70%;
  }

  &__click-area {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    outline: none;
    position: relative;
  }

  &__title {
    margin: 0;
    width: 30%;
    text-align: left;
  }

  .ui.selection.dropdown {
    width: 100%;
  }

  &__roles-list {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    left: 31%;
    z-index: 1;
  }

  &__role {
    border: 1px solid $role-list-color;
    box-sizing: border-box;
    border-radius: 4px;
    background: $role-list-back;
    min-height: 36px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    word-break: break-all;
  }

  .ui.active.selection.dropdown {
    z-index: 0;
  }

  &__items-list, &__items-list-to-bottom {
    position: absolute;
    bottom: 42px;
    right: 0;
    text-align: start;
    width: 69.9%;
    margin-left: 1px;
    max-width: 910px;
    overflow: auto;
    border-color: $morningGlory;
    box-shadow: 0 2px 3px 0 $role-list-box-shadow;
    padding: 0 0;
    background: $whiteColor;
    max-height: 200px;
    outline: none;
    z-index: 1;
  }

  &__items-list-to-bottom{
    bottom: auto;
    top: 36px;
  }

  &__roles-container {
    max-width: 905px;
    background: $role-list-back;
    border: 1px solid $role-list-box-shadow;
    border-radius: .28571429rem;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }

  &__item-in-list {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    line-height: 1em;
    font-size: 1rem;
    text-transform: none;
    font-weight: 400;
    box-shadow: none;
    border-top: 1px solid $role-list-border-top;
    padding: .78571429rem 1.14285714rem!important;
    white-space: normal;
    word-wrap: normal;
    background: $whiteColor;
    color: $role-list-color-role;
    outline: none;
  }

  &__item-in-list[name=Approve-1] {
    color: $red;
  }

  &__item-in-list[name=Approve-2] {
    color: $orange;
  }

  &__item-in-list[name=Approve-3] {
    color: $yellow;
  }

  &__item-in-list[name=Approve-4] {
    color: $green;
  }

  &__error {
    color: $errorColor;
    text-align: left;
  }

  &__item-in-list:hover {
    background: $documentFormColor;
  }

  &__roles-container-wrap {
    display: flex;
    flex-wrap: wrap;
    min-height: 36px;
  }

  &__input-search {
    position: absolute;
    top: 2px;
  }

  &__click-area-dropdown-icon {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__placeholder {
    color: $silver;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
  }
}
