@import '../../../styles/colors.scss';

.limit-amount-popup {
    width: 360px;
    height: 200px;
    background-color: $whiteColor;
    padding: 15px 5px 5px;

    &__title {
        font-size: 18px;
        height: 25px;
        margin: 0;
        font-weight: bold;
    }

    &__message {
        font-size: 16px;
        height: calc(100% - 40px);
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    &__email {
        color: $blue;
        text-decoration: underline;
        margin: 0;
    }
}