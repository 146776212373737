@import '../../../styles/colors.scss';

.scan-interface {
    background-color: $formColor;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__page-count, &__zoom-count {
        height: 25px;
        width: 50px;
        border: none;
        border-radius: 3px;
        text-align: center;

        &:focus {
            outline: none;
        }
    }

    &__zoom-count {
        margin: 0 5px;
    }

    &__pdf-wrapper {
        overflow: auto;
    }

    &__canvas-wrapper {
        display: table;
        margin-left: 15%;

        &_img  {
            margin-left: 0;
        }

        canvas {
            height: 200px;
        }

        &_rotate0 {
            transform: rotate(0deg);
        }

        &_rotate90 {
            transform: rotate(90deg);
        }

        &_rotate180 {
            transform: rotate(180deg);
        }

        &_rotate270 {
            transform: rotate(270deg);
        }

        &_rotate-90 {
            transform: rotate(-90deg);
        }

        &_rotate-180 {
            transform: rotate(-180deg);
        }

        &_rotate-270 {
            transform: rotate(-270deg);
        }
    }

    &__page-interface {
        display: flex;
        align-items: center;
        margin-right: 20px;
        height: 25px;
        width: 100px;
    }

    .left.icon,
    .right.icon,
    .zoom-in.icon,
    .zoom-out.icon,
    .download.icon,
    .redo.icon,
    .undo.icon
    {
        color: $buttonColor;
        font-size: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 25px;
        margin: 0;

        &:hover {
            font-size: 2.5rem;
        }
    }

    .zoom-in.icon,
    .zoom-out.icon,
    .redo.icon,
    .undo.icon {
        margin-top: 5px;
        font-size: 1.5rem;

        &:hover {
            font-size: 1.7rem;
        }
    }

    .download.icon {
        font-size: 1.5rem;

        &:hover {
            font-size: 1.7rem;
        }
    }

    &__zoom-interface {
        display: flex;
        align-content: center;
        margin-right: 25px;
        height: 25px;
        width: 120px;
    }

    &__wrapper {
        overflow: auto;
        height: 800px;
        border: 1px solid $borderColor;

        &_img {
            padding: 40px 0;
        }
    }
}
