@import '../../../styles/colors';

.custom-multi-select {
  position: relative;

  &__dropdown {
    background-color: $whiteColor;
    width: 100%;
    border-radius: 4px;
  }

  .menu.transition {
    width: 100%;
  }

  .item {
    font-weight: bold !important;
  }

  &__error {
    text-align: left;
    color: $errorColor;
  }
}