@import '../../styles/colors';

.insurerData {
  &__documentBlock {
    &__actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 4px 15px;
    }

    &__notification {
      text-align: left;
      color: $gray;
      margin-right: 10px;
      word-wrap: break-word;

      &_active {
        color: $red-carnation;
      }

      &_hidden {
        visibility: hidden;
      }
    }

    &__button.ui.button{
      width: 150px;
      color: $blackColor;
      background-color: $gray;
    }

    &__button.ui.button:not(.disabled) {
      background-color: $red;
    }
    &__button_success.ui.button {
      background-color: $green;
    }
  }

  &__applyButton.ui.button {
    background-color: $buttonColor;
    color: $blackColor;
    margin: 0;
    font-size: 20px;

    @media only screen and (max-width: 480px) {
      width: 100%;
    }

    &:hover {
      background-color: $activeButtonColor;
    }
  }

  &__defaultButton.ui.button {
    background-color: $borderColor;
    align-self: end;
    margin: 10px;
    max-width: 160px;

    &:hover {
      background-color: $activeGreyButton;
    }
  }

  &__formContainer {
    .row:first-child > .col {
      padding: 0;
    }
  }

  &__searchBar {
    width: 100%;

    .results {
      right: 7px !important;
      width: auto !important;
    }

    &_small {
      .results {
        width: 400px !important;
        max-height: 280px;
        overflow: auto;
        @media only screen and (max-width: 720px) {
          max-width: 100%;
        }
      }
    }

    &_right {
      .results {
        right: 0 !important;
        left: auto !important;
      }
    }
  }

  &__searchBar.ui.search .ui, &__content {
    width: calc(100% - 7px);
  }

  &__addressCheckbox.ui.checkbox {
    margin: 10px;
    display: flex;
  }

  &__addressCheckboxWrap.field {
    margin-bottom: 5px !important;
  }
  &__addressCheckboxWrap {
    i {
      margin: 0 10px 0 0 !important;
    }

    p {
      margin-top: 2px;
    }
    display: flex;
  }

  &__formContainer {
    background-color: $whiteColor;

    .ui.form {
      display: flex;
      flex-direction: column;
    }

    &__inputs {
      padding: 10px;

      input::-webkit-contacts-auto-fill-button,
      input::-webkit-credentials-auto-fill-button {
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        right: 0;
      }

      @media only screen and (min-width: 1024px) {
        padding: 15px;
        padding-top: 5px;
      }

      @media only screen and (max-width: 720px) {
        padding-top: 25px;
      }
    }

    &__interface {
      display: flex;
      justify-content: space-between;
      align-self: end;
      padding-top: 30px;
    }

    &__total {
      background-color: $formColor;
      display: flex;
      flex-direction: row;
      padding: 10px;

      &__label {
        width: 50%;
        text-align: left;
      }

      &__value {
        font-size: 25px;
        width: 50%;
      }
    }
  }

  &__error {
    margin: 10px;
    font-weight: bold;
    text-align: left;
    color: $errorColor;
  }
}

.modal .actions {
  display: flex;
  justify-content: center;
}

.modalView.ui.button {
  font-size: inherit;
}
