@import '../../../styles/colors';

.agent-checking-error-popup {
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: $formColor;
  min-width: 500px;

  &__header-warning {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
  }

  &__header-text {
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    margin: 5px 0 25px 0;
  }

  &__agent {
    margin-bottom: 15px;
  }

  &__agent-title-text {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }

  &__agent-error {
    display: flex;
    width: 100%;
    padding-left: 15px;
    justify-content: center;
    margin-bottom: 10px;
  }

  &__agent-error-wrap {
    position: relative;
  }

  &__agent-error-icon.warning.sign.icon {
    color: $red;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 23px;
  }

  &__agent-error-text {
    padding-left: 25px;
    text-align: left;
    font-size: 16px;
  }
}
