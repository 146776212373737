@import '../../../styles/colors';

.switcher {
  display: flex;
  flex-direction: row;
  background-color: $whiteColor;
  margin-bottom: 4px;

  @media only screen and (max-width: 990px) {
    flex-direction: column;
  }

  &__section {
    background-color: $formColor;
    font-size: 20px;
    padding: 0 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    height: 55px;
    cursor: pointer;
    flex: 1;
    margin-right: 1px;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    @media only screen and (max-width: 768px) {
      font-size: 17px;
      height: 40px;
      min-height: 40px;
    }

    @media only screen and (max-width: 990px) {
      border-bottom: 1px solid $borderColor;
    }

    &.active {
      background-color: $activeFormColor;
      cursor: default;
    }

    &:last-child {
      margin: 0;
    }
  }

  &__sub-switch-block {
    display: flex;
    cursor: pointer;

    @media only screen and (max-width: 990px) {
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
    }
  }

  &__sub-switch {
    width: 55px;
    background-color: $formColor;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    border-left: 1px solid $gray;
    box-sizing: border-box;
    outline: 0;

    @media only screen and (max-width: 1160px) {
      width: 30px;
    }

    @media only screen and (max-width: 990px) {
      border-bottom: 1px solid $borderColor;
    }

    &:last-child {
     border-right: 0;
    }

    &_active {
      border: 2px solid black !important;
    }

  }

  &__message {
    font-size: 0.8rem;
    position: relative;
    bottom: 5px;
    white-space: nowrap;
  }

  &__switch-block {
    display: flex;
    margin-right: 2px;
    box-sizing: border-box;
    position: relative;

    .switcher__section {
      margin: 0;
    }

    @media only screen and (max-width: 990px) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__message-form {
    padding: 20px;
    font-size: 1.5rem;
  }
}
