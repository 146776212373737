
.auth {
  &__title {
    font-size: 25px;
  }

  &__form__interface {
    justify-content: center;
    flex-direction: column;
    display: flex;
  }

  &__controls {
    display: flex;
    width: 100%;
  }

  &__warningMessage {
    text-align: left;
  }
}
