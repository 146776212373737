@import '../../../styles/colors.scss';

.branches-admin-table {
    min-height: 705px;

    &__control-wrapper {
        outline: none;
        cursor: pointer;
    }

    &__not-found {
        padding: 50px;
    }

    &__controls {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;

        div {
            height: 100%;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:first-child {
                border-right: 1px solid $borderColor;
            }
        }
    }

    .add.circle {
        color: $green;;
        font-size: 35px;
        display: flex;
        align-items: center;
        margin: 0;
        justify-content: center;
    }

    &__edit-icon {
        width: 40px;
    }

    &__control-button {
        outline: none;
    }

    &__user-icon {
        width: 40px;
        height: 40px;
    }

}
