@import '../../../styles/colors';

.info-table {
  &__custom-row {
    padding: 0 2px 0 2px !important;

      .row-product__row-title {
        display: flex;

        align-items: center;
        justify-content: center;
        
        text-align: center;
        height: 50px;
        width: 100%;
        font-weight: 900;

        p {
          text-align: center;
        }
      }

      .row-product__row-with-children-content {
        width: 100%;
      }
  }

  &__header-block {
    background-color: $sideMenuButton;
    height: 25px;
    border: 1px solid $borderColor;
    margin: 2px;
    font-size: 16px;
    text-align: left;
    font-weight: 600;
    padding-left: 10px;

    &:last-child {
      margin-bottom: 2px;
    }
  }
}
