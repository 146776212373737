@import './../../../../../../styles/colors';

.basket-strategy-title-button {
  &.ui.button {
    background-color: $buttonColor;
    color: $blackColor;
    font-weight: bold;
    padding: 8px 12px;
    font-size: 14px;
    height: 33px;
  }

  &__text {
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 20px;
  }

  &__icon.icon {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    display: flex;
    align-items: center;
    height: 100%;
  }
}