@import '../../../styles/colors';

@mixin only-ie-11() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

.extend-setting-btn {
  outline: none;
  width: 55px;
  height: 55px;
  position: relative;

  @include only-ie-11 {
    border-radius: 0 !important;
  }

  &__img {
    width: 55px;
    height: 55px;
  }

  label {
    margin: 0;
    padding: 0;
  }
}

.checkbox-setting {
  display: none;

  + .checkbox-setting_custom {
    display: inline-block;
    position: absolute;
    min-width: 10px;
    max-width: 10px;
    min-height: 10px;
    max-height: 10px;
    top: 32px;
    right: 23px;

    @include only-ie-11 {
      right: 19px !important;
    }

    &:before {
      content: '';
      padding: 2px;
      width: 20px;
      height: 20px;

      position: absolute;
      top: 50%;
      bottom: 0;
      transform: translateY(-50%);

      background: #fff;

      border-radius: 3px;
      border: 2px solid $colorBtnSetting;
      border-top: 5px solid $colorBtnSetting;
    }
  }

  &:checked + .checkbox-setting_custom {
    &:after {
      content: "";
      padding: 3px;
      width: 1px;
      height: 11px;
      position: absolute;
      top: calc(50% - 1px);
      left: 11px;
      transform: translateY(-50%) rotate(45deg);
      border: solid $cornflower-blue;
      border-width: 0 2.3px 2.33px 0;

      @include only-ie-11 {
        left: 6px;
      }
    }
    &:before {
      background: $whiteColor;
    }
  }
}

.label_checkbox {
  display: flex;
  align-items: center;
}

