@import './../../../styles/colors.scss';

.reports-admin-table {
    &__roles {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
    }

    &__role {
        height: 100%;
        border-top: 1px solid $activeFormColor;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    &__role:first-child {
        border: none;
    }
    &__edit {
        outline: none;
        cursor: pointer;
    }
}