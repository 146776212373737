@import '../../styles/colors';

.client-test {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 5px;

  &__header {
    text-align: left;
    font-size: 18px;
    margin-bottom: 15px;
  }

  &__form {
    background: $whiteColor;
    padding: 10px;
    max-width: 1980px;
    min-width: 1024px;
    width: 100%;
  }

  &__field-block {
    display: flex;
    flex-direction: column;
    background-color: $formColor;
    border-radius: 5px;
    margin: 3px 0;
    padding: 10px;
    align-items: flex-start;
  }

  &__filed-block-label {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    margin-bottom: 5px;
  }

  &__answers-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
  }

  &__field {
    height: auto;
    min-height: 38px;
    margin: 3px 0;
    text-align: left;
  }

  &__submit-btn.ui.button {
    background-color: $buttonColor;
    color: $blackColor;
    margin-right: 10px;
    width: 50%;
    font-size: 20px;

    &:hover {
      background-color: $activeButtonColor;
    }

    &:focus {
      background-color: $buttonColor;
    }
  }

  @media (max-width: 1036px) {
    &__form {
      padding: 5px;
      min-width: 100%;
    }

    &__field-block {
      padding: 5px;
    }

    &__filed-block-label {
      font-size: 12px;
    }

    &__field {
      margin: 3px 0;
    }

    &__submit-btn.ui.button {
      font-size: 18px;
    }
  }

  @media (max-width: 768px) {
    &__header {
      font-size: 16px;
    }

    &__form {
      padding: 5px;
      min-width: 100%;
    }

    &__field-block {
      padding: 5px;
    }

    &__filed-block-label {
      font-size: 12px;
    }

    &__field {
      margin: 2px 0;
    }

    &__submit-btn.ui.button {
      font-size: 18px;
    }
  }
}
