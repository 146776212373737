@import './../../../styles/colors';

.progress-bar-download {
  position: fixed !important;
  min-height: 135px !important;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  background-color: #E5E5E5;
  width: 400px;
  height: 135px;
  top: calc(100vh - 170px);
  left: 15px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  &__title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  &__filename {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  &__filename-title {
    font-size: 14px;
    margin: 0;
    margin-right: 3px;
    padding: 0;
    white-space: nowrap;

  }

  &__filename-file {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    word-wrap: break-word;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-align: center;
  }

  &__progress-bar {
    display: flex;
    padding: 15px;
  }

  &__percent {
    margin-left: 5px;
    font-size: 14px;
    font-weight: bold;
  }

  progress[value] {
    appearance: none;
    border: none;
    width: 200px; height: 20px;
    background-color: whiteSmoke;
    border-radius: 3px;
    box-shadow: 0 2px 3px rgba(0,0,0,.5) inset;
    color: royalblue;
    position: relative;
  }

  progress[value]::-webkit-progress-bar {
    background-color: whiteSmoke;
    border-radius: 3px;
    box-shadow: 0 2px 3px rgba(0,0,0,.5) inset;
  }

  progress[value]::-webkit-progress-value {
    position: relative;
    background-size: 35px 20px, 100% 100%, 100% 100%;
    border-radius:3px;
    animation: animate-stripes 5s linear infinite;
  }

  @keyframes animate-stripes { 100% { background-position: -100px 0; } }

  progress[value]::-webkit-progress-value:after {
    content: '';
    position: absolute;
    width:5px; height:5px;
    top:7px; right:7px;
    background-color: white;
    border-radius: 100%;
  }

  progress[value]::-moz-progress-bar {
    background-image:
        -moz-linear-gradient( 135deg,
                transparent,
                transparent 33%,
                rgba(0,0,0,.1) 33%,
                rgba(0,0,0,.1) 66%,
                transparent 66%),
        -moz-linear-gradient( top,
                rgba(255, 255, 255, .25),
                rgba(0,0,0,.2)),
        -moz-linear-gradient( left, #09c, #f44);

    background-size: 35px 20px, 100% 100%, 100% 100%;
    border-radius:3px;
  }

  .progress-bar {
    background-color: whiteSmoke;
    border-radius: 3px;
    box-shadow: 0 2px 3px rgba(0,0,0,.5) inset;
    width: 100%; height:20px;
  }

  .progress-bar span {
    background-color: royalblue;
    border-radius: 3px;

    display: block;
    text-indent: -9999px;
  }

  p[data-value] {

    position: relative;
  }

  p[data-value]:after {
    content: attr(data-value) '%';
    position: absolute; right:0;
  }

  &__progress::-webkit-progress-value {
    background-image:
            -webkit-linear-gradient( 135deg,
                    transparent,
                    transparent 33%,
                    rgba(0,0,0,.1) 33%,
                    rgba(0,0,0,.1) 66%,
                    transparent 66%),
            -webkit-linear-gradient( top,
                    rgba(255, 255, 255, .25),
                    rgba(0,0,0,.2)),
            -webkit-linear-gradient( left, #09c, #f44);
  }

  &__progress::-moz-progress-bar {
    background-image:
      -moz-linear-gradient( 135deg,
              transparent,
              transparent 33%,
              rgba(0,0,0,.1) 33%,
              rgba(0,0,0,.1) 66%,
              transparent 66%),
      -moz-linear-gradient( top,
              rgba(255, 255, 255, .25),
              rgba(0,0,0,.2)),
      -moz-linear-gradient(left, #09c, #f44);
  }
}