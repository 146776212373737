@import "../../../styles/colors";

$user-card-width: 370px;

@mixin toColumnContest() {
  @media only screen and (max-width: 1375px) {
    @content;
  }
}

.contests-form {
  max-width: 1920px;
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;

  &__contest {
    width: calc(100% - #{$user-card-width});

    @include toColumnContest {
      width: 100%;
    }
  }

  &__contest-info {
    margin: 0 15px 15px 0;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);

    @include toColumnContest {
      margin-right: 0;
    }
  }

  &__user-info {
    width: $user-card-width;

    @include toColumnContest {
      width: 100%;
    }
  }

  &__contest-title, &__result-title {
    height: 70px;
    padding: 0;
    margin: 0;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  &__contest-title {
    background-color: $blue-malibu;
  }

  &__result-title {
    background-color: $orange-jaffa;
  }

  &__contest-result {
    min-height: 400px;
    display: flex;
    flex-wrap: wrap;
    background-color: $formColor;
    padding: 10px;
    padding-bottom: 0;
  }

  &__contest-description {
    padding: 15px;
    background-color: $formColor;
  }

  &__contest-image {
    width: 100%;
  }

  &__segment {
    height: 300px;
    background-color: unset !important;
    border: none !important;
    box-shadow: none !important;
  }

  &__dimmer {
    background-color: unset !important;
  }

  &__contest-wrapper {
    width: 100%;
    display: flex;
    margin-top: 100px;

    @include toColumnContest {
      flex-direction: column-reverse;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__contest-controls {
    display: flex;
    justify-content: flex-end;
    padding: 0 10px;
    background-color: $formColor;

    .ui.button {
      margin: 0 0 10px 0;
      background-color: $buttonColor;
      font-size: 16px;
      color: $blackColor;
      &:hover {
        background-color: $activeButtonColor;
      }
    }
  }
}