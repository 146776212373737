@import '../../../styles/colors.scss';

.cloud-popup__popup-not-hover {
    position: absolute;
    top: 0;
    display: none;
}
.cloud-popup__popup {
    display: block;
    position: absolute;
    bottom: 0px;
    background: $whiteColor;
    padding: 10px;
    right: 5px;
    border-radius: 5px;
    margin-bottom: 30px;
    z-index: 101;
}
.cloud-popup__trigger {
    position: relative;
}
.cloud-popup {
    position: relative;
}
.cloud-popup__connector {
    position: absolute;
    width: 100%;
    bottom: 10px;
    height: 25px;
    pointer-events: none;
}
