@import '../../../styles/colors';

.garants-table {
  border-collapse: collapse;
  width: 100%;

  tr {
    td,
    th {
      border: 1px solid $borderColor;
      height: 56px;
    }

    td:first-child,
    th:first-child {
      border-left: 0;
    }

    td:last-child,
    th:last-child {
      border-right: 0;
    }

    &:last-child {
      td,
      th {
        border-bottom: 0;
      }
    }

    .garants-table-body-row__payment-term {
        width: 50%;
    }

    .garants-table-body-row__garant-value {
        width: 50%;
    }
  }
}
