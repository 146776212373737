.guaranteed-payout {
  display: flex;
  align-items: center;
  margin-top: 7px;
  padding: 5px;
  border-radius: 5px;
  background-color: #E5E5E5;

  &__percent-input {
    width: 200px !important;
    display: flex;
  }

  &__number {
    display: flex;
    width: 10%;
    font-weight: bold;
  }

  &__error {
    font-size: 12px;
  }

  &__guaranteed-payments-amount {
    display: flex;
    width: 45%;
    align-items: center;
  }

  &__guaranteed-payments-amount-text {
    white-space: nowrap;
    margin-right: 5px;
  }

  &__payment-term {
    display: flex;
    width: 25%;
    align-items: center;
  }

  &__button {
    margin-left: auto;
  }
}
