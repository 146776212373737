@import '../../../styles/colors';

.upload-button {
  width: 36px;
  height: 38px;
  background-color: $buttonColor;
  border: 2px solid $borderColorButton;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  outline: none;

  &:disabled {
    opacity: 0.5;
  }

  &:active {
    outline: none;
  }

  &__icon {
    width: 0;
    height: 0;
    margin-left: 4px;
    border-top: 10px solid transparent;
    border-left: 15px solid $whiteColor;
    border-bottom: 10px solid transparent;
  }

  .icon.stop, img {
    color: $red;
    position: absolute;
    top: 23px;
    font-size: 1.5rem;
    left: 18px;
  }

}
