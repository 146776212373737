@import '../../styles/colors';

@mixin mid-size() {
  @media only screen and (max-width: 800px) {
    @content;
  }
}

.administration-force-data {
  max-width: 1280px;
  width: 100%;
  height: 100%;
  margin: 15px;
  display: flex;
  flex-direction: column;
  background-color: $whiteColor;
  padding: 2px 2px 0;
  height: 100%;

  &__scheduler {
    background: $formColor;
  }

  .ui.active.label {
    background-color: #e8e8e8;
    color: #5d5d5d;
  }

  &__input-upload-area {
    background: $whiteColor;
    display: flex;
    width: 100%;
    border: 1px solid $sharkColor;
    align-items: center;
  }

  &__input-file {
    border: none !important;
  }

  &__cancel-button {
    color: $red;
  }

  &__header-menu {
    width: 100%;
    height: 31px;
    margin-bottom: 1px;
    background-color: $gray-silver;
    display: flex;
    align-items: center;
    padding-left: 2px;
  }

  &__header-title {
    height: 26px;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FF7A00;
    font-size: 1.3rem;
    font-weight: bold;
    margin-right: 2px;

    @include mid-size() {
      font-size: 1rem;
    }
  }

  &__switch-button,  
  &__switch-button-import,
  &__switch-button-active,
  &__switch-button-import-active {
    width: 17%;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    font-weight: bold;
    cursor: pointer;
    outline: none;
  }
  &__switch-button-active, &__switch-button-import-active  {
    background-color: $activeFormColor;
  }

  &__switch-button-import {
    margin-left: 5px;
  }

  &__info {
    width: 20%;
    background-color: $formColor;
    margin-left: 2px;
    padding: 2px 2px 2px 4px;
    margin-right: 2px;
    margin-bottom: 5px;

    @include mid-size() {
      display: none;
    }

    p {
      text-align: left;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 1px;
    width: 100%;
  }

  .react-datepicker-wrapper, .react-datepicker__input-container {
    width: 98%;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }

    input {
      border: none;
      width: 100%;
      height: 55px;
      font-size: 1.2rem;
      outline: none;
      border-radius: 0 !important;
    }
  }

  .fieldWrap.date {
    svg {
      left: 80%;
      top: 15px;
    }
  }

  &__loader {
    display: flex;
    align-items: center;

    p {
      padding-left: 5px;
    }
  }

  &__uploading1C, &__import-1c {
    display: flex;

    .ui.segment {
      padding: 0;
      margin: 0;
    }
  }

  .ui.form {
    cursor: default;
    width: 80%;

    @include mid-size() {
      width: 100%;
    }
  }

  &__unloading-1c, &__import-from-1c {
    display: flex;
  }
  
  &__import-from-1c {

    .ui.input {
      width: 100% !important;
    }
  }
  
  &__form-content-radio {
    position: relative;
    width: 70%;
    display: flex;
    justify-content: space-around;
  }

  .ui.form .field {
    margin: 0 !important;
  }

  &__content-header {
    margin: 0;
    padding: 0;
    padding-left: 10px;
    margin-bottom: 2px;
    width: 100%;
    height: 35px;
    background-color: $silverChalice;
    display: flex;
    align-items: center;
    font-size: 1.14286rem;
  }

  &__input-text {
    border: none;
    outline: none;
    width: 100%;
    min-height: 50px;
    border-radius: 0 !important;
  }


  &__field {
    width: 40%;
  }

  &__error {
    margin: 0;
    padding: 0;

    width: 100%;
    font-size: 1rem;
    color: $errorColor;
    text-align: left;
  }

  &__label {
    margin: 0;
    padding: 0;

    @media only screen and (max-width: 800px) {
      width: 100%;
    }
  }

  &__select {
    width: 40%;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 0 !important;

    &.ui.selection.dropdown {
      min-height: 50px;
      display: flex;
    }

    .dropdown.icon {
      height: 100% !important;
      display: flex;
      align-items: center;
    }
  }

  &__date-block {
    display: flex;

    @include mid-size() {
      flex-direction: column;
    }
    @media only screen and (max-width: 800px) {
      width: 40%;
    }
  }

  &__label-date {
      position: absolute;
      z-index: 2;
      font-size: 1rem;
      width: 200px;
      color: $gray;
      left: 5px;
      text-align: left;
  }

  &__date {
    position: relative;

    .ui.input {
      height: 60px;
    }

    .ui[class*="left icon"].input {
      > i.icon {
        right: 5px;
        left: unset;
        font-size: 1.3rem;
      }

      > input {
        padding-left: 31px !important;
        font-size: 1.3rem;
      }
    }

    &:first-child {
      margin-right: 10px;

      @media only screen and (max-width: 800px) {
        margin-right: 0;
      }
    }
  }

  &__submit.ui.button {
    background-color: $buttonColor;
    color: $blackColor;
    border-radius: 0;
    margin-right: 10px;
    width: 150px;
    font-size: 20px;

    padding: 0;
    height: 55px;

    &:hover {
      background-color: $activeButtonColor;
    }
  }

  .field-block {
    margin-bottom: 2px;
  }

  .ui.compact.segment {
    border-radius: 0 !important;
  }

  .ui.checkbox .box:before, .ui.checkbox label:before {
    border: none;
  }

  .fieldWrap.date .react-datepicker-wrapper .react-datepicker__input-container input {
    height: 50px;
  }

  .formInput .fieldWrap {
    @media only screen and (max-width: 800px) {
      width: 100%;
    }
  }
}
