@import '../../../styles/colors';

.unfinished-check {
  width: 700px;
  min-height: 340px;
  padding: 20px 120px;
  background-color: $formColor;
  border: 1px solid $silverChalice;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__warning {
    font-size: 1.428rem;
    font-weight: 600;
  }

  &__contract-info {
    text-align: left;
    font-size: 1.1428rem;
    margin-bottom: 20px;
  }

  &__contract-info-row {
    display: flex;

    p {
      margin: 0;
      padding: 0;
    }

    p:first-child {
      width: 130px;
    }

    p:last-child {
      max-width: 300px;
    }
  }

  &__status-check {
    font-size: 1.2857rem;
    font-weight: 600;
  }

  &__question {
    font-size: 1.1428rem;
    width: 350px;
  }

  &__controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__btn-cancel, &__btn-confirm {
    width: 120px;
    height: 45px;
    font-weight: 700;
    border: none;

    &:focus {
      outline: none;
    }

    &:active {
      width: 125px;
      height: 50px;
      font-size: 1.1rem;
    }
  }

  &__btn-cancel {
    background-color: $red-carnation;
  }

  &__btn-confirm {
    background-color: $buttonColor;
  }

  &__btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 55px;
  }

}