@import './../../../styles/colors.scss';

.add-users-form {
    display: flex;
    flex-direction: column;
    min-height: 665px;
    border-bottom: 1px black solid;

    &__filter{
        height: 40px;
        display: flex;
        align-items: center;
        margin-left: 10px;
    }

    &__controls {
        display: flex;
        width: 100%;
        flex-direction: column;
        background-color: $formColor;
        min-height: 40px;

        .field.formInput {
            margin: 0 !important;
        }
        
        .fieldWrap {
            width: 100%;
        }

        .row {
            margin: 0;
            width: 100%;
            padding: 0;
            height: 40px;
        }

        .ui.input {
            width: 100%;
            height: 40px;
            border-radius: 0;
        }
        .ui.input>input {
            border-radius: 0;
        }

        .ui.selection.dropdown {
            height: 40px;
            border-radius: 0;
        }

        .extend-setting-btn__img {
            height: 40px;
            width: 40px;
        }

        .checkbox-setting + .checkbox-setting_custom:before {
            height: 15px;
            width: 15px;
            top: -5px;
            left: 3px;
            @media screen and (max-width: 1130px) {
                left: 50%;
            }
            @media screen and (max-width: 768px) {
                left: 10%;
            }
        }
        .checkbox-setting:checked + .checkbox-setting_custom:after {
            height: 11px;
            top: -6px;
            left: 7px;
        }
    }

    &__create-user-and-settings {
        display: flex;
        height: 40px;
    }

    .ui.segment.dimmable {
        margin: 0;
        padding: 0;
    }
}
.nopaddings {
    padding: 0;
}
.disp {
    display: block;
}
.non-disp {
    display: none;
}