@import './../../../styles/colors.scss';

.breadcrumbs-administrative-section {
    display: flex;
    height: 40px;
    width: 80%;
    background-color: $formColor;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        width: 100%;
        height: unset;
    }

    &__item {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
        outline: none;
        word-break: break-all;

        @media only screen and (max-width: 767px) {
            width: 100%;
        }

        &_active {
            background-color: $activeFormColor;
        }

        p {
            margin: 0;
            padding: 0;
        }

        .caret.icon {
           color: $buttonColor;
        }
    }
}

.administrative-section {
    display: flex;
    flex-direction: column;
    max-width: 1313px;
    width: 100%;
    background-color: $whiteColor;
    margin: 15px;
    height: 100%;

    &__content {
        min-height: 700px;
        position: relative;
    }

    &__header {
        display: flex;
        width: 100%;

        @media only screen and (max-width: 767px) {
            flex-direction: column;
        }
    }

    &__button.ui.button.main-btn {
        height: 40px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;

        @media only screen and (max-width: 767px) {
            width: 100%;
        }
    }

}