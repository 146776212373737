@import './../../../styles/colors.scss';

.catalogs-admin-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 700px;

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__footer {
        padding: 10px;
        background: $formColor;
        display: flex;
        justify-content: space-between;
    }

    &__control-button {
        border-radius: 4px !important;
        height: 40px !important;
    }

    .create-entity-btn {
        height: 38px !important;
    }

    &__reference-name {
        padding: 0 10px;
        background: $formColor;
        align-items: center;
        display: flex;
        justify-content: center;
        width: 50%;
    }
}