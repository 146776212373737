.dp-policy-constructor-execution-popup {
  max-width: 450px;
  width: 100%;
  padding: 15px;

  &__label {
    font-size: 16px;
    font-weight: bold;
  }

  &__form {
    margin-bottom: 20px;
  }
}
