@import '../../../../styles/colors.scss';

.constructor-isz-form {
  max-width: 1980px;
  min-width: 1024px;
  width: 100%;
  padding: 10px;
  height: calc(100vh - 100px);

  &__content {
    display: flex;
    flex-direction: column;
    background: $whiteColor;
    justify-content: space-around;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    border-radius: 4px;
    height: 100%;
    min-height: 700px;
  }

  .visible.transition {
    z-index: 1000 !important;
  }

  &__view {
    position: static !important;
    margin-bottom: 0px !important;
  }

  &__scroll {
    position: static !important;
    background-color: $whiteColor;
  }

  &__thumb-horizontal {
    display: none;
  }

  &__table-title {
    background: $placeholderColor;
    position: relative;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: $blackColor;
    padding: 5px 0;
  }

  &__footer {
    position: relative;
    bottom: 0;
    padding: 0 20px 10px 20px;
    display: flex;
    justify-content: space-between;
  }

  &__tables {
    height: calc(100% - 110px);

    .ui.segment {
      height: 100%;
      width: 100%;
      padding: 0;
      border: 0;
      margin: 0;
      box-shadow: none;
    }
  }

  &__footer-right-button {
    display: flex;
    flex-direction: column;
  }

  .create-entity-btn__icon-add {
    display: none;
  }

  .main-btn.ui.button {
    border-radius: 4px !important;
    height: auto;
    font-size: 18px;
  }

  .create-entity-btn {
    width: auto;
  }

  &__table {
    height: calc(50% - 5px);
  }

  &__table-wrapper {
    height: calc(100% - 38px);
  }

  &__get-basket-button {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin: 0;
      line-height: 22px;
    }
  }

  &__scroll-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    height: 16px;
    position: absolute;
    right: 10px;
    top: 10px;

    .loader.inline.active {
      margin-right: 5px;
    }
  }

  &__min-pay-block {
    max-width: 310px;
    min-height: 55px;

    .know-min-insurance-amount-button__button-text {
      line-height: 18px;
      font-size: 18px;
    }

    .know-min-insurance-amount-button__min-insurance-block {
      margin: 0px 20% 0px -34% !important;
      position: absolute;
      width: 50%;
      bottom: 0%;
      left: 87%;
    }
  }

  &__dimmer {
    z-index: unset !important;
  }
}
