@import '../../../styles/colors';

.address-block-common {
  width: 100%;

  &__switch-button {
    background-color: $buttonColor;
    height: 36px;
    border-radius: 4px;
    font-weight: bold;
    width: 170px;
    border: 0;
  }

  &__btn-interface {
    display: flex;
    justify-content: flex-end;
    padding: 9px 7px;
  }
}