.admin-products-table {

    svg {
        position: absolute;
        top: 10px;
        right: 15px;
        pointer-events: none;
      }

      &__calendar {
          position: relative;
          width: 100%
      }

      .react-datepicker-wrapper {
          width: 100%;
          display: flex;

          .ui.input {
              height: 100%;
          }
      }
}
