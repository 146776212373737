@import '../../../styles/colors.scss';

.nsz-form {
  background-color: $whiteColor;

  .react-datepicker-wrapper {
    width: 60% !important;
  }

  .main-btn {
    width: 100%;
    max-width: 150px;
    border-radius: 5px !important;
  }

  &__content {
    display: flex;

    @media only screen and (max-width: 720px) {
      flex-direction: column;
    }
  }

  &__footer-next-button {
    display: flex;
    justify-content: flex-end;
    padding: 0 10px 10px 0;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 6px;
    padding-top: 6px;
  }

  &__print-payment {
    padding-top: 7px !important;
  }

  svg {
    display: none;
  }

  @media only screen and (max-width: 720px) {
    flex-direction: column;
  }

  &__form {
    background-color: $whiteColor;

    .ui.form {
      display: flex;
      flex-direction: column;
    }

    &__inputs, &__calculated-wrapper {
      padding: 10px;
      width: 60%;

      @media only screen and (min-width: 1024px) {
        padding: 30px 15px 0px 30px;
      }

      @media only screen and (max-width: 1024px) {
        padding-top: 25px;
        width: 100%;
      }

      .field.formInput {
        background-color: $formColor;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 10px 10px 25px;

        @media only screen and (min-width: 480px) {

          .error {
            align-self: flex-end;
          }
        }

        .fieldWrap {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          @media only screen and (max-width: 480px) {
            flex-direction: column;
          }
        }

        .fieldNumber {
          background-color: $tooltipArrowColor;
          position: absolute;
          left: 0;
          top: 0;
          padding: 1px 5px;
        }

        .label {
          display: flex;
          align-items: center;
          text-align: left;
          width: 40%;
        }

        @media only screen and (min-width: 480px) {

          .selection.dropdown, input {
            width: 60% !important;
          }
        }
      }

      .react-datepicker__input-container {
        .formInput {
          padding: 0px !important;

          .fieldWrap {
            .field {
              .ui input {
                padding-left: 10px;
              }
            }
          }
        }
      }
    }

    &__interface {
      display: flex;
      justify-content: flex-end;
      padding: 15px;
    }

    &__nextButton.ui.button {
      background-color: $buttonColor;
      color: $blackColor;
      margin: 0;
      font-size: 20px;

      @media only screen and (max-width: 480px) {
        width: 100%;
      }

      &:hover {
        background-color: $activeButtonColor;
      }
    }

    &__total {
      background-color: $formColor;
      display: flex;
      flex-direction: row;
      padding: 10px 10px 10px 25px;
      margin: 0 0 1em;
      overflow: hidden;

      &__label {
        width: 50%;
        text-align: left;
      }

      &__value {
        font-size: 22px;
        width: 50%;
      }
    }
  }

  &__next-button {
    padding: 0 30px !important;
  }
}
