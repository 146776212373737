@import '../../../styles/colors';

.scans-block {
  &__scan {
    width: 70%;
    display: flex;
    align-items: center;
    background-color: $documentFormColor;
    padding-left: 10px;
    margin-bottom: 4px;
    margin-left: 2px;
    height: 55px;
    border: 1px solid $borderColor;
    position: relative;
  }

  &__file-type {
    margin: 0;
    padding: 0;
    text-align: left;
    width: 30%;
    color: $gray;
  }

  &__save-icon,
  &__status-icon {
    width: 50px;
  }

  &__save-icon {
    position: relative;
    border: none;
    background-color: transparent;
    cursor: pointer;

    img {
      width: 40px;
    }

    &:focus {
      outline: none;
    }
  }

  &__button {
    background-color: transparent;
    border: none;

    &:focus {
      outline: none;
    }
  }

  &__status {
    margin: 0;
    padding: 0;
    text-align: left;
  }

  &__update-scan-icon {
    position: absolute;
    right: 5px;
    border: none;
    background-color: transparent;
    top: calc(50% - 20px);
    cursor: pointer;

    &:focus {
      outline: none;
    }

    img {
      width: 40px
    }
  }

}