@import '../../../styles/colors';

.migrations-popup {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  background-color: transparent;

  &__window {
    width: 100%;
    max-width: 700px;
    min-height: 400px;
    padding: 30px;
    background-color: $formColor;
    border: 1px solid $silverChalice;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
  }

  &__content {
    background-color: $whiteColor;
    width: 100%;
    min-height: 340px;
    height: 500px;
    overflow: auto;
  }

  .close.icon {
    font-size: 1.7rem;
  }

  &__close-btn {
    border: none;
    background-color: transparent;
    position: absolute;
    right: -7px;
    top: 4px;

    &:focus {
      outline: none;
    }
  }

  table {
    width: 100%;

    td, th {
      border: 1px solid $borderColor;
    }
  }
}